import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export type TPDFRenderer = 'pdf-writer' | 'browserless' | null;

@Injectable({
  providedIn: 'root',
})
export class DoenKidsPreferencesProvider {
  private INCLUDE_INCATIVE_USERS_KEY = 'includeInactiveUsers';

  private INCLUDE_INCATIVE_LOCATIONS_KEY = 'includeInactiveLocations';

  private SEARCH_ALL_ORGANIZATIONS = 'searchAllOrganizations';

  private PDF_RENDERER_KEY = 'PDF_RENDERER_PREFERENCE';

  public showInactiveUsers$ = new BehaviorSubject(localStorage.getItem(this.INCLUDE_INCATIVE_USERS_KEY) === 'true');

  public showInactiveLocations$ = new BehaviorSubject(localStorage.getItem(this.INCLUDE_INCATIVE_LOCATIONS_KEY) === 'true');

  public searchInAllOrganizations$ = new BehaviorSubject(localStorage.getItem(this.SEARCH_ALL_ORGANIZATIONS) === 'true');

  public pdfRendererPreference$ = new BehaviorSubject<TPDFRenderer>(null);

  constructor() {
    const storedPDFRenderer = localStorage.getItem(this.PDF_RENDERER_KEY);
    let appliedPdfRenderer = storedPDFRenderer as TPDFRenderer;

    if (storedPDFRenderer === 'URL') {
      appliedPdfRenderer = 'browserless';
    } else if (storedPDFRenderer === 'WRITER') {
      appliedPdfRenderer = 'pdf-writer';
    }

    if (appliedPdfRenderer !== storedPDFRenderer) {
      localStorage.setItem(this.PDF_RENDERER_KEY, appliedPdfRenderer);
    }

    this.pdfRendererPreference$.next(appliedPdfRenderer);
  }

  public toggleShowInactiveUsers(toggle: boolean) {
    this.showInactiveUsers$.next(toggle);
    localStorage.setItem(this.INCLUDE_INCATIVE_USERS_KEY, `${toggle}`);
  }

  public toggleShowInactiveLocations(toggle: boolean) {
    this.showInactiveLocations$.next(toggle);
    localStorage.setItem(this.INCLUDE_INCATIVE_LOCATIONS_KEY, `${toggle}`);
  }

  public toggleSearchInAllOrganizations(toggle: boolean) {
    this.searchInAllOrganizations$.next(toggle);
    localStorage.setItem(this.SEARCH_ALL_ORGANIZATIONS, `${toggle}`);
  }

  public setPDFRendererPreference(preference: TPDFRenderer) {
    this.pdfRendererPreference$.next(preference);
    localStorage.setItem(this.PDF_RENDERER_KEY, preference);
  }
}
