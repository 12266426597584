import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { IProgramTemplate } from 'typings/doenkids/doenkids';
import {
  ProgramTemplateBundleProgramTemplateListState,
  ProgramTemplateBundleProgramTemplateListStore,
} from './program-tempate-bundle-program-template-list.store';

@Injectable({ providedIn: 'root' })
export class ProgramTemplateBundleProgramTemplateListQuery
  extends QueryEntity<ProgramTemplateBundleProgramTemplateListState, IProgramTemplate> {
  constructor(protected store: ProgramTemplateBundleProgramTemplateListStore) {
    super(store);
  }
}
