import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ITemplateWithSettings } from 'typings/api-publish';
import { TemplateStore, ITemplateState } from './template.store';

@Injectable({ providedIn: 'root' })
export class TemplateQuery extends QueryEntity<ITemplateState, ITemplateWithSettings> {
  constructor(protected store: TemplateStore) {
    super(store);
  }
}
