import {
  Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation, ViewChild, SimpleChanges, OnChanges,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';

import {
  isEqual, isNil, isObject, get, omit,
} from 'lodash';
import {
  Observable, Subject, BehaviorSubject, firstValueFrom, combineLatest,
} from 'rxjs';
import {
  debounceTime,
  filter, takeUntil,
} from 'rxjs/operators';
import { IPeriodSection, IPeriodSectionType, ISectionType } from 'typings/doenkids/doenkids';
import { FormHelperProvider } from 'src/providers/form-helper.provider';
import { DoenkidsTemplateProvider } from 'src/providers/doenkids-template.provider';
import { IMigratePeriodSectionsDialogData, MigratePeriodSectionsDialogComponent } from 'src/components/dialogs/migrate-period-sections-dialog/migrate-period-sections-dialog.component';
import { PeriodActivitySectionDialogComponent } from 'src/components/dialogs/period-activity-section-dialog/period-activity-section-dialog.component';
import { ProgramSectionTypeQuery } from 'src/api/activity/program-section-type/program-section-type.query';
import { ProgramPeriodSectionService } from 'src/api/activity/program-period-section/program-section.service';
import {
  MigratePeriodSectionsToOtherProgramDialogComponent,
} from 'src/components/dialogs/migrate-period-sections-to-other-program-dialog/migrate-period-sections-to-other-program-dialog.component';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { ConfirmationDialogComponent } from 'src/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { PanelContainerComponent } from 'src/components/layout/panel-container/panel-container.component';
import { I18nToastProvider } from 'src/providers/i18n-toast.provider';
import { TranslateService } from 'src/app/utils/translate.service';
import { convertIProgramSummaryPeriodSectionToIPeriodSection } from 'src/api/activity/program/program.service';
import { IProgramSummaryPeriod, IProgramSummaryPeriodSection } from 'typings/api-activity';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { EProgramPeriodSectionType, ESectionContainerType, ESectionType, getNameForProgramPeriodSectionType } from 'src/components/shared/section/section.component';

@Component({
  selector: 'app-program-period-sections',
  templateUrl: './program-period-sections.component.html',
  styleUrls: ['./program-period-sections.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProgramPeriodSectionsComponent implements OnInit, OnDestroy, OnChanges {

  @Input()
    periodId$: BehaviorSubject<number>;

  @Input()
    periods$: BehaviorSubject<IProgramSummaryPeriod[]>;

  @Input()
    programId: number;

  @Input()
    programLanguage: string;

  @Input()
    readOnly: boolean = false;


  @Input()
  public excludedActivityIds$ = new BehaviorSubject<number[]>([]);

  @Output()
    close: EventEmitter<void> = new EventEmitter();

  @Output()
    periodSectionsChanged: EventEmitter<void> = new EventEmitter();

  private destroy$: Subject<void> = new Subject<void>();

  public periodSectionsToShow$ = new BehaviorSubject<IProgramSummaryPeriodSection[]>([]);

  public periodSectionsForm: UntypedFormGroup;

  public periodSectionTypes$: Observable<ISectionType[]>;

  public selectingSections = false;

  public selectedSections: { [index: number]: boolean } = [];

  public selectedSectionsLength = 0;

  public disableMediaSections$: Observable<boolean>;

  public openedSections: number[] = [];

  readonly ESectionType = ESectionType;

  readonly ESectionContainerType = ESectionContainerType;

  @ViewChild(PanelContainerComponent) panelContainer: PanelContainerComponent;

  protected readonly EProgramPeriodSectionType = EProgramPeriodSectionType;

  private typesToUseOriginalFormat = [EProgramPeriodSectionType.MEDIA];

  private currentOuId$: Observable<number>;

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    public programPeriodSectionService: ProgramPeriodSectionService,
    private matDialog: MatDialog,
    private periodSectionTypeQuery: ProgramSectionTypeQuery,
    private $formManagementService: FormHelperProvider,
    private $doenkidsTemplate: DoenkidsTemplateProvider,
    private $translateService: TranslateService,
    private $i18nToastProvider: I18nToastProvider,
    private $session: DoenkidsSessionProvider,
  ) {
    this.periodSectionsForm = fb.group({});

    this.periodSectionTypes$ = this.periodSectionTypeQuery.selectAll().pipe(takeUntil(this.destroy$));

    this.disableMediaSections$ = this.$doenkidsTemplate.programDisableMediaSections$.pipe(takeUntil(this.destroy$));

    this.periodSectionsToShow$.pipe(
      takeUntil(this.destroy$),
    ).subscribe((sections) => {
      this.setupSectionControls(sections);
    });

    this.currentOuId$ = this.$session.currentOuId$.pipe(takeUntil(this.destroy$));
  }

  ngOnInit() {
    combineLatest([this.periodId$, this.periods$]).pipe(
      takeUntil(this.destroy$),
      filter(([periodId, sections]) => !isNil(periodId) && !isNil(sections)),
      debounceTime(300),
    ).subscribe(([periodId, sections]) => {
      const sectionsOfPeriod = (sections ?? []).find((period) => period.id === periodId)?.section ?? [];
      const sortedSections = sectionsOfPeriod.sort((sectionA, sectionB) => sectionA.order - sectionB.order);
      this.periodSectionsToShow$.next(sortedSections);
      this.setupSectionControls(sortedSections);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.readOnly) {
      if (changes.readOnly.currentValue) {
        this.periodSectionsForm.disable();
      } else {
        this.periodSectionsForm.enable();
      }
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  private async setupSectionControls(periodSections: IProgramSummaryPeriodSection[]) {
    const formKeys = periodSections.map((section) => section.id.toString());

    // Remove all controls
    //
    this.$formManagementService.removeControls(this.periodSectionsForm, formKeys);

    // For each section in the period, add controls.
    //
    periodSections.forEach((section) => {
      if (!this.periodSectionsForm.get(section?.id?.toString())) {
        this.addPeriodSectionControl(section);
      }
    });

    const currentQueryParams = await firstValueFrom(this.route.queryParams);

    if (currentQueryParams.periodSections && currentQueryParams.opened) {
      const splitSections: string[] = currentQueryParams.opened.split(',');
      const parsedSplitSections: number[] = splitSections.map((value: string) => parseInt(value, 10));

      this.openedSections = parsedSplitSections;
    } else if (currentQueryParams.periodSections && !currentQueryParams.opened) {
      this.openedSections = [];
    }
  }

  /**
   * Add a period section formcontrol.
   * @param periodSection The section to add controls for, for the initial form data.
   */
  public async addPeriodSectionControl(periodSection?: IProgramSummaryPeriodSection | IPeriodSection) {
    let data;
    let columns = periodSection.columns;
    if (this.typesToUseOriginalFormat.includes(periodSection.type_id)) {
      const originalData = (await this.programPeriodSectionService.fetch(periodSection.id));
      data = originalData?.data;
      columns = originalData?.columns;
    } else {
      data = (periodSection as IPeriodSection).data ?? periodSection;
    }
    const newControl = this.fb.control({ data, columns });
    if (this.periodSectionsForm.disabled) {
      newControl.disable();
    }
    this.periodSectionsForm.addControl(periodSection.id.toString(), newControl);
  }

  async addMedia(media_uuid: string, activityId: number) {
    const programActivityControl = this.periodSectionsForm.get(activityId.toString());
    const activityData = programActivityControl.value as IPeriodSection;
    programActivityControl.patchValue({ ...activityData, media_uuid });
  }

  /** Remove an activity from the program */
  async removeSection(periodSection: IProgramSummaryPeriodSection) {
    const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
      width: '400px',
      minWidth: '320px',
      data: {
        title: this.$translateService.instant('generic.confirm'),
        description: this.$translateService.instant(
          periodSection.type_id === EProgramPeriodSectionType.PAGE_END
            ? _('program.period_sections.remove.confirm.description.page_end')
            : _('program.period_sections.remove.confirm.description'),
        ),
      },
    });

    const result = await firstValueFrom(dialogRef.afterClosed());

    if (result === 'confirm') {
      await this.programPeriodSectionService.archive(periodSection.id);
      this.periodSectionsForm.removeControl(periodSection?.id?.toString());
      this.periodSectionsChanged.emit();
    }
  }

  async saveProgramPeriodSections() {
    // Get the current period activity form values.
    //
    const periodSectionFormValues = this.periodSectionsForm.value;
    // eslint-disable-next-line no-prototype-builtins
    const periodIds = Object.keys(periodSectionFormValues).filter((key) => periodSectionFormValues.hasOwnProperty(key));

    // Loop through all existing periodSections.
    //
    const allResults: Promise<IPeriodSection | void>[] = [];
    for (const periodSectionId of periodIds) {
      allResults.push(this._saveProgramPeriodSection(periodSectionId));
    }

    await Promise.all(allResults);
    // TODO: Deal with failures and errors here.
    return allResults;
  }

  /** Saves a specific periodsection */
  async saveSection(sectionId: number) {
    try {
      const response = await this._saveProgramPeriodSection(sectionId);
      if (response) {
        this.$i18nToastProvider.success(_('program.period_sections.changes.saved'));
        this.periodSectionsForm.markAsPristine();
      } else {
        this.$i18nToastProvider.error(_('program.period_sections.nothing_saved'));
      }
    } catch (e) {
      console.log(e);
    }
  }

  private async _saveProgramPeriodSection(periodSectionId: number | string) {
    // Get the current period activity form values.
    //
    const periodSectionFormCtrl = this.periodSectionsForm.get(periodSectionId.toString());

    // If the current periodActivityForm has a control with the Period Activity's ID as the name, continue.
    //
    if (isNil(periodSectionFormCtrl)) {
      return Promise.resolve();
    }

    const periodSectionValue: Partial<IPeriodSection> = periodSectionFormCtrl.value as IPeriodSection;

    // Get the original IPeriodSection object.
    //
    const originalSectionDataObject = this.periodSectionsToShow$.value.find((periodSection) => periodSection.id === parseInt(`${periodSectionId}`));

    // Check whether the current activity still exists.
    //
    if (!isObject(originalSectionDataObject)) {
      return Promise.resolve();
    }

    // If the form data doesn't match the original properties, update the object!
    //
    if (isEqual(originalSectionDataObject, periodSectionValue)) {
      return Promise.resolve();
    }

    // Update the period activity.
    //
    let result;
    if (this.typesToUseOriginalFormat.includes(originalSectionDataObject.type_id)) {
      result = await this.programPeriodSectionService.update(convertIProgramSummaryPeriodSectionToIPeriodSection(
        originalSectionDataObject, this.periodId$.value, periodSectionValue.data, periodSectionValue.columns));
    } else {
      result = await this.programPeriodSectionService.update({
        ...convertIProgramSummaryPeriodSectionToIPeriodSection({
          ...originalSectionDataObject,
          ...periodSectionValue.data,
        }, this.periodId$.value),
      });
    }

    this.periodSectionsChanged.emit();
    return result;
  }

  async addActivities(activity?: IPeriodSection) {
    // Open the activity search dialog.
    //
    const inquiryDialogRef = this.matDialog.open(
      PeriodActivitySectionDialogComponent, {
        width: '400px',
        minWidth: '320px',
        data: {
          programId: this.programId,
          activity,
        },
        panelClass: 'doenkids-dialog',
      },
    );
    const inquiryResult = await firstValueFrom(inquiryDialogRef.afterClosed());

    // If the confirm button wasn't pressed, return.
    //
    if (inquiryResult === 'create') {
      const addActivity = await this._addActivity(null);
      this.addPeriodSectionControl(addActivity);
      return addActivity;
    } else if (inquiryResult === 'select') {
      const periodId = await firstValueFrom(this.periodId$);
      this.router.navigate(['/activities'], { queryParams: { programId: this.programId, periodId, lang: this.programLanguage }, replaceUrl: true });
      return null;
    }
  }

  private async _addActivity(activity: IPeriodSection) {
    const periodId = await this.periodId$.value;
    const organizationUnitId = await firstValueFrom(this.currentOuId$);

    const currentPeriod = this.periods$.value.find((period) => period.id === periodId);

    let response: Promise<IPeriodSection>;

    if (isNil(activity)) {
      response = this.programPeriodSectionService.create({
        period_id: periodId,
        type_id: EProgramPeriodSectionType.ACTIVITY,
        name: 'activity',
      }, organizationUnitId);
    } else {
      // Create a new activity for the period.
      //
      response = this.programPeriodSectionService.create({
        period_id: periodId,
        name: 'activity',
        ...activity,
      }, organizationUnitId);
    }

    const section = await response;

    // If the response is undefined, a warning toast and log message should be shown.
    //
    if (isNil(response)) {
      console.warn(`Unlinked activity ${activity} couldn't be added to period ${periodId}`);
      this.$i18nToastProvider.error(_('program.period_sections.activity.add.failed'), { currentPeriod: currentPeriod.name });
    } else {
      this.addPeriodSectionControl(section);
    }

    return response;
  }

  /** Appends a new section to the program details */
  async appendSection(periodSectionType: IPeriodSectionType) {
    const periodId = this.periodId$.value;
    const { id: typeId, name } = periodSectionType;
    const organizationUnitId = await firstValueFrom(this.currentOuId$);

    if (typeId) {
      if (typeId === EProgramPeriodSectionType.ACTIVITY) {
        const addedActivity = await this.addActivities();
        if (!addedActivity) {
          return;
        }
      } else if (typeId === EProgramPeriodSectionType.MEDIA) {
        const mediaSection = await this.programPeriodSectionService.create({
          name,
          period_id: periodId,
          type_id: typeId,
          data: {
            title: '',
            media: [],
          },
        }, organizationUnitId);
        this.addPeriodSectionControl(mediaSection);
      } else if (typeId === EProgramPeriodSectionType.ACTIVITY_WHAT) {
        const textSection = await this.programPeriodSectionService.create({
          name,
          period_id: periodId,
          type_id: typeId,
          data: {
            title: '',
            content: '',
          },
        }, organizationUnitId);
        this.addPeriodSectionControl(textSection);
      } else if (typeId === EProgramPeriodSectionType.PAGE_END) {
        const pageBreakSection = await this.programPeriodSectionService.create({
          name,
          period_id: periodId,
          type_id: typeId,
          data: {},
        }, organizationUnitId);
        this.addPeriodSectionControl(pageBreakSection);
      }

      this.periodSectionsChanged.emit();
    }
  }

  setPanelBackToPDF() {
    this.close.emit();
  }

  async panelSelected(sectionId) {
    if (this.selectingSections) {
      let sectionSelected = get(this.selectedSections, sectionId);
      sectionSelected = isNil(sectionSelected) ? false : sectionSelected;

      this.selectedSections[sectionId] = !sectionSelected;

      if (this.selectedSections[sectionId]) {
        this.selectedSectionsLength++;
      } else {
        this.selectedSectionsLength--;
      }
    } else {
      const currentQueryParams = await firstValueFrom(this.route.queryParams);
      let newQueryParams;

      if (currentQueryParams.periodSections) {
        let newSelectedSections: string[] = [];

        if (currentQueryParams.opened) {
          const splitActivities: string[] = currentQueryParams.opened.split(',');
          const indexOfCurrentSection = splitActivities.findIndex((value) => parseInt(value, 10) === sectionId);

          if (indexOfCurrentSection > -1) {
            splitActivities.splice(indexOfCurrentSection, 1);
          } else {
            splitActivities.push(`${sectionId}`);
          }

          newSelectedSections = splitActivities;
        } else {
          newSelectedSections.push(`${sectionId}`);
        }

        if (newSelectedSections.length === 0 && currentQueryParams.opened) {
          newQueryParams = omit(currentQueryParams, 'opened');
        } else {
          newQueryParams = { ...currentQueryParams, opened: newSelectedSections.join(',') };
        }

        // by not providing a string for a url and using the relativeTo we basically reload the page with the current values
        // but with the opened query parameter attached. This will help by reloading the state after going to a activity of a period
        //
        this.router.navigate([], { replaceUrl: true, queryParams: newQueryParams, relativeTo: this.route });
      }
    }
  }

  toggleSelection(selecting?: boolean) {
    if (this.selectingSections) {
      this.selectedSections = [];
      this.selectedSectionsLength = 0;
    }

    // Toggle section selection, or set to given value if it's defined.
    //
    this.selectingSections = !isNil(selecting) ? selecting : !this.selectingSections;

    if (this.panelContainer) {
      if (this.selectingSections) {
        this.panelContainer.collapseEveryone();
      } else {
        this.panelContainer.openLastActivePanel();
      }
    }
  }

  async promptSectionMove() {
    if (!this.selectingSections) {
      return;
    }

    const periodId = await firstValueFrom(this.periodId$);

    const selectedSectionIds = Object.keys(this.selectedSections)
      // eslint-disable-next-line no-prototype-builtins
      .filter((id) => this.selectedSections.hasOwnProperty(id))
      .filter((id) => this.selectedSections[id])
      .map((id) => +id);

    const dialogRef = this.matDialog.open(MigratePeriodSectionsDialogComponent, {
      data: {
        periodId,
        periods: this.periods$.value,
        selectedSectionIds,
        title: this.$translateService.instant(_('program.period_sections.activity.move.dialog.title')),
        confirmText: this.$translateService.instant(_('generic.move')),
        cancelText: this.$translateService.instant(_('generic.cancel')),
      } as IMigratePeriodSectionsDialogData,
    });

    const result = await firstValueFrom(dialogRef.afterClosed());

    if (isNil(result)) {
      return;
    }

    const updatedSections = await this.moveSelectedSections(result, this.periodSectionsToShow$.value);

    const failureCount = updatedSections.filter((section) => isNil(section)).length;
    if (failureCount > 0) {
      this.$i18nToastProvider.failureSummary('activity', failureCount, selectedSectionIds.length, { action: 'move' });
    }

    this.selectingSections = false;
    this.selectedSectionsLength = 0;
    this.selectedSections = [];

    // FIXME: Without this fetchall, the list doesn't re-render.
    // The update method is already dilligently updating the store.
    // Therefore, the list should be updated in the view, but it isn't without this fetch.
    //
    // this.programPeriodSectionService.fetchAll(periodId, 100, 0);
    this.periodSectionsChanged.emit();
  }

  /**
   * Move all selected sections to a designated period.
   * @param periodId The period to move the selected sections to.
   */
  async moveSelectedSections(periodId: number, sections: IProgramSummaryPeriodSection[]) {
    const selectedSectionIds = Object.keys(this.selectedSections)
      // eslint-disable-next-line no-prototype-builtins
      .filter((id) => this.selectedSections.hasOwnProperty(id))
      .filter((id) => this.selectedSections[id])
      .map((id) => +id);

    const promises = selectedSectionIds.map((selectedSectionId) => {
      const currentPeriodSection = sections.find((section) => selectedSectionId === section.id);

      if (!isNil(currentPeriodSection)) {
        return this.programPeriodSectionService.update({
          id: currentPeriodSection.id,
          type_id: currentPeriodSection.type_id,
          name: currentPeriodSection.name,
          period_id: periodId,
          order: null,
        });
      }

      return null;
    });

    return Promise.all(promises);
  }

  async promptSectionCopy() {
    if (!this.selectingSections) {
      return;
    }

    const periodCount = this.periods$.value.length;

    let result;
    let dialogRef: MatDialogRef<any>;

    const selectedSectionIds = Object.keys(this.selectedSections)
      // eslint-disable-next-line no-prototype-builtins
      .filter((id) => this.selectedSections.hasOwnProperty(id))
      .filter((id) => this.selectedSections[id])
      .map((id) => +id);

    const periodId = await firstValueFrom(this.periodId$);

    if (periodCount > 1) {
      // if there is more then 1 period in the program first give the option to copy to another period
      // the user can then also choose to copy to another program
      //
      dialogRef = this.matDialog.open(MigratePeriodSectionsDialogComponent, {
        data: {
          periodId,
          periods: this.periods$.value,
          selectedSectionIds,
          title: this.$translateService.instant(_('program.period_sections.activity.copy.dialog.title')),
          confirmText: this.$translateService.instant(_('generic.copy')),
          cancelText: this.$translateService.instant(_('generic.cancel')),
          isCopying: true,
        },
      });

      result = await firstValueFrom(dialogRef.afterClosed());
    } else {
      // Since the user can't copy to another period in the same program (as there is only 1)
      // we set the result to the same as if the user would have chosen to copy to another program
      // as this is probably what they would want to do
      //
      result = {
        action: 'other-program',
      };
    }

    if (isNil(result)) {
      return;
    }

    if (result.action && result.action === 'other-program') {
      dialogRef = this.matDialog.open(MigratePeriodSectionsToOtherProgramDialogComponent, {
        maxWidth: '810px',
        width: '50vw',
        minWidth: '320px',
        minHeight: '50vh',
        data: {
          currentProgramId: this.programId,
          periodId,
          title: this.$translateService.instant(_('program.period_sections.activity.copy.dialog.title')),
          confirmText: this.$translateService.instant(_('generic.copy')),
          cancelText: this.$translateService.instant(_('generic.cancel')),
        },
      });

      const otherProgramAndPeriod = await firstValueFrom(dialogRef.afterClosed());

      if (otherProgramAndPeriod != null && otherProgramAndPeriod.periodSectionId) {
        const updatedSections = await this.copySelectedSections(otherProgramAndPeriod.periodSectionId, this.periodSectionsToShow$.value);

        const failedCount = updatedSections.filter((section) => isNil(section)).length;
        if (failedCount > 0) {
          this.$i18nToastProvider.failureSummary('activity', failedCount, selectedSectionIds.length, { action: 'copy' });
        } else {
          this.$i18nToastProvider.success(_('program.period_sections.activities.copied'), { copiedCount: selectedSectionIds.length });
        }
      }
    } else if (!result.action) {
      const updatedSections = await this.copySelectedSections(result, this.periodSectionsToShow$.value);

      const failedCount = updatedSections.filter((section) => isNil(section)).length;
      if (failedCount > 0) {
        this.$i18nToastProvider.failureSummary('activity', failedCount, selectedSectionIds.length, { action: 'copy' });
      } else {
        this.$i18nToastProvider.success(_('program.period_sections.activities.copied'), { copiedCount: selectedSectionIds.length });
      }
    }
    this.selectingSections = false;
    this.selectedSectionsLength = 0;
    this.selectedSections = [];
    this.periodSectionsChanged.emit();
  }

  /**
   * Move all selected sections to a designated period.
   * @param periodId The period to move the selected sections to.
   */
  async copySelectedSections(periodId: number, sections: IProgramSummaryPeriodSection[]) {
    const selectedSectionIds = Object.keys(this.selectedSections)
      // eslint-disable-next-line no-prototype-builtins
      .filter((id) => this.selectedSections.hasOwnProperty(id))
      .filter((id) => this.selectedSections[id])
      .map((id) => +id);
    const organizationUnitId = await firstValueFrom(this.currentOuId$);

    const promises = selectedSectionIds.map((selectedSectionId) => {
      const currentPeriodSection = sections.find((section) => selectedSectionId === section.id);

      if (!isNil(currentPeriodSection)) {
        return this.programPeriodSectionService.create({
          ...convertIProgramSummaryPeriodSectionToIPeriodSection(currentPeriodSection, periodId),
          order: null,
        }, organizationUnitId);
      }

      return null;
    });

    return Promise.all(promises);
  }

  async promptSectionRemove() {
    if (!this.selectingSections) {
      return;
    }

    const selectedSectionIds = Object.keys(this.selectedSections)
      // eslint-disable-next-line no-prototype-builtins
      .filter((id) => this.selectedSections.hasOwnProperty(id))
      .filter((id) => this.selectedSections[id])
      .map((id) => +id);

    const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
      width: '400px',
      minWidth: '320px',
      data: {
        title: this.$translateService.instant('generic.confirm'),
        description: this.$translateService.instant(_('program.period_sections.remove.confirm.dialog.description')),
      },
    });

    const result = await firstValueFrom(dialogRef.afterClosed());

    if (result === 'confirm') {
      selectedSectionIds.forEach(async (sectionId: number) => {
        await this.programPeriodSectionService.archive(sectionId);
        this.periodSectionsForm.removeControl(sectionId.toString());
      });
      this.selectedSections = {};
      this.selectingSections = false;
      this.periodSectionsChanged.emit();
    }
  }

  openActivity(data: IProgramSummaryPeriodSection) {
    const activityId = get(data, 'activity_id');
    if (isNil(activityId)) {
      this.$i18nToastProvider.error(_('program.period_sections.activity.open.failed'));

      return;
    }

    this.router.navigate([`/activities/preview/${activityId}`]);
  }

  isOpenedSection(sectionId: number) {
    return this.openedSections.indexOf(sectionId) > -1;
  }

  // Drop event for moving items around.
  //
  async dropPeriodSection(event: CdkDragDrop<{ id?: number, order?: number }[]>) {
    // Get the event values.
    //
    const { previousIndex, currentIndex, container } = event;

    // If the previous index is the exact same as the current, we don't need to move anything.
    //
    if (previousIndex === currentIndex) {
      return;
    }

    // Get the original list of items from before the drag and drop event,
    // and retrieve the dropped item from it.
    //
    const items = (container.data as IProgramSummaryPeriodSection[]).map((section) => {
      const periodSectionFormValue = this.periodSectionsForm.get(section.id.toString())?.value;
      const originalSectionDataObject = this.periodSectionsToShow$.value.find((periodSection) => periodSection.id === parseInt(`${section.id}`));
      if (this.typesToUseOriginalFormat.includes(section.type_id)) {
        return convertIProgramSummaryPeriodSectionToIPeriodSection(
          originalSectionDataObject, this.periodId$.value, periodSectionFormValue.data);
      }
      return convertIProgramSummaryPeriodSectionToIPeriodSection({
        ...originalSectionDataObject,
        ...periodSectionFormValue.data,
      }, this.periodId$.value);
    });
    const droppedItem = items[previousIndex];

    // Update the store optimistically by passing the third param as true.
    // Save the first promise to be awaited later.
    //
    const firstPromise = this.programPeriodSectionService.update({ ...droppedItem, order: currentIndex + 1 });

    // Because the store is already being updated optimistically,
    // we only need to await the promises and restore the previous state if any promises fail.
    //
    const promises = [firstPromise];

    // Loop through all items in the drop list and determine whether they need to be updated.
    //
    for (let index = 0; index < items.length; index++) {
      // If the current item is actually the previous item, continue.
      //
      if (index === previousIndex) {
        // eslint-disable-next-line no-continue
        continue;
      }

      // Get the current item,
      // and prepare the update promise.
      //
      const item = items[index];
      let promise: Promise<any>;

      // If the current item comes after the original dragged place, but before the drop position,
      // Save the index as the order. ( Less means the item moves up. )
      //
      if (index > previousIndex && index <= currentIndex) {
        promise = this.programPeriodSectionService.update({ ...item, order: (index) });

      // If the current item comes before the original dragged place, but after the drop position,
      // add 2 to the index and save that value as the order. ( More means the item moves down. )
      //
      } else if (index < previousIndex && index >= currentIndex) {
        promise = this.programPeriodSectionService.update({ ...item, order: (index + 2) });
      }

      if (!isNil(promise)) {
        // Add the saved promise to the list of promises.
        //
        promises.push(promise);
      }
    }

    // Await all promises at the same time.
    //
    await Promise.all(promises);
    this.periodSectionsChanged.emit();
  }

  getNameForProgramPeriodSectionType(sectionTypeId: EProgramPeriodSectionType): string {
    return getNameForProgramPeriodSectionType(sectionTypeId);
  }

  getActivityIsExcluded(section: IProgramSummaryPeriodSection) {
    const isExcluded = this.excludedActivityIds$.value.includes(section.activity_id);
    return !isNil(section.activity_id) && isExcluded;
  }

  isSelectedSection(sectionId: number) {
    return this.selectedSections[sectionId] ?? false;
  }
}
