import { Injectable } from '@angular/core';
import { transaction } from '@datorama/akita';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { IServiceResponse } from 'typings/api-generic';
import { IPublishProgramRequest, IPublishProgramActivityCoverRequest, IPublishProgramScriptRequest } from 'typings/api-publish';
import { DoenkidsFileDownloader } from 'src/providers/download-files.provider';
import { firstValueFrom } from 'rxjs';
import { DoenKidsPreferencesProvider } from 'src/providers/preferences.provider';
import { TranslateService } from 'src/app/utils/translate.service';

export interface IProgramHTMLResponse {
  header?: string;
  footer?: string;
  content: string;
}

export interface IProgramBookletRequest {
  program_ids: number[];
  organization_unit_id: number;
  cover_media_uuid: string;
}

@Injectable({
  providedIn: 'root',
})
export class PublishProgramService {
  constructor(
    private $baseApi: DoenKidsGenericApiProvider,
    private $downloadFileDownloader: DoenkidsFileDownloader,
    private $preference: DoenKidsPreferencesProvider,
    private $translate: TranslateService,
  ) {}

  async fetchProgramPdf(publishProgramRequest: IPublishProgramRequest, queryParams?: { filename?: string, debug?: boolean, forceUpdate?: boolean }) {
    try {
      let url = '/publish/program/pdf';
      url = this.$baseApi.appendQueryParam(url, 'filename', queryParams?.filename);
      url = this.$baseApi.appendQueryParam(url, 'debug', queryParams?.debug);
      url = this.$baseApi.appendQueryParam(url, 'forceUpdate', queryParams?.forceUpdate);

      if (this.$preference.pdfRendererPreference$.value !== null) {
        publishProgramRequest.renderer = this.$preference.pdfRendererPreference$.value;
      }

      publishProgramRequest.locale = this.$translate.currentLang;

      const fetchedProgramPdfBuffer = await firstValueFrom(this.$baseApi.uploadImage(url, publishProgramRequest));

      return fetchedProgramPdfBuffer;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async fetchProgramBookletPdf(bookletRequest: IProgramBookletRequest) {
    try {
      return await firstValueFrom(this.$baseApi.uploadImage('/publish/program/booklet/pdf', bookletRequest));
    } catch (e) {
      return Promise.reject(e);
    }
  }

  @transaction()
  async fetchProgramHtml(publishProgramRequest: IPublishProgramRequest) {
    try {
      if (this.$preference.pdfRendererPreference$.value !== null) {
        publishProgramRequest.renderer = this.$preference.pdfRendererPreference$.value;
      }
      publishProgramRequest.locale = this.$translate.currentLang;

      const programHTML = await firstValueFrom(this.$baseApi.genericPostCall('/publish/program/html', publishProgramRequest)) as IProgramHTMLResponse;
      return programHTML;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async getProgramPdfUrl(publishProgramRequest: IPublishProgramRequest, filename?: string) {
    try {
      let url = '/publish/program/pdf-url';
      if (filename) {
        url += `?filename=${filename}`;
      }

      if (this.$preference.pdfRendererPreference$.value !== null) {
        publishProgramRequest.renderer = this.$preference.pdfRendererPreference$.value;
      }

      publishProgramRequest.locale = this.$translate.currentLang;

      const fetchedProgramPdfUrl = await firstValueFrom(this.$baseApi.genericPostCallWithoutErrorHandler(url, publishProgramRequest)) as IServiceResponse;

      return fetchedProgramPdfUrl.url;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async getProgramScriptPdfUrl(publishProgramScriptRequest: IPublishProgramScriptRequest, filename?: string) {
    try {
      let url = '/publish/program/script/pdf';
      if (filename) {
        url += `?filename=${filename}`;
      }

      if (this.$preference.pdfRendererPreference$.value !== null) {
        publishProgramScriptRequest.renderer = this.$preference.pdfRendererPreference$.value;
      }

      const fetchedProgramScriptPdfBuffer = await firstValueFrom(this.$baseApi.genericPostCall(url, publishProgramScriptRequest)) as IServiceResponse;

      return fetchedProgramScriptPdfBuffer.url;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async getProgramCoverPdf(publishProgramCoverRequest: IPublishProgramActivityCoverRequest, filename?: string) {
    try {
      let url = '/publish/program/cover/pdf';
      if (filename) {
        url += `?filename=${filename}`;
      }

      if (this.$preference.pdfRendererPreference$.value !== null) {
        publishProgramCoverRequest.renderer = this.$preference.pdfRendererPreference$.value;
      }

      return await firstValueFrom(this.$baseApi.uploadImage(url, publishProgramCoverRequest));
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async getExcelProgram(programId: number) {
    try {
      const url = `/activity/program/${programId}/materials/export`;

      return await firstValueFrom(this.$baseApi.getExcelCall(url));
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async getExcelActivity(activityId: number) {
    try {
      const url = `/activity/activity/${activityId}/materials/export`;

      return await firstValueFrom(this.$baseApi.getExcelCall(url));
    } catch (e) {
      return Promise.reject(e);
    }
  }

  downloadPdfUrl(url: string, filename: string) {
    this.$downloadFileDownloader.addDownload({
      url,
      name: `${filename}.pdf`,
    });
  }
}
