import { Injectable } from '@angular/core';
import { transaction } from '@datorama/akita';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { IAvailableFontOptions, ITemplateListResponse } from 'typings/api-publish';
import { TemplateStore } from './template.store';
import { TemplateQuery } from './template.query';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TemplateService {
  constructor(private store: TemplateStore, private query: TemplateQuery, private $baseApi: DoenKidsGenericApiProvider) {}

  @transaction()
  async fetch(organizationUnitId: number) {
    const url = `/publish/template?limit=100&skip=0&sortField=created_at&sortDirection=created_at&organizationUnitId=${organizationUnitId}&archived=false`;
    const fetchedTemplates = await firstValueFrom(this.$baseApi.genericGetCall(url)) as ITemplateListResponse;
    this.store.set(fetchedTemplates?.items ?? []);
    return fetchedTemplates?.items ?? [];
  }

  async fetchAvailableFontList() {
    const availableFonts = await firstValueFrom(this.$baseApi.genericGetCall('/publish/template/available-fonts')) as IAvailableFontOptions;

    return availableFonts;
  }
}
