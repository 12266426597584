import {
  Component, Inject, OnDestroy, OnInit, ViewEncapsulation,
} from '@angular/core';
import {
  FormControl,
  UntypedFormControl, ValidationErrors, Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  isEmpty, omit, snakeCase, cloneDeep, isNil, isEqual,
} from 'lodash';
import { Subject, firstValueFrom } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProgramActivitiesService } from 'src/api/activity/program-activities/program-activities.service';
import { AssetService } from 'src/api/media/asset/asset.service';
import { ProgramAttachmentService } from 'src/api/media/program-attachment/program-attachment.service';
import { PublishProgramService } from 'src/api/publish/program/program.service';
import { DoenkidsAssetProvider } from 'src/providers/assets.provider';
import { DoenkidsFileDownloader } from 'src/providers/download-files.provider';
import {
  DOENKIDS_DEFAULT_ACTIVITY_TEMPLATE_ID, DOENKIDS_DEFAULT_PROGRAM_MATERIAL_LIST_TEMPLATE_ID,
  DOENKIDS_DEFAULT_PROGRAM_TEMPLATE_ID, DownloadProvider,
} from 'src/providers/download.provider';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { PermissionProvider } from 'src/providers/permission.provider';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { IProgramActivityResponse } from 'typings/api-activity';
import { IPublishProgramActivityCoverRequest, IPublishProgramRequest, IPublishProgramScriptRequest } from 'typings/api-publish';
import { IMedia, IProgram, IProgramAttachmentMedia } from 'typings/doenkids/doenkids';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from 'src/app/utils/translate.service';
import { I18nToastProvider } from 'src/providers/i18n-toast.provider';

_('download_program_pdf.type.program');
_('download_program_pdf.type.material');
_('download_program_pdf.type.script');
_('download_program_pdf.type.covers');
_('download_program_pdf.type.attachments');

interface ICheckboxOption {
  control: UntypedFormControl;
  label: string;
  value: string;
  disablesSelect: boolean;
}

interface ISelectOption {
  control: UntypedFormControl;
  label: string;
  options: any[];
  disablesCheckbox: boolean;
  allOptionsLabel?: string;
  multipleSelection: boolean;
  invalidLabel: string;
  addSelectAllOption?: boolean;
}
interface IPDFOption {
  label: string;
  value: string;
  checkboxOptions?: ICheckboxOption[];
  selectOptions?: ISelectOption;
}

const emptyArrayValidator = (control: UntypedFormControl): ValidationErrors | null => {
  if (isNil(control.value) || control.value.length === 0) {
    return { invalid: true };
  }
  return null;
};

@Component({
  selector: 'app-download-program-pdf-dialog',
  templateUrl: './download-program-pdf-dialog.component.html',
  styleUrls: ['./download-program-pdf-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DownloadProgramPdfDialogComponent implements OnInit, OnDestroy {
  title: string;

  description;

  private stop$ = new Subject<void>();

  private programDownloadTypeChange$ = new Subject<void>();

  loading = false;

  programDownloadType: UntypedFormControl = new UntypedFormControl('');

  documentType = new UntypedFormControl('pdf');

  public downloadOptions: IPDFOption[] = [];

  public selectAllSelectOptionsControl = new FormControl<boolean>(false);

  constructor(
    public dialogRef: MatDialogRef<DownloadProgramPdfDialogComponent>,
    private programActivitiesService: ProgramActivitiesService,
    private programAttachmentService: ProgramAttachmentService,
    private downloadService: DownloadProvider,
    private publishService: PublishProgramService,
    private $session: DoenkidsSessionProvider,
    private $doenkidsFileDownloaderService: DoenkidsFileDownloader,
    private $asset: AssetService,
    private $baseApi: DoenKidsGenericApiProvider,
    private $permission: PermissionProvider,
    private $assetProvider: DoenkidsAssetProvider,
    private $translateService: TranslateService,
    private $i18nToastProvider: I18nToastProvider,
    @Inject(MAT_DIALOG_DATA) public data: { program: IProgram, title: string, description: string, programTitle: string },
  ) {
    this.title = data.title;
    this.description = data.description;
  }

  ngOnDestroy() {
    this.stop$.next();
  }

  async ngOnInit() {
    let activityOptions: IProgramActivityResponse[] = [];
    let programAttachmentOptions: IProgramAttachmentMedia[] = [];

    const activityOptionsResponse = await this.programActivitiesService.fetch(this.data.program.id);
    const programAttachmentWithMediaResponse = await this.programAttachmentService.fetchAttachmentsWithMedia({
      programId: this.data.program.id,
    });

    if (!isEmpty(activityOptionsResponse)) {
      activityOptions = activityOptionsResponse;
    }

    if (!isEmpty(programAttachmentWithMediaResponse)) {
      const hasProgramExplanationPermission = await firstValueFrom(this.$permission.hasProgramExplanationPermission$);

      if (hasProgramExplanationPermission) {
        programAttachmentOptions = programAttachmentWithMediaResponse.items;
      } else {
        for (const attachment of programAttachmentWithMediaResponse.items) {
          // eslint-disable-next-line no-await-in-loop
          const attachmentMedia = (await this.$assetProvider.fetch(attachment.media_id))?.data;

          if (attachmentMedia?.purpose !== 'program-explanation') {
            programAttachmentOptions.push(attachment);
          }
        }
      }
    }

    this.downloadOptions = [
      {
        label: _('download_program_pdf_dialog.option.program_booklet'),
        value: 'program',
      }, {
        label: _('download_program_pdf_dialog.option.materials'),
        value: 'material',
      }, {
        label: _('download_program_pdf_dialog.option.script'),
        value: 'script',
        checkboxOptions: [{
          control: new UntypedFormControl(false),
          label: _('download_program_pdf_dialog.only_attachments'),
          value: 'only_attachments',
          disablesSelect: false,
        }],
        selectOptions: {
          control: new UntypedFormControl(cloneDeep(activityOptions).map((activity) => activity.id), [emptyArrayValidator]),
          label: _('download_program_pdf_dialog.activities.label'),
          options: cloneDeep(activityOptions),
          disablesCheckbox: false,
          allOptionsLabel: _('download_program_pdf_dialog.activities.all'),
          multipleSelection: true,
          invalidLabel: _('download_program_pdf_dialog.activities.invalid'),
        },
      },
      {
        label: _('download_program_pdf_dialog.options.covers'),
        value: 'covers',
        selectOptions: {
          control: new UntypedFormControl(cloneDeep(activityOptions).map((activity) => activity.id), [emptyArrayValidator]),
          label: _('download_program_pdf_dialog.covers.label'),
          options: cloneDeep(activityOptions),
          disablesCheckbox: false,
          allOptionsLabel: _('download_program_pdf_dialog.activities.all'),
          multipleSelection: true,
          invalidLabel: _('download_program_pdf_dialog.activities.invalid'),
          addSelectAllOption: true,
        },
      },
    ];

    if (programAttachmentOptions.length > 0) {
      const attachmentOption: IPDFOption = {
        label: _('download_program_pdf_dialog.options.attachments'),
        value: 'attachments',
        selectOptions: {
          control: new UntypedFormControl('', [Validators.required]),
          label: _('download_program_pdf_dialog.attachments.label'),
          options: cloneDeep(programAttachmentOptions),
          disablesCheckbox: false,
          multipleSelection: false,
          invalidLabel: _('download_program_pdf_dialog.attachments.invalid'),
        },
      };

      if (attachmentOption.selectOptions.options.length === 1) {
        attachmentOption.selectOptions.control.setValue(attachmentOption.selectOptions.options[0].id);
      }
      this.downloadOptions.push(attachmentOption);
    }

    this.programDownloadType.setValue(this.downloadOptions[0].value);

    this.programDownloadType.valueChanges.pipe(takeUntil(this.stop$)).subscribe((value) => {
      this.programDownloadTypeChange$.next();
      if (value !== 'material') {
        this.documentType.setValue('pdf');
      }

      const foundDownloadOption = this.downloadOptions.find((downloadOption) => downloadOption.value === value);

      if (foundDownloadOption?.selectOptions?.addSelectAllOption) {
        const allOptionIds = foundDownloadOption?.selectOptions?.options?.map((option) => option.id);
        const selectedIds = foundDownloadOption?.selectOptions?.control.value;

        if (isEqual(allOptionIds, selectedIds)) {
          this.selectAllSelectOptionsControl.setValue(true, { emitEvent: false });
        } else {
          this.selectAllSelectOptionsControl.setValue(false, { emitEvent: false });
        }

        this.setSelectAllOnOptionChange(foundDownloadOption.selectOptions.control, foundDownloadOption.selectOptions.options);
      }
    });

    this.selectAllSelectOptionsControl.valueChanges.pipe(takeUntil(this.stop$)).subscribe((selectAll) => {
      const foundDownloadOption = this.downloadOptions.find((downloadOption) => downloadOption.value === this.programDownloadType.value);

      if (foundDownloadOption?.selectOptions) {
        let newSelectedIds = [];

        if (selectAll) {
          newSelectedIds = foundDownloadOption.selectOptions.options.map((option) => option.id);
        }

        foundDownloadOption.selectOptions.control.setValue(newSelectedIds);
      }
    });

    this.downloadOptions.forEach((downloadOption) => {
      if (downloadOption.checkboxOptions && downloadOption.selectOptions) {
        downloadOption.checkboxOptions.forEach((checkboxOption) => {
          if (checkboxOption.disablesSelect) {
            checkboxOption.control.valueChanges.pipe(takeUntil(this.stop$)).subscribe((selected: boolean) => {
              if (selected) {
                downloadOption.selectOptions.control.disable();
              } else {
                downloadOption.selectOptions.control.enable();
              }
            });
          }
        });
      }
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  async confirm() {
    const organizationUnitId = await firstValueFrom(this.$session.currentOuId$);
    const fileName = snakeCase(this.data.program.name);

    this.downloadService.programsDownloading++;

    const request: IPublishProgramRequest = {
      program_id: this.data.program.id,
      organization_unit_id: organizationUnitId,
      template_id: 2,
    };

    let parsedRequest: Partial<IPublishProgramRequest> | Partial<IPublishProgramScriptRequest> | Partial<IPublishProgramActivityCoverRequest> = null;
    let publishServiceRequestMethod = '';
    let pdfErrorMessage = '';
    let selectedMediaItem: IProgramAttachmentMedia;

    if (this.documentType.value === 'pdf') {
      switch (this.programDownloadType.value) {
        case 'program':
          publishServiceRequestMethod = 'getProgramPdfUrl';
          parsedRequest = {
            ...request,
            template_id: DOENKIDS_DEFAULT_PROGRAM_TEMPLATE_ID,
          };
          break;
        case 'material':
          publishServiceRequestMethod = 'getProgramPdfUrl';
          parsedRequest = {
            ...request,
            template_id: DOENKIDS_DEFAULT_PROGRAM_MATERIAL_LIST_TEMPLATE_ID,
            materiallist_only: true,
          };
          break;
        case 'script': {
          publishServiceRequestMethod = 'getProgramScriptPdfUrl';
          const selectedOption = this.downloadOptions.find((downloadOption) => downloadOption.value === 'script');
          const onlyAttachmentOption = selectedOption?.checkboxOptions?.find((checkboxOption) => checkboxOption.value === 'only_attachments');
          parsedRequest = {
            ...request,
            activity_template_id: DOENKIDS_DEFAULT_ACTIVITY_TEMPLATE_ID,
            only_attachments: onlyAttachmentOption?.control?.value ?? false,
            activityIds: selectedOption?.selectOptions?.control?.value ?? [],
          };
          // when we don't have any activity to select don't provide the activityIds property so all activities of the program are included
          //
          if (isEmpty(selectedOption?.selectOptions?.options)) {
            parsedRequest = omit(parsedRequest, 'activityIds');
          }
        } break;
        case 'covers': {
          publishServiceRequestMethod = 'getProgramCoverPdf';
          const selectedOption = this.downloadOptions.find((downloadOption) => downloadOption.value === 'covers');
          parsedRequest = {
            ...request,
            template_id: 4,
            activity_ids: selectedOption?.selectOptions?.control?.value ?? [],
          };
          // when we don't have any activity to select don't provide the activity_ids property so all activities of the program are included
          //
          if (isEmpty(parsedRequest.activity_ids)) {
            parsedRequest = omit(parsedRequest, 'activity_ids');
          }
        } break;
        case 'attachments': {
          publishServiceRequestMethod = 'getMedia';
          const selectedOption = this.downloadOptions.find((downloadOption) => downloadOption.value === 'attachments');
          // when we don't have any activity to select don't provide the activityIds property so all activities of the program are included
          //
          if (!isEmpty(selectedOption?.selectOptions?.options) && selectedOption.selectOptions.control.value) {
            const optionById = selectedOption.selectOptions.options.find((option) => option.id === selectedOption.selectOptions.control.value);
            if (optionById) {
              selectedMediaItem = optionById;
            }
          }
        } break;
        default: return;
      }
    } else if (this.documentType.value === 'excel') {
      switch (this.programDownloadType.value) {
        case 'material':
          publishServiceRequestMethod = 'getProgramExcel';
          parsedRequest = {
            ...request,
            template_id: DOENKIDS_DEFAULT_PROGRAM_MATERIAL_LIST_TEMPLATE_ID,
            materiallist_only: true,
          };
          break;
        default: {
          return;
        }
      }
    } else {
      return;
    }

    pdfErrorMessage = this.$translateService.instant(
      `download_program_pdf.type.${this.programDownloadType.value}`,
      { programTitle: this.data.programTitle },
    );

    // Show a loading spinner when downloading
    //
    this.loading = true;

    // Cover PDF needs to be downloaded as a PDF, not via a download URL
    //
    if (publishServiceRequestMethod === 'getProgramCoverPdf') {
      try {
        const blob = await this.publishService[publishServiceRequestMethod](parsedRequest as IPublishProgramActivityCoverRequest, fileName);

        if (blob) {
          this.downloadService.downloadPdfBlob(blob, 'covers');
        }
      } catch (error) {
        this.$i18nToastProvider.error('generic.error.download', { item: this.$translateService.instant('download_program_pdf_dialog.posters') });
      }
    } else if (publishServiceRequestMethod === 'getProgramExcel') {
      const name = this.$translateService.instant(
        'download_program_pdf_dialog.filename.materials',
        { programName: snakeCase(this.data.program.name) },
      );
      try {
        // eslint-disable-next-line no-undef
        const url = `${this.$baseApi.API_END_POINT}/activity/program/${this.data.program.id}/materials/export`;
        const headers = this.$baseApi.getAuthorizationHeader();

        this.$doenkidsFileDownloaderService.addDownload({
          name,
          url,
          headers,
        });

        this.downloadService.programsDownloading--;
      } catch (error) {
        this.$i18nToastProvider.error('generic.error.download', { item: this.$translateService.instant('download_program_pdf_dialog.excel') });
        this.downloadService.programsDownloading--;
      }
    } else if (publishServiceRequestMethod === 'getMedia') {
      try {
        const fetchedAsset = (await firstValueFrom(this.$asset.fetch(selectedMediaItem.media_id)) as any).data as IMedia;
        const assetUrl = (await firstValueFrom(this.$asset.getUrl(fetchedAsset.uuid)) as any).url as string;

        this.$doenkidsFileDownloaderService.addDownload({
          name: `${fetchedAsset.filename}`,
          url: assetUrl,
        });

        this.downloadService.programsDownloading--;
      } catch (error) {
        this.$i18nToastProvider.error('generic.error.download', { item: pdfErrorMessage });
        this.downloadService.programsDownloading--;
      }
    } else {
      try {
        const pdf: string | Blob = await this.publishService[publishServiceRequestMethod](parsedRequest, fileName);
        if (typeof pdf === 'string') {
          this.publishService.downloadPdfUrl(pdf, fileName);
        } else {
          this.downloadService.downloadPdfBlob(pdf, fileName);
        }
        // because the browser handles the downloading of the pdf and we can't hook into that we wait 30 seconds
        // before we set the downloading number of programs - 1. This will give the idea to the user that the download is still
        // going on
        //
        setTimeout(() => {
          this.downloadService.programsDownloading--;
        }, 30000);
      } catch (error) {
        this.$i18nToastProvider.error('generic.error.download', { item: pdfErrorMessage });
        this.downloadService.programsDownloading--;
      }
    }
    this.loading = false;
    this.dialogRef.close();
  }

  hasNoActivitiesSelected() {
    const selectedDownloadOption = this.downloadOptions.find((downloadOption) => downloadOption.value === this.programDownloadType.value);
    const selectedActivities = selectedDownloadOption?.selectOptions?.control?.value;

    if (selectedDownloadOption && selectedDownloadOption.selectOptions && (isNil(selectedActivities) || selectedActivities.length === 0)) {
      return true;
    }

    return false;
  }

  getCheckboxOptionsOfDownloadOption() {
    const selectedDownloadOption = this.downloadOptions.find((downloadOption) => downloadOption.value === this.programDownloadType.value);

    return selectedDownloadOption?.checkboxOptions ?? null;
  }

  getSelectOptionsOfDownloadOption() {
    const selectedDownloadOption = this.downloadOptions.find((downloadOption) => downloadOption.value === this.programDownloadType.value);

    return selectedDownloadOption?.selectOptions ?? null;
  }

  setSelectAllOnOptionChange(control: FormControl, options: any[]) {
    control.valueChanges.pipe(takeUntil(this.programDownloadTypeChange$)).subscribe((selectedIds) => {
      if (isEmpty(selectedIds)) {
        this.selectAllSelectOptionsControl.setValue(false, { emitEvent: false });
      } else {
        const optionIds = options.map((option) => option.id);

        if (isEqual(optionIds, selectedIds)) {
          this.selectAllSelectOptionsControl.setValue(true, { emitEvent: false });
        }
      }
    });
  }
}
