import {
  UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators,
} from '@angular/forms';
/* eslint-disable object-property-newline */
/* eslint-disable @typescript-eslint/naming-convention */
import {
  Component, ViewEncapsulation, OnInit, OnDestroy, ViewChild,
} from '@angular/core';
import { DoenkidsSessionProvider, LANGUAGES_FOR_COUNTRIES, SUPPORTED_COUNTRIES } from 'src/providers/session.provider';
import {
  Subject, combineLatest, BehaviorSubject, Observable, of, firstValueFrom,
} from 'rxjs';
import {
  isNil, get, isEqual, isObject, range, reverse,
} from 'lodash';
import {
  takeUntil, map, debounceTime, distinctUntilChanged, skip, startWith,
} from 'rxjs/operators';
import { PermissionProvider } from 'src/providers/permission.provider';
import { DoenkidsStaticValuesHelper } from 'src/components/shared/static-values/doenkids-static-values-helper';
import { MatTab, MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { FabButton } from 'src/components/layout/doenkids-speed-dial/doenkids-speed-dial.component';
import { MatDialog } from '@angular/material/dialog';
import { AddOrganizationDialogComponent, EOrganizationUnitType } from 'src/components/dialogs/add-organization-dialog/add-organization-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import {
  BrowseOrganizationUnitDialogComponent, ISelectedOrganizationUnit,
} from 'src/components/dialogs/browse-organization-unit-dialog/browse-organization-unit-dialog.component';
import { OrganizationUnitService } from 'src/api/customer/organization-unit/organization-unit.service';
import {
  IOrganizationUnit, IOrganizationUnitDetails, IOrganizationUnitPermission, IJibbieOrganizationUnit, IOrganizationUnitOverview, IActivityType,
} from 'typings/doenkids/doenkids';
import { OrganizationUnitDetailsService } from 'src/api/customer/organization-unit-details/organization-unit-details.service';
import { OrganizationUnitPermissionsService } from 'src/api/customer/organization-unit-permissions/organization-unit-permissions.service';
import { IOrganizationUnitPermissionOverview } from 'typings/api-customer';
import { CurrentUserService } from 'src/api/customer/auth/auth.service';
import { JibbieOrganizationService } from 'src/api/customer/jibbie/organization/jibbie-organization.service';
import { ConfirmationDialogComponent } from 'src/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { ConnectJibbieOuDialogComponent } from 'src/components/dialogs/connect-jibbie-ou-dialog/connect-jibbie-ou-dialog.component';
import { JibbieLocationService } from 'src/api/customer/jibbie/location/jibbie-location.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { UpdateOuLrkIdentifierDialog } from 'src/components/dialogs/update-ou-lrk-identifier-dialog/update-ou-lrk-identifier-dialog.component';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { IComponentCanDeactivate } from 'src/guards/can-leave-page.guard';
import { TranslateService } from 'src/app/utils/translate.service';
import { I18nToastProvider } from 'src/providers/i18n-toast.provider';
import { OrganizationUnitManageNewsfeedComponent } from './organization-unit-manage-newsfeed/organization-unit-manage-newsfeed.component';
import { ActivityTypeQuery } from 'src/api/generic/activity-type/activity-type.query';
import { ShowErrorsImmediatelyErrorMatcher } from 'src/components/shared/form-validators/error-state-matchers';
import { DoenKidsPreferencesProvider } from 'src/providers/preferences.provider';

interface IPermissionRule {
  shortKey: string;
  viewValue: string;
  formControlName: string;
  disables: string[];
  visibleForOuType$?: Observable<boolean>;
  visibleForOuType2$?: Observable<boolean>;
  visible: boolean;
}

interface IPermissionCategory {
  shortKey: string;
  viewValue: string;
  alsoSets: string[];
  permissions: IPermissionRule[];
  inheritingPermissions: IPermissionRule[];
}

const CHANGE_PARENT_NODE: FabButton = {
  icon: 'swap_vert',
  label: _('floating_action_button.change_parent_node'),
  action: 'change-parent-ou',
  disabled: false,
};

const ADD_ROOT_ORGANIZATION: FabButton = {
  svgIcon: 'doenkids-customer',
  label: _('floating_action_button.add_root_organization'),
  action: 'add-root',
  disabled: false,
};

const ADD_ORGANIZATION: FabButton = {
  svgIcon: 'doenkids-customer',
  label: _('floating_action_button.add_organization'),
  action: 'add-organisation',
  disabled: false,
};

const ADD_LOCATION: FabButton = {
  svgIcon: 'doenkids-location',
  label: _('floating_action_button.add_location'),
  action: 'add-location',
  disabled: false,
};

const ADD_GROUP: FabButton = {
  svgIcon: 'doenkids-group',
  label: _('floating_action_button.add_group'),
  action: 'add-group',
  disabled: false,
};

@Component({
  selector: 'app-organization-unit-details',
  templateUrl: './organization-unit-details.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./organization-unit-details.component.scss'],
})
export class OrganizationUnitDetailsComponent implements OnInit, OnDestroy, IComponentCanDeactivate {
  stop$: Subject<any> = new Subject();

  ouChange$ = new Subject<void>();

  @ViewChild('tabs', { static: false }) tabGroup: MatTabGroup;

  @ViewChild('manageNewsfeedComponent') manageNewsfeedComponent: OrganizationUnitManageNewsfeedComponent;

  readOnly = false;

  inputAppearance: MatFormFieldAppearance | null = 'fill';

  selectedTabIndex: number;

  selectedOrganizationUnitId: number;

  selectedOrganizationUnit: IOrganizationUnitOverview;

  isAdmin$: Observable<boolean>;

  hasParentOUWritePermissions$: Observable<boolean>;

  hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$: Observable<boolean>;

  hasWritePermissionOnAtLeastOneLocationOUInCurrentNodeTree$: Observable<boolean>;

  isLoading$: Subject<boolean> = new Subject<boolean>();

  scrolled$ = new Subject();

  isDoenkidsOrganization$: Observable<boolean>;

  isOrganizationOfTypeCustomer$: Observable<boolean>;

  isOrganizationOfTypeLocation$: Observable<boolean>;

  isOrganizationOfTypeCoorporate$: Observable<boolean>;

  translations$: Observable<any>;

  hasTemplateOptionsPermission$: Observable<boolean>;

  hasActiveUsersEnabled$: Observable<boolean>;

  hasCustomerSymbolsPermission$: Observable<boolean>;

  hasKonnectIntegrationPermission$: Observable<boolean>;

  canSetKonnectIntegrationSettingsPermission$: Observable<boolean>;

  konnectIntegrationPermissionDisabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  disableActivityCreatePermissionDisabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  hasJibbieIntegrationPermission$: Observable<boolean>;

  jibbieIntegrationPermissionDisabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  hasNewsItemPermission$: Observable<boolean>;

  newsItemPermissionDisabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  hasAnalyticsPermission$: Observable<boolean>;

  analyticsPermissionDisabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  hasCustomRecommendationPermission$: Observable<boolean>;

  customRecommendationPermissionDisabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  hasProgramExplanationPermission$: Observable<boolean>;

  programExplanationPermissionDisabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  canSeeUnpublishedProgramCountPermission$: Observable<boolean>;

  canSeeUnpublishedProgramCountPermissionDisabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  manageReviewsPermission$: Observable<boolean>;

  manageReviewsPermissionDisabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  permissionOverview$: BehaviorSubject<IOrganizationUnitPermissionOverview> = new BehaviorSubject<IOrganizationUnitPermissionOverview>(null);

  trickleDownPermissionsText$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  parentPermissionOrigins$: BehaviorSubject<{ [key: string]: IOrganizationUnit }> = new BehaviorSubject<{ [key: string]: IOrganizationUnit }>({});

  possibleActivityTypes: IActivityType[];

  months$: Observable<any>;

  form: UntypedFormGroup;

  financialForm = new UntypedFormGroup({
    financialNote: new UntypedFormControl(),
    billingStartMonth: new UntypedFormControl(),
    billingFrequency: new UntypedFormControl(''),
  });

  public floatingActionButtons: FabButton[] = [];

  public jibbieDetails: IJibbieOrganizationUnit;

  public isLinkedWithJibbie = false;

  public permissionsMap: IPermissionCategory[];

  public remainingPermissions: IPermissionRule[];

  readonly EOrganizationUnitType = EOrganizationUnitType;

  public localSupportedCountries = SUPPORTED_COUNTRIES;

  public possibleContentLanguages$ = new BehaviorSubject<string[]>([]);

  public errorStateMatcher = new ShowErrorsImmediatelyErrorMatcher();

  public showPdfWriterSettings$: Observable<boolean>;

  public ouCountryCode$: Observable<string>;

  public isReader$: Observable<boolean>;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private $session: DoenkidsSessionProvider,
    private $permissions: PermissionProvider,
    private matDialog: MatDialog,
    private router: Router,
    private organizationUnitService: OrganizationUnitService,
    private $organizationUnitDetail: OrganizationUnitDetailsService,
    private organizationUnitPermissionService: OrganizationUnitPermissionsService,
    private currentUserService: CurrentUserService,
    private jibbieOrganizationService: JibbieOrganizationService,
    private jibbieLocationService: JibbieLocationService,
    private activityTypeQuery: ActivityTypeQuery,
    private dialog: MatDialog,
    private $translateService: TranslateService,
    private $i18nToastProvider: I18nToastProvider,
    private $preference: DoenKidsPreferencesProvider,
    private $route: ActivatedRoute,
  ) {
    this.isAdmin$ = this.$session.isAdmin$.pipe(takeUntil(this.stop$));
    this.isReader$ = this.$session.isReader$.pipe(takeUntil(this.stop$));
    this.hasParentOUWritePermissions$ = this.$permissions.hasParentOUWritePermissions$.pipe(takeUntil(this.stop$));
    this.hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$ = this.$permissions.hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$.pipe(takeUntil(this.stop$));
    this.hasWritePermissionOnAtLeastOneLocationOUInCurrentNodeTree$ = this.$permissions.hasWritePermissionOnAtLeastOneLocationOUInCurrentNodeTree$.pipe(takeUntil(this.stop$));
    this.isDoenkidsOrganization$ = this.$session.isCurrentOrganizationDoenKids$.pipe(takeUntil(this.stop$));
    this.isOrganizationOfTypeCustomer$ = this.$session.isCurrentOrganizationUnitIsOfTypeCustomer$.pipe(takeUntil(this.stop$));
    this.isOrganizationOfTypeLocation$ = this.$session.isCurrentOrganizationUnitIsOfTypeLocation$.pipe(takeUntil(this.stop$));
    this.isOrganizationOfTypeCoorporate$ = this.$session.isCurrentOrganizationUnitIsOfTypeCustomer$.pipe(takeUntil(this.stop$));
    this.translations$ = this.$session.getOrganizationUnit$.pipe(
      takeUntil(this.stop$),
      map((organization) => {
        return get(organization, 'i18n_translation');
      }),
    );
    this.hasTemplateOptionsPermission$ = this.$permissions.hasTemplateOptionsPermission$.pipe(takeUntil(this.stop$));
    this.hasActiveUsersEnabled$ = this.$permissions.hasActiveUsersEnabled$.pipe(takeUntil(this.stop$));
    this.hasCustomerSymbolsPermission$ = this.$permissions.hasCustomerSymbolsPermission$.pipe(takeUntil(this.stop$));
    this.hasKonnectIntegrationPermission$ = this.$permissions.hasKonnectIntegrationPermission$.pipe(takeUntil(this.stop$));
    this.canSetKonnectIntegrationSettingsPermission$ = this.$permissions.canSetKonnectIntegrationSettingsPermission$.pipe(takeUntil(this.stop$));
    this.hasJibbieIntegrationPermission$ = this.$permissions.hasJibbieIntegrationPermission$.pipe(takeUntil(this.stop$));
    this.hasNewsItemPermission$ = this.$permissions.hasNewsItemPermission$.pipe(takeUntil(this.stop$));
    this.hasAnalyticsPermission$ = this.$permissions.hasAnalyticsPermission$.pipe(takeUntil(this.stop$));
    this.hasCustomRecommendationPermission$ = this.$permissions.hasCustomRecommendationPermission$.pipe(takeUntil(this.stop$));
    this.hasProgramExplanationPermission$ = this.$permissions.hasProgramExplanationPermission$.pipe(takeUntil(this.stop$));
    this.canSeeUnpublishedProgramCountPermission$ = this.$permissions.canSeeUnpublishedProgramCountPermission$.pipe(takeUntil(this.stop$));
    this.manageReviewsPermission$ = this.$permissions.manageReviewsPermission$.pipe(takeUntil(this.stop$));
    this.showPdfWriterSettings$ = this.$preference.pdfRendererPreference$.pipe(
      takeUntil(this.stop$),
      map((rendererPreference) => rendererPreference === 'pdf-writer'),
    );
    this.ouCountryCode$ = this.$session.ouCountryCode$.pipe(takeUntil(this.stop$));

    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      lrkIdentifier: [''],
      address: [''],
      city: [''],
      country: [''],
      postalcode: [''],
      phone_number: [''],
      email: ['', Validators.email],
      activity_type_id: [''],
      billing_address: [''],
      billing_city: [''],
      billing_postalcode: [''],
      contentLanguages: [[], Validators.required],

      // permissions
      // plus
      template_settings: [false],

      // pro
      customer_symbols: [false],
      activity_copy: [false],
      program_template_create: [false],
      i18n_translate: [false],
      konnect_integration: [false],
      jibbie_integration: [false],

      // expert
      enable_active_users: [false],
      analytics: [false],
      news_item_create: [false],
      has_custom_recommendation: [false],

      // remaining
      disable_activity_create: [false],
      can_add_program_explanations: [false],
      can_see_unpublished_program_count: [false],
      manage_reviews: [false],
      test_organization: [false],
    });

    this.permissionsMap = [
      {
        shortKey: 'PLUS',
        viewValue: _('permission_category.plus'),
        alsoSets: [],
        permissions: [{
          shortKey: 'TS',
          viewValue: _('permission.template_settings'),
          formControlName: 'template_settings',
          disables: [],
          visible: true,
        }],
        inheritingPermissions: [],
      },
      {
        shortKey: 'PRO',
        viewValue: _('permission_category.pro'),
        alsoSets: ['PLUS'],
        permissions: [
          {
            shortKey: 'CS',
            viewValue: _('permission.customer_symbols'),
            formControlName: 'customer_symbols',
            disables: [],
            visible: true,
          },
          {
            shortKey: 'AC',
            viewValue: _('permission.activity_copy'),
            formControlName: 'activity_copy',
            disables: [],
            visible: true,
          },
          {
            shortKey: 'PT',
            viewValue: _('permission.program_template_create'),
            formControlName: 'program_template_create',
            disables: [],
            visible: true,
          },
          {
            shortKey: 'TR',
            viewValue: _('permission.own_translations'),
            formControlName: 'i18n_translate',
            disables: [],
            visible: true,
          },
        ],
        inheritingPermissions: [],
      },
      {
        shortKey: 'EXPERT',
        viewValue: _('permission_category.expert'),
        alsoSets: ['PRO', 'PLUS'],
        permissions: [{
          shortKey: 'AU',
          viewValue: _('permission.enable_active_users'),
          formControlName: 'enable_active_users',
          disables: [],
          visible: true,
        }],
        inheritingPermissions: [
          {
            shortKey: 'IS',
            viewValue: _('permission.analytics'),
            formControlName: 'analytics',
            disables: [],
            visible: true,
            visibleForOuType$: this.isOrganizationOfTypeCustomer$,
          },
          {
            shortKey: 'NW',
            viewValue: _('permission.news_item_create'),
            formControlName: 'news_item_create',
            disables: [],
            visible: true,
            visibleForOuType$: this.isOrganizationOfTypeCustomer$,
          },
          {
            shortKey: 'CR',
            viewValue: _('permission.has_custom_recommendation'),
            formControlName: 'has_custom_recommendation',
            disables: [],
            visible: true,
            visibleForOuType$: this.isOrganizationOfTypeCustomer$,
            visibleForOuType2$: this.isOrganizationOfTypeLocation$,
          },
          {
            shortKey: 'MR',
            viewValue: _('permission.manage_reviews'),
            formControlName: 'manage_reviews',
            disables: [],
            visible: true,
            visibleForOuType$: this.isOrganizationOfTypeCustomer$,
          },
        ],
      },
    ];

    this.remainingPermissions = [
      {
        shortKey: 'DAC',
        viewValue: _('permission.disable_activity_create'),
        formControlName: 'disable_activity_create',
        disables: [],
        visible: true,
        visibleForOuType$: this.isOrganizationOfTypeCustomer$,
        visibleForOuType2$: this.isOrganizationOfTypeLocation$,
      },
      {
        shortKey: 'CAPE',
        viewValue: _('permission.can_add_program_explanations'),
        formControlName: 'can_add_program_explanations',
        disables: [],
        visible: true,
        visibleForOuType$: this.isOrganizationOfTypeCustomer$,
        visibleForOuType2$: this.isOrganizationOfTypeLocation$,
      },
      {
        shortKey: 'CSUPC',
        viewValue: _('permission.can_see_unpublished_program_count'),
        formControlName: 'can_see_unpublished_program_count',
        disables: [],
        visible: true,
        visibleForOuType$: this.isOrganizationOfTypeCustomer$,
        visibleForOuType2$: this.isOrganizationOfTypeLocation$,
      },
      {
        shortKey: 'KO',
        viewValue: _('permission.konnect_integration'),
        formControlName: 'konnect_integration',
        disables: [],
        visible: true,
        visibleForOuType$: this.isOrganizationOfTypeCustomer$,
        visibleForOuType2$: this.isOrganizationOfTypeLocation$,
      },
      {
        shortKey: 'JI',
        viewValue: _('permission.jibbie_integration'),
        formControlName: 'jibbie_integration',
        disables: [],
        visible: true,
        visibleForOuType$: this.isOrganizationOfTypeCustomer$,
      },
    ];

    this.months$ = combineLatest([
      of(range(12)),
      this.$translateService.onInitialLangAndLangChange$,
    ]).pipe(
      takeUntil(this.stop$),
      map(([monthNumbers]) => monthNumbers.map((monthNum) => ({
        value: monthNum + 1,
        viewValue: this.$translateService.getDayjsLocaleInstance().month(monthNum).format('MMMM'),
      }))),
    );

    this.$session.getOrganizationUnit$.pipe(takeUntil(this.stop$)).subscribe((organisationUnit) => {
      this.ouChange$.next();
      this.possibleContentLanguages$.next(LANGUAGES_FOR_COUNTRIES[organisationUnit.country_code] ?? []);
      const contentLanguageControl = this.form.get('contentLanguages');
      const countryControl = this.form.get('country');
      contentLanguageControl.setValue(organisationUnit.languages);
      if (isNil(organisationUnit.parent_organization_unit_id)) {
        countryControl.enable();
        contentLanguageControl.disable();
      } else {
        countryControl.disable();
        contentLanguageControl.enable();

        this.possibleContentLanguages$.pipe(
          takeUntil(this.stop$),
          takeUntil(this.ouChange$),
        ).subscribe((contentLanguages) => {
          if (contentLanguages.length <= 1) {
            this.form.get('contentLanguages').disable();
          } else if (contentLanguages.length > 1) {
            this.form.get('contentLanguages').enable();
          }
        });
      }
    });

    this.form.get('country').valueChanges.pipe(
      takeUntil(this.stop$),
    ).subscribe((country) => {
      const validLanguages: string[] = LANGUAGES_FOR_COUNTRIES[country] ?? [];
      this.possibleContentLanguages$.next(validLanguages);
      const contentLanguageControl = this.form.get('contentLanguages');
      const selectedContentLanguages = contentLanguageControl.value.filter((contentLanguage) => validLanguages.find((validLanguage) => validLanguage === contentLanguage));
      contentLanguageControl.setValue(selectedContentLanguages.length === 0 ? validLanguages : selectedContentLanguages);
    });

    this.isReader$.pipe(takeUntil(this.stop$)).subscribe((isReader) => {
      if (isReader && this.form.enabled) {
        this.form.disable();
      } else if (!isReader && this.form.disabled) {
        this.form.enable();
      }
    });
  }

  async ngOnInit() {
    this.isLoading$.next(true);

    this.form.get('lrkIdentifier').disable();

    this.konnectIntegrationPermissionDisabled$.pipe(takeUntil(this.stop$)).subscribe((disabled: boolean) => {
      if (disabled) {
        this.form.get('konnect_integration').disable();
      } else {
        this.form.get('konnect_integration').enable();
      }
    });

    this.disableActivityCreatePermissionDisabled$.pipe(takeUntil(this.stop$)).subscribe((disabled: boolean) => {
      if (disabled) {
        this.form.get('disable_activity_create').disable();
      } else {
        this.form.get('disable_activity_create').enable();
      }
    });

    this.jibbieIntegrationPermissionDisabled$.pipe(takeUntil(this.stop$)).subscribe((disabled: boolean) => {
      if (disabled) {
        this.form.get('jibbie_integration').disable();
      } else {
        this.form.get('jibbie_integration').enable();
      }
    });

    this.analyticsPermissionDisabled$.pipe(takeUntil(this.stop$)).subscribe((disabled: boolean) => {
      if (disabled) {
        this.form.get('analytics').disable();
      } else {
        this.form.get('analytics').enable();
      }
    });

    this.newsItemPermissionDisabled$.pipe(takeUntil(this.stop$)).subscribe((disabled: boolean) => {
      if (disabled) {
        this.form.get('news_item_create').disable();
      } else {
        this.form.get('news_item_create').enable();
      }
    });

    this.customRecommendationPermissionDisabled$.pipe(takeUntil(this.stop$)).subscribe((disabled: boolean) => {
      if (disabled) {
        this.form.get('has_custom_recommendation').disable();
      } else {
        this.form.get('has_custom_recommendation').enable();
      }
    });

    this.programExplanationPermissionDisabled$.pipe(takeUntil(this.stop$)).subscribe((disabled: boolean) => {
      if (disabled) {
        this.form.get('can_add_program_explanations').disable();
      } else {
        this.form.get('can_add_program_explanations').enable();
      }
    });

    this.canSeeUnpublishedProgramCountPermissionDisabled$.pipe(takeUntil(this.stop$)).subscribe((disabled: boolean) => {
      if (disabled) {
        this.form.get('can_see_unpublished_program_count').disable();
      } else {
        this.form.get('can_see_unpublished_program_count').enable();
      }
    });

    this.manageReviewsPermissionDisabled$.pipe(takeUntil(this.stop$)).subscribe((disabled: boolean) => {
      if (disabled) {
        this.form.get('manage_reviews').disable();
      } else {
        this.form.get('manage_reviews').enable();
      }
    });

    combineLatest([
      this.hasCustomerSymbolsPermission$,
      this.hasTemplateOptionsPermission$,
      this.$permissions.hasTranslationPermission$,
      this.$permissions.hasActivityCopyPermission$,
      this.$permissions.hasProgramTemplateCreatePermission$,
      this.hasKonnectIntegrationPermission$,
      this.$permissions.hasDisableActivityCreatePermission$,
      this.hasJibbieIntegrationPermission$,
      this.hasActiveUsersEnabled$,
      this.hasAnalyticsPermission$,
      this.hasNewsItemPermission$,
      this.hasCustomRecommendationPermission$,
      this.hasProgramExplanationPermission$,
      this.canSeeUnpublishedProgramCountPermission$,
      this.manageReviewsPermission$,
    ])
      .pipe(
        takeUntil(this.stop$),
        map(
          ([
            customerSymbolsPerm,
            templateOptionsPerm,
            translationPerm,
            activityCopyPerm,
            programTemplateCreatePerm,
            konnectIntegrationPerm,
            disableActivityCreatePerm,
            jibbieIntegrationPerm,
            enableActiveUsersPerm,
            analyticsPerm,
            newsItemCreatePerm,
            customRecommendationPerm,
            programExplanationPerm,
            canSeeUnpublishedProgramCountPerm,
            manageReviewsPerm,
          ]) => {
            const permissionsObj = {
              template_settings: templateOptionsPerm,
              customer_symbols: customerSymbolsPerm,
              i18n_translate: translationPerm,
              activity_copy: activityCopyPerm,
              program_template_create: programTemplateCreatePerm,
              konnect_integration: konnectIntegrationPerm,
              disable_activity_create: disableActivityCreatePerm,
              jibbie_integration: jibbieIntegrationPerm,
              enable_active_users: enableActiveUsersPerm,
              analytics: analyticsPerm,
              news_item_create: newsItemCreatePerm,
              has_custom_recommendation: customRecommendationPerm,
              can_add_program_explanations: programExplanationPerm,
              can_see_unpublished_program_count: canSeeUnpublishedProgramCountPerm,
              manage_reviews: manageReviewsPerm,
            };
            return permissionsObj;
          },
        ),
      )
      .subscribe((permissions) => {
        this.form.patchValue(permissions);
      });

    combineLatest([this.$session.getOrganizationUnit$, this.isAdmin$])
      .pipe(takeUntil(this.stop$), distinctUntilChanged((prev, current) =>  isEqual(prev, current)))
      .subscribe(async ([organization, isAdmin]) => {
        if (organization.organization_unit_type_id === DoenkidsStaticValuesHelper.ORGANIZATION_UNIT_TYPE_GROUP) {
          this.router.navigate(['/']);
        } else if (this.selectedOrganizationUnitId !== null && organization.id !== this.selectedOrganizationUnitId) {
          this.router.navigate([`/organization/${organization.id}/overview`], { replaceUrl: true, queryParamsHandling: 'merge' });
        }

        this.selectedOrganizationUnitId = organization.id;
        this.selectedOrganizationUnit = organization;
        const {
          name,
          address,
          city,
          country_code,
          postalcode,
          phone_number,
          email,
          billing_address,
          billing_postalcode,
          billing_city,
          lrk_identifier,
          test_organization,
          activity_type_id,
        } = organization;
        this.form.patchValue({
          name,
          address,
          city,
          country: country_code.toLowerCase(),
          postalcode,
          phone_number,
          email,
          billing_address,
          billing_postalcode,
          billing_city,
          lrkIdentifier: lrk_identifier,
          test_organization,
          activity_type_id: activity_type_id ?? 'IKC',
        });

        this.possibleActivityTypes = this.activityTypeQuery.getActivityTypeByCountryCode(organization.country_code);

        if (isAdmin) {
          this.checkIfTrickleDownPermissionsShouldBeDeactivated(organization);
          this.fetchFinancialNote();
        }

        const hasWritePermissions = await firstValueFrom(this.$permissions.hasParentOUWritePermissions$);
        if (!isAdmin && !hasWritePermissions) {
          this.readOnly = true;
          this.inputAppearance = null;
        } else if (hasWritePermissions) {
          this.readOnly = false;
          this.inputAppearance = 'fill';
        }
      });

    combineLatest([this.isOrganizationOfTypeCoorporate$, this.isOrganizationOfTypeCustomer$, this.isOrganizationOfTypeLocation$]).pipe(
      debounceTime(300),
      takeUntil(this.stop$),
    ).subscribe(() => {
      this.checkAllPermissionsVisibility();
    });

    this.fetchJibbieDetails();

    combineLatest([
      this.isAdmin$,
      this.hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$,
      this.isDoenkidsOrganization$,
      this.isOrganizationOfTypeCoorporate$,
      this.isOrganizationOfTypeCustomer$,
      this.isOrganizationOfTypeLocation$,
      this.$translateService.onInitialTranslationAndLangOrTranslationChange$,
    ])
      .pipe(takeUntil(this.stop$), debounceTime(100))
      .subscribe(([isAdmin, hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree, isDoenkidsOrganization, isCoorporate, isCustomer, isLocation]) => {
        const newFloatingActionButtons = [];

        if (isAdmin) {
          newFloatingActionButtons.push(CHANGE_PARENT_NODE);

          if (isDoenkidsOrganization) {
            newFloatingActionButtons.push(ADD_ROOT_ORGANIZATION);
          }

          if (isCoorporate || isCustomer) {
            newFloatingActionButtons.push(ADD_ORGANIZATION);
            newFloatingActionButtons.push(ADD_LOCATION);
          }
        }

        if (isLocation && (isAdmin || hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree)) {
          newFloatingActionButtons.push(ADD_GROUP);
        }

        this.floatingActionButtons = newFloatingActionButtons.map((floatingActionButton) => ({
          ...floatingActionButton,
          label: this.$translateService.instant(floatingActionButton.label),
        }));
      });

    this.financialForm.valueChanges.pipe(
      takeUntil(this.stop$),
      skip(1),
    ).subscribe(() => {
      this.form.markAsDirty();
    });

    this.isLoading$.next(false);
  }

  ngAfterViewInit() {
    combineLatest([this.tabGroup._allTabs.changes.pipe(startWith(this.tabGroup._allTabs)), this.$route.queryParams]).pipe(
      takeUntil(this.stop$),
    ).subscribe(([tabs, queryParams]) => {
      if (queryParams.tab) {
        const allTabs: MatTab[] = tabs._results;
        const tabIndex = allTabs.findIndex((tab) => tab.textLabel === queryParams.tab);

        if (tabIndex > -1 && tabIndex !== this.selectedTabIndex) {
          this.selectedTabIndex = tabIndex;
        }
      } else if (this.selectedTabIndex !== 0) {
        this.selectedTabIndex = 0;
      }
    });
  }

  ngOnDestroy(): void {
    this.stop$.next(undefined);
  }

  async speedDialClick(action: string) {
    const organization = await firstValueFrom(this.$session.getOrganizationUnit$);
    switch (action) {
      case 'change-parent-ou':
        this.openChangeParentOUDialog();
        break;
      case 'add-root':
        this.openAddOrganizationDialog(null, 2);
        break;
      case 'add-organisation':
        this.openAddOrganizationDialog(organization, 2);
        break;
      case 'add-location':
        this.openAddOrganizationDialog(organization, 3);
        break;
      case 'add-group':
        this.openAddOrganizationDialog(organization, 4);
        break;
      default:
        break;
    }
  }

  goToActivities() {
    this.router.navigate([`organization/${this.selectedOrganizationUnitId}/activity`]);
  }

  goToActiveUsers() {
    this.router.navigate([`organization/${this.selectedOrganizationUnitId}/active-users`]);
  }

  private async openAddOrganizationDialog(dialogParent, dialogOUType) {
    const dialogRef = this.matDialog.open(AddOrganizationDialogComponent, {
      width: '800px',
      height: '800px',
      data: {
        organizationUnitType: dialogOUType,
        parentOU: dialogParent,
        currentValueKonnectIntegration: this.form.get('konnect_integration').value,
        currentValueDisableActivityCreate: this.form.get('disable_activity_create').value,
      },
    });

    const dialogResult = await firstValueFrom(dialogRef.afterClosed());

    // if we get a result and it is a number it is the new organization id and we navigate to it
    //
    if (dialogResult && !isNaN(dialogResult)) {
      this.router.navigate([`/organization/${dialogResult}/overview`], { replaceUrl: true });
    }
  }

  private async openChangeParentOUDialog() {
    const ouDetails = await firstValueFrom(this.$session.getOrganizationUnit$);
    const ouParent = ouDetails.parent_organization_unit_id ? await this.organizationUnitService.fetch(ouDetails.parent_organization_unit_id) : undefined;

    const dialogRef = this.matDialog.open(BrowseOrganizationUnitDialogComponent, {
      data: {
        title: this.$translateService.instant(_('organization_unit.parent.change.dialog.title')),
        description: this.$translateService.instant(
          _('organization_unit.parent.change.dialog.description'),
          { organization: ouDetails.name },
        ),
        initialBrowsingOrganizationUnitId: ouParent?.parent_organization_unit_id,
        initialOrganizationUnitSelection: ouParent ? [{
          id: ouParent.id,
          name: ouParent.name,
          organization_unit_type_id: ouParent.organization_unit_type_id,
        }] : undefined,
        addNewRootNodeOption: !!ouDetails.parent_organization_unit_id,
      },
    });

    const selection: ISelectedOrganizationUnit[] = await firstValueFrom(dialogRef.afterClosed());
    const result = selection && selection[0];

    // if we get a result and it is the new parent ou. (id can be null but that is fine because it becomes a new root node)
    //
    if (result) {
      this.isLoading$.next(true);

      const updatedOU: IOrganizationUnit = {
        id: ouDetails.id,
        organization_unit_type_id: ouDetails.organization_unit_type_id,
        name: ouDetails.name,
        parent_organization_unit_id: result.id === -1 ? null : result.id,
      };

      await this.organizationUnitService.update(updatedOU);

      if (result.id > 0) {
        this.$i18nToastProvider.success(
          _('organization_unit.parent.change.under_organization'),
          {
            organization: ouDetails.name,
            parent: result.name,
          },
        );
      } else {
        this.$i18nToastProvider.success(
          _('organization_unit.parent.change.to_root'),
          { organization: ouDetails.name },
        );
      }

      this.isLoading$.next(false);
    }
  }

  async saveDetails() {
    this.isLoading$.next(true);

    try {
      const isAdmin = await firstValueFrom(this.isAdmin$);

      if (this.form.get('name').dirty || this.form.get('country').dirty || this.form.get('contentLanguages').dirty || this.form.get('activity_type_id').dirty) {
        const countryCode = (this.form.get('country')?.value ?? '').toUpperCase();

        const activityTypeIdFormValue = this.form.get('activity_type_id').value;

        await this.organizationUnitService.update({
          id: this.selectedOrganizationUnitId,
          name: this.form.get('name').value,
          country_code: countryCode,
          languages: this.form.get('contentLanguages').value.map((language) => language.toLowerCase()),
          activity_type_id: activityTypeIdFormValue === 'IKC' ? null : activityTypeIdFormValue,
        });
      }

      let ouDetails: IOrganizationUnitDetails;
      if (isAdmin) {
        ouDetails = {
          organization_unit_id: this.selectedOrganizationUnitId,
          address: this.form.get('address').value,
          postalcode: this.form.get('postalcode').value,
          city: this.form.get('city').value,
          phone_number: this.form.get('phone_number').value,
          email: this.form.get('email').value,
          billing_address: this.form.get('billing_address').value,
          billing_postalcode: this.form.get('billing_postalcode').value,
          billing_city: this.form.get('billing_city').value,
          test_organization: this.form.get('test_organization').value,
        };

        await this.$organizationUnitDetail.update(ouDetails);
      } else if (!isAdmin && this.form.get('test_organization').dirty) {
        ouDetails = {
          organization_unit_id: this.selectedOrganizationUnitId,
          test_organization: this.form.get('test_organization').value,
        };
      }

      if (ouDetails) {
        await this.$organizationUnitDetail.update(ouDetails);
      }

      if (isAdmin) {
        const ouPermission: IOrganizationUnitPermission = {
          template_settings: this.form.get('template_settings').value,
          customer_symbols: this.form.get('customer_symbols').value,
          organization_unit_id: this.selectedOrganizationUnitId,
          i18n_translate: this.form.get('i18n_translate').value,
          activity_copy: this.form.get('activity_copy').value,
          program_template_create: this.form.get('program_template_create').value,
          konnect_integration: this.form.get('konnect_integration').value,
          disable_activity_create: this.form.get('disable_activity_create').value,
          jibbie_integration: this.form.get('jibbie_integration').value,
          enable_active_users: this.form.get('enable_active_users').value,
          analytics: this.form.get('analytics').value,
          news_item_create: this.form.get('news_item_create').value,
          custom_recommendations: this.form.get('has_custom_recommendation').value,
          can_add_program_explanations: this.form.get('can_add_program_explanations').value,
          can_see_unpublished_program_count: this.form.get('can_see_unpublished_program_count').value,
          manage_reviews: this.form.get('manage_reviews').value,
        };

        await this.organizationUnitPermissionService.update(ouPermission);
      }

      if (isAdmin) {
        if (Object.values(this.financialForm.value).some((value) => !isNil(value))) {
          const { financialNote, billingStartMonth, billingFrequency } = this.financialForm.getRawValue();
          await this.$organizationUnitDetail.saveFinancialNote(this.selectedOrganizationUnitId, {
            note: financialNote,
            start_month: billingStartMonth,
            frequency: billingFrequency,
          });
          await this.fetchFinancialNote();
        }
      }

      // we do this to update the permissions that the user has
      //
      this.currentUserService.fetch();
    } catch (e) {
      this.$i18nToastProvider.error(_('organization_unit.update.failed'));
    }

    this.isLoading$.next(false);
  }

  setChildListLoading($event: boolean) {
    this.isLoading$.next($event);
  }

  async checkIfTrickleDownPermissionsShouldBeDeactivated(organization: IOrganizationUnitOverview) {
    const parentOuIds = organization.node_path.map((value) => parseInt(value.toString(), 10)).filter((ouId) => ouId !== organization.id);

    if (parentOuIds.length === 0) {
      this.konnectIntegrationPermissionDisabled$.next(false);
      this.jibbieIntegrationPermissionDisabled$.next(false);
      this.disableActivityCreatePermissionDisabled$.next(false);
      this.analyticsPermissionDisabled$.next(false);
      this.newsItemPermissionDisabled$.next(false);
      this.customRecommendationPermissionDisabled$.next(false);
      this.programExplanationPermissionDisabled$.next(false);
      this.canSeeUnpublishedProgramCountPermissionDisabled$.next(false);
      this.manageReviewsPermissionDisabled$.next(false);
      this.trickleDownPermissionsText$.next('');
      this.parentPermissionOrigins$.next({});
    } else {
      const permissionOverview = await this.organizationUnitPermissionService.getOverview(organization.id);

      const parentsPromises: Promise<IOrganizationUnit>[] = [];
      parentOuIds.forEach((ouId) => {
        parentsPromises.push(this.organizationUnitService.fetch(ouId));
      });
      const parents = await Promise.all(parentsPromises);

      // Find out which permission came from which parent
      const permissionsFromParents: { [key: string]: IOrganizationUnit } = {};
      Object.keys(permissionOverview.appliedPermissions).forEach((permissionName: string) => {
        const hasPermissionFromParent = get(permissionOverview.permissions, permissionName) === false && get(permissionOverview.appliedPermissions, permissionName) === true;
        if (hasPermissionFromParent) {
          // Determine which parent it came from
          reverse(permissionOverview.parentPermissions).forEach((parentPermission) => {
            if (get(parentPermission, permissionName)) {
              // Got it from this parent
              permissionsFromParents[permissionName] = parents
                .find((organisationUnit) => organisationUnit.id === parentPermission.organization_unit_id);
            }
          });
        }
      });
      this.parentPermissionOrigins$.next(permissionsFromParents);

      this.permissionOverview$.next(permissionOverview);

      let underlyingType = 'organization';

      const konnectIntegrationFromParent = permissionOverview.appliedPermissions.konnect_integration === true
        && permissionOverview.appliedPermissions.konnect_integration !== permissionOverview.permissions.konnect_integration;
      this.konnectIntegrationPermissionDisabled$.next(konnectIntegrationFromParent);

      const disableActivityCreateFromParent = permissionOverview.appliedPermissions.disable_activity_create === true
        && permissionOverview.appliedPermissions.disable_activity_create !== permissionOverview.permissions.disable_activity_create;
      this.disableActivityCreatePermissionDisabled$.next(disableActivityCreateFromParent);

      const jibbieIntegrationFromParent = permissionOverview.appliedPermissions.jibbie_integration === true
        && permissionOverview.appliedPermissions.jibbie_integration !== permissionOverview.permissions.jibbie_integration;
      this.jibbieIntegrationPermissionDisabled$.next(jibbieIntegrationFromParent);

      const analyticsFromParent = permissionOverview.appliedPermissions.analytics === true
        && permissionOverview.appliedPermissions.analytics !== permissionOverview.permissions.analytics;
      this.analyticsPermissionDisabled$.next(analyticsFromParent);

      const newsItemFromParent = permissionOverview.appliedPermissions.news_item_create === true
        && permissionOverview.appliedPermissions.news_item_create !== permissionOverview.permissions.news_item_create;
      this.newsItemPermissionDisabled$.next(newsItemFromParent);

      const customRecommendationFromParent = permissionOverview.appliedPermissions.custom_recommendations === true
        && permissionOverview.appliedPermissions.custom_recommendations !== permissionOverview.permissions.custom_recommendations;
      this.customRecommendationPermissionDisabled$.next(customRecommendationFromParent);

      const programExplanationFromParent = permissionOverview.appliedPermissions.can_add_program_explanations === true
        && permissionOverview.appliedPermissions.can_add_program_explanations !== permissionOverview.permissions.can_add_program_explanations;
      this.programExplanationPermissionDisabled$.next(programExplanationFromParent);

      const canSeeUnpublishedProgramCountFromParent = permissionOverview.appliedPermissions.can_see_unpublished_program_count === true
        && permissionOverview.appliedPermissions.can_see_unpublished_program_count !== permissionOverview.permissions.can_see_unpublished_program_count;
      this.canSeeUnpublishedProgramCountPermissionDisabled$.next(canSeeUnpublishedProgramCountFromParent);

      const manageReviewsFromParent = permissionOverview.appliedPermissions.manage_reviews === true
        && permissionOverview.appliedPermissions.manage_reviews !== permissionOverview.permissions.manage_reviews;
      this.manageReviewsPermissionDisabled$.next(manageReviewsFromParent);

      // eslint-disable-next-line max-len
      if (konnectIntegrationFromParent || disableActivityCreateFromParent || jibbieIntegrationFromParent || analyticsFromParent || newsItemFromParent || customRecommendationFromParent || programExplanationFromParent || canSeeUnpublishedProgramCountFromParent) {
        this.trickleDownPermissionsText$.next(
          this.$translateService.instant(
            _('organization_unit.permissions.trickle_down'),
            {
              underlyingType,
            },
          ),
        );
      } else {
        this.trickleDownPermissionsText$.next('');
      }
    }
  }

  async syncJibbie() {
    try {
      const response = await this.jibbieOrganizationService.sync();

      if (response.code >= 200 && response.code <= 299) {
        this.$i18nToastProvider.success(_('organization_unit_details.jibbie.sync.success'));
      } else {
        console.log('Jibbie sync failed', response);
        this.$i18nToastProvider.error(_('organization_unit_details.jibbie.sync.failed'));
      }
    } catch (e) {
      console.log('Jibbie sync failed', e);
      this.$i18nToastProvider.error(_('organization_unit_details.jibbie.sync.failed'));
    }
  }

  async toggleJibbie(event: MatSlideToggleChange) {
    if (event.checked) {
      await this.connectJibbieOU();
    } else {
      await this.unlinkJibbieOU();
    }
  }

  async fetchJibbieDetails() {
    const fetchedJibbieDetails = await this.organizationUnitService.jibbieDetails(this.selectedOrganizationUnitId);

    if (fetchedJibbieDetails.src !== 'none') {
      this.jibbieDetails = fetchedJibbieDetails;
      this.isLinkedWithJibbie = isObject(fetchedJibbieDetails);
    } else {
      this.jibbieDetails = null;
      this.isLinkedWithJibbie = false;
    }
  }

  async connectJibbieOU() {
    const dialogRef = this.matDialog.open(ConnectJibbieOuDialogComponent, {
      width: '800px',
      height: '800px',
      data: {
        organizationUnitType: this.selectedOrganizationUnit.organization_unit_type_id,
        organizationUnitName: this.selectedOrganizationUnit.name,
        organizationUnitId: this.selectedOrganizationUnit.id,
        parentOUId: this.selectedOrganizationUnit.parent_organization_unit_id,
        parentOUName: this.selectedOrganizationUnit.parent_organization_unit_name,
      },
    });

    await firstValueFrom(dialogRef.afterClosed());

    this.fetchJibbieDetails();
  }

  async unlinkJibbieOU() {
    const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
      width: '400px',
      minWidth: '320px',
      data: {
        title: this.$translateService.instant(_('organization_unit.jibbie.unlink.dialog.title')),
        description: this.$translateService.instant(_('organization_unit.jibbie.unlink.dialog.description'), { name: this.form.value.name }),
      },
    });

    const result = await firstValueFrom(dialogRef.afterClosed());
    if (result === 'confirm') {
      // TODO don't cast jibbie details to any
      if (this.selectedOrganizationUnit.organization_unit_type_id === EOrganizationUnitType.LOCATION) {
        await this.jibbieLocationService.unsetOrganizationUnit((this.jibbieDetails as any).jibbie_id);
      } else if (this.selectedOrganizationUnit.organization_unit_type_id === EOrganizationUnitType.ORGANIZATION) {
        await this.jibbieOrganizationService.unsetOrganizationUnit((this.jibbieDetails as any).jibbie_id);
      }
      this.fetchJibbieDetails();
    }
  }

  organizationIsAlreadyLinkedToJibbie() {
    return this.jibbieDetails != null;
  }

  exportOUData() {
    this.organizationUnitService.exportOUDetails(this.selectedOrganizationUnitId, `${this.selectedOrganizationUnit.name}_export`);
  }

  /**
   * Download financial data for the selected organization unit in XLS format
   */
  exportFinance() {
    this.organizationUnitService.exportFinance(this.selectedOrganizationUnitId, `${this.selectedOrganizationUnit.name}_finance`);
  }

  async canDeactivate(): Promise<boolean> {
    const { newsItemForm } = this.manageNewsfeedComponent;

    const pendingChanges = newsItemForm.pristine === false;

    let leavePage = true;

    if (pendingChanges) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '400px',
        minWidth: '320px',
        data: {
          title: this.$translateService.instant(_('organization_unit.unsaved_changes.dialog.title')),
          description: this.$translateService.instant(_('organization_unit.unsaved_changes.dialog.description')),
        },
      });

      const result = await firstValueFrom(dialogRef.afterClosed());

      if (result !== 'confirm') {
        leavePage = false;
      }
    }

    return leavePage;
  }

  onScroll(event) {
    this.scrolled$.next(event.target.scrollTop > 0);
  }

  async checkAllPermissionsVisibility() {
    for (const category of this.permissionsMap) {
      for (const permission of category.permissions) {
        // eslint-disable-next-line no-await-in-loop
        permission.visible = await this.permissionIsVisible(permission);
      }
      for (const inheritedPermission of category.inheritingPermissions) {
        // eslint-disable-next-line no-await-in-loop
        inheritedPermission.visible = await this.permissionIsVisible(inheritedPermission);
      }
    }

    for (const permission of this.remainingPermissions) {
      // eslint-disable-next-line no-await-in-loop
      permission.visible = await this.permissionIsVisible(permission);
    }
  }

  async permissionIsVisible(permission: IPermissionRule) {
    const hasVisibleForOuType = !isNil(permission.visibleForOuType$);
    const visibleForOuType = hasVisibleForOuType ? await firstValueFrom(permission.visibleForOuType$) : false;

    const hasVisibleForOuType2 = !isNil(permission.visibleForOuType2$);
    const visibleForOuType2 = hasVisibleForOuType2 ? await firstValueFrom(permission.visibleForOuType2$) : false;

    return ((!hasVisibleForOuType && !hasVisibleForOuType2) || ((hasVisibleForOuType && visibleForOuType) && !hasVisibleForOuType2) || (visibleForOuType || visibleForOuType2));
  }

  hasAllPermissions(category: IPermissionCategory) {
    const hasNoInheritedPermission = category.inheritingPermissions.length === 0;

    return category.permissions.every((permission) => (!permission.visible ? true : (this.form.get(permission.formControlName)?.value ?? false)))
      && (hasNoInheritedPermission || category.inheritingPermissions.every(
        (inheritedPermission) => (!inheritedPermission.visible ? true : (this.form.get(inheritedPermission.formControlName)?.value ?? false)),
      ));
  }

  hasSomePermissions(category: IPermissionCategory) {
    const hasEveryPermission = this.hasAllPermissions(category);
    const hasSomePermissions = category.permissions.some((permission) => (!permission.visible ? false : (this.form.get(permission.formControlName)?.value ?? false)));
    const hasSomeInheritedPermission = category.inheritingPermissions.some(
      (inheritedPermission) => (!inheritedPermission.visible ? false : (this.form.get(inheritedPermission.formControlName)?.value ?? false)),
    );

    return !hasEveryPermission && (hasSomePermissions || hasSomeInheritedPermission);
  }

  setCategoryPermissions(category: IPermissionCategory, checked: boolean) {
    category.permissions.forEach((permission) => {
      if (permission.visible) {
        this.form.get(permission.formControlName).setValue(checked);
      }
    });

    category.inheritingPermissions.forEach((inheritedPermission) => {
      const formControl = this.form.get(inheritedPermission.formControlName);

      if (inheritedPermission.visible && !formControl.disabled) {
        formControl.setValue(checked);
      }
    });

    for (const siblingCategory of category.alsoSets) {
      const foundCategory = this.permissionsMap.find((findingCategory) => findingCategory.shortKey === siblingCategory);

      if (foundCategory) {
        this.setCategoryPermissions(foundCategory, checked);
      }
    }
  }

  chooseLrkIdentifier($event?: Event) {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
    const dialogRef = this.dialog.open(UpdateOuLrkIdentifierDialog, {
      width: '600px',
      minWidth: '320px',
      data: {
        currentOu: this.selectedOrganizationUnit,
      },
    });

    firstValueFrom(dialogRef.afterClosed()).then(() => {
      this.$session.selectOrganizationUnit(this.selectedOrganizationUnit.id);
    });
  }

  async clearLrkIdentifier($event?: Event) {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
    await this.$organizationUnitDetail.update({
      organization_unit_id: this.selectedOrganizationUnit.id,
      lrk_identifier: null,
    } as IOrganizationUnitDetails);

    this.$session.selectOrganizationUnit(this.selectedOrganizationUnit.id);
  }

  async fetchFinancialNote() {
    const result = await this.$organizationUnitDetail.fetchFinancialNote(this.selectedOrganizationUnitId) as { note: string, start_month: string, frequency: string };

    if (result) {
      this.financialForm.patchValue({
        financialNote: result.note,
        billingStartMonth: result.start_month,
        billingFrequency: result.frequency,
      }, { emitEvent: false, onlySelf: true });
      this.financialForm.get('financialNote').disable();
    } else {
      this.financialForm.get('financialNote').setValue('');
    }
  }

  getPermissionOrigin(permissionName: string, parentPermissions: any) {
    const organization = get(parentPermissions, permissionName);
    if (organization) {
      return this.$translateService.instant(_('organization_unit.permission.origin'), { organization: organization.name });
    }
    return '';
  }

  editFinancialNote() {
    this.financialForm.get('financialNote').enable();
  }

  getCountryLabel(countryCode: string) {
    const supportedCountryEntry = this.localSupportedCountries.find((supportedCountry) => supportedCountry.countryCode.toLowerCase() === countryCode.toLowerCase());

    // a dot means its not a valid country code that we currently support so just show the country code so they know whats going on
    //
    if (!supportedCountryEntry) {
      return countryCode;
    }

    return this.$translateService.instant(supportedCountryEntry.viewValue);
  }

  handleTabChange(tabChange: MatTabChangeEvent) {
    if (tabChange.index === 0) {
      this.router.navigate([], { relativeTo: this.$route });
    } else {
      this.router.navigate([], { relativeTo: this.$route, queryParams: { tab: tabChange.tab.textLabel } });
    }

  }
}
