<form [formGroup]="activityForm">
  <app-doenkids-menu-bar [showBackButton]="true" [loading]="loading$ | async">
    <div left>
      <app-responsive-toolbar-content icon="expand_more">
        <ng-template #content>
          <mat-menu #addSection="matMenu">
            <ng-container *ngFor="let type of optionalSectionTypes$ | async">
              <button (click)="appendSection(type)" type="button" mat-menu-item [matTooltip]="'activity.section.add' | translate">
                {{ 'activity.section.type.' + getSectionTypeName(type.id) + '.title' | translate }}
              </button>
            </ng-container>
          </mat-menu>
          <button
            [disabled]="(hasOUWritePermissions$ | async) === false"
            mat-icon-button
            type="button"
            [matMenuTriggerFor]="addSection"
            [matTooltip]="'generic.add' | translate"
          >
            <mat-icon>add</mat-icon>
          </button>
          <button
            [disabled]="(hasOUWritePermissions$ | async) === false || (isOwner$ | async) === false"
            mat-icon-button
            type="button"
            (click)="save()"
            [matTooltip]="'generic.save' | translate"
          >
            <mat-icon>save</mat-icon>
          </button>
          <ng-container
            *ngIf="
              (hasActivityCopyPermission$ | async) &&
              (hasOUWritePermissions$ | async) &&
              (activityOwner$ | async)?.organization_unit_id !== (currentOUDetails$ | async)?.id &&
              (currentOUDetails$ | async)?.parent_organization_unit_id !== null
            "
          >
            <button
              mat-icon-button
              type="button"
              (click)="forkActivity()"
              [matTooltip]="'activity.copy.tooltip' | translate"
              *ngIf="(activityDetails$ | async)?.replace_activity_id === null"
            >
              <mat-icon class="icon-btn">call_split</mat-icon>
            </button>
            <button
              mat-icon-button
              type="button"
              (click)="unforkActivity()"
              [matTooltip]="'activity.is_copy' | translate"
              *ngIf="(activityDetails$ | async)?.replace_activity_id !== null"
            >
              <mat-icon class="icon-btn-selected">call_split</mat-icon>
            </button>
          </ng-container>

          <button *ngIf="isAdmin$ | async" mat-icon-button type="button" (click)="showHTML()" [matTooltip]="'activity.html.show' | translate">
            <mat-icon>code</mat-icon>
          </button>

          <button mat-icon-button type="button" (click)="downloadPDF()" [matTooltip]="'activity.pdf.download.tooltip' | translate">
            <mat-icon>get_app</mat-icon>
          </button>

          <button *ngIf="isOwner$ | async" [matTooltip]="'activity.review.tooltip' | translate" (click)="reviewByOrganisation()" mat-icon-button type="button">
            <mat-icon>preview</mat-icon>
          </button>

          <button
            *ngIf="
              (isDoenkidsManagement$ | async) ||
              (((hasOUWritePermissions$ | async) ||
                (hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$ | async) ||
                (hasWritePermissionOnAtLeastOneLocationOUInCurrentNodeTree$ | async)) &&
                (hasActivityCopyPermission$ | async))
            "
            [matTooltip]="'activity.copy.tooltip' | translate"
            (click)="copyActivityWithFields()"
            mat-icon-button
            type="button"
          >
            <mat-icon>content_copy</mat-icon>
          </button>
          <button
            *ngIf="(isLocation$ | async) && (activityDetails$ | async)?.status_id === publishedStatus"
            mat-icon-button
            type="button"
            (click)="createProgram()"
            [matTooltip]="'activity.create-program.tooltip' | translate"
          >
            <mat-icon>date_range</mat-icon>
          </button>

          <ng-container *ngVar="(possibleContentLanguages$ | async) as contentLanguages">
            <mat-form-field
              *ngIf="(hasOUWritePermissions$ | async) && contentLanguages.length > 1"
              appearance="outline"
              class="compact-select content-language-select"
            >
              <mat-label>{{ 'activity.content_language.label' | translate }}</mat-label>
              <mat-select [formControl]="contentLanguageControl">
                <mat-select-trigger *ngIf="contentLanguageControl.value">
                  <mat-icon [svgIcon]="contentLanguageControl.value.toLowerCase()"></mat-icon>
                  {{ 'generic.language.' + contentLanguageControl.value.toLowerCase()| translate }}
                </mat-select-trigger>
                <mat-option
                  *ngFor="let contentLanguage of contentLanguages"
                  [value]="contentLanguage"
                >
                  <mat-icon [svgIcon]="contentLanguage.toLowerCase()"></mat-icon>
                  {{ 'generic.language.' + contentLanguage.toLowerCase() | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </ng-container>

          <ng-container *ngIf="activityDetails$ | async as activityDetails">
            <!-- Activity owner-->
            <ng-container *ngIf="!(activityDetails?.replace_activity_id !== null && (hasActivityCopyPermission$ | async)) || (isAdmin$ | async)">
              <app-owner-chip *ngIf="activityOwner$ | async as owner" [owningOuId]="owner.organization_unit_id"></app-owner-chip>
            </ng-container>

            <!-- Activity status-->
            <mat-form-field
              *ngIf="activityDetails?.status_id as statusId"
              [matTooltip]="(hasOUWritePermissions$ | async) ? ('activity.status.change.tooltip' | translate) : undefined"
              appearance="outline"
              class="compact-select status-select"
            >
              <mat-select [formControl]="activityStatusCtrl">
                <ng-container *ngIf="(activityDetails$ | async)?.status_id as statusId">
                  <ng-container *ngIf="hasOUWritePermissions$ | async">
                    <ng-container *ngFor="let status of activityStatusesToShow$ | async">
                      <mat-option
                        [ngClass]="[getActivityStatusName(statusId)]"
                        [disabled]="(hasOUWritePermissions$ | async) === false"
                        (click)="changeActivityStatus(status.value)"
                        [value]="status.value"
                      >
                        {{ 'activity.status.' + getActivityStatusName(status.value) | translate }}
                      </mat-option>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </mat-select>
            </mat-form-field>

            <ng-container *ngIf="activityDetails?.status_id === EActivityStatusType.REVIEW">
              <button mat-stroked-button class="status-button review">
                {{ 'activity.status.review.reviewed_by' | translate: { user: activityCreatedByUser, organization: activityCreatedByOU } }}
              </button>
            </ng-container>
          </ng-container>
          <mat-menu #activityCustomer="matMenu">
            <ng-container *ngIf="currentOUDetails$ | async; let currentOrganization">
              <ng-container *ngIf="activityDetails$ | async as activityDetails">
                <button
                  *ngIf="
                    activityDetails?.replace_activity_id === null &&
                    currentOrganization.id !== 1 &&
                    currentOrganization.id !== (activityOwner$ | async)?.organization_unit_id
                  "
                  mat-menu-item
                  type="button"
                  (click)="changeActivityOwner(currentOrganization.id)"
                >
                  {{ 'activity.owner.change.to_current_organization' | translate: { organization: currentOrganization?.name } }}
                </button>
                <button
                  *ngIf="activityDetails?.replace_activity_id === null && (activityOwner$ | async)?.organization_unit_id !== 1 && (isAdmin$ | async)"
                  mat-menu-item
                  type="button"
                  (click)="changeActivityOwner(1)"
                >
                  {{ 'activity.owner.change.to_doenkids' | translate }}
                </button>
                <span mat-menu-item *ngIf="currentOrganization.id === (activityOwner$ | async)?.organization_unit_id">
                  {{ 'activity.owner.change.error.already_owned_by_current' | translate: { organization: currentOrganization?.name } }}
                </span>
              </ng-container>
            </ng-container>
          </mat-menu>
        </ng-template>
      </app-responsive-toolbar-content>
    </div>
    <div right *ngIf="activityDetails$ | async as activityDetails">
      <button mat-icon-button type="button" (click)="showEventLog()" [matTooltip]="'activity.event_log.view.tooltip' | translate">
        <mat-icon>assignment</mat-icon>
      </button>
      <button
        *ngIf="
          activityDetails?.replace_activity_id === null &&
          !(isDoenkidsManagement$ | async) &&
          !(isCurrentOrganizationUnitIsOfTypeLocation$ | async) &&
          !(isCurrentOrganizationUnitIsOfTypeGroup$ | async)
        "
        mat-icon-button
        type="button"
        (click)="excludeActivity()"
        [matTooltip]="'activity.exclude.tooltip' | translate"
      >
        <mat-icon>remove_circle</mat-icon>
      </button>
      <button
        *ngIf="(isDoenkidsManagement$ | async) || (hasOUWritePermissions$ | async)"
        mat-icon-button
        type="button"
        (click)="deleteActivity()"
        [matTooltip]="'activity.delete.tooltip' | translate"
      >
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </app-doenkids-menu-bar>

  <app-split-page [leftLabel]="'activity.details' | translate" [rightLabel]="'activity.preview' | translate" (selectedTabChange)="tabChange($event)">
    <ng-template #left>
      <div class="details-container">
        <!-- Generic Activity Details (e.g. name, subtitle)-->
        <app-activity-controls
          #activityControls
          (initialized)="listenToForms($event)"
          (attachmentsUpdated)="renderAttachments()"
          [activityDetails$]="activityDetails$"
        ></app-activity-controls>
        <!-- Sections -->
        <app-panel-container
          *ngIf="activitySectionList$ | async as activitySections"
          formGroupName="sections"
          cdkDropList
          [cdkDropListData]="activitySections"
          (cdkDropListDropped)="dropSection($event)"
        >
          <ng-template let-sectionIndex="index">
            <ng-container *ngIf="activitySections[sectionIndex] as section">
              <mat-expansion-panel cdkDrag cdkDragLockAxis="y" class="section-panel" *ngIf="section?.type_id !== ESectionType.BASIC_DETAILS">
                <mat-expansion-panel-header [class.panel-with-description]="section?.type_id === ESectionType.MEDIA">
                  <mat-panel-title [class.panel-title-with-info]="sectionsWithInfoDialog.includes(section?.type_id)">
                    <app-drag-handle cdkDragHandle></app-drag-handle>
                    <span>
                      {{ 'activity.section.type.' + getSectionTypeName(section?.type_id) + '.title' | translate }}
                      <ng-container *ngIf="section?.type_id === ESectionType.PAGE_END">
                        ............................................................
                      </ng-container>
                    </span>
                    <ng-container *ngIf="sectionsWithInfoDialog.includes(section?.type_id)">
                      <mat-icon (click)="openInfoWindow($event, section?.type_id)">info</mat-icon>
                    </ng-container>
                  </mat-panel-title>
                  <mat-panel-description *ngIf="activityForm.get('sections').get(section?.id?.toString())?.value?.data as sectionForm">
                    <span *ngIf="section?.type_id !== ESectionType.MEDIA">{{ sectionForm.title }}</span>
                    <span *ngIf="section?.type_id === ESectionType.MEDIA">
                      {{ 'activity.section.type.media.info' | translate }}
                    </span>
                  </mat-panel-description>
                </mat-expansion-panel-header>

                <ng-template matExpansionPanelContent>
                  <!-- If this section is an image section, we'll need to be able to drag and drop images all throughout the section. -->
                  <app-asset-upload
                    *ngIf="section?.type_id === ESectionType.MEDIA"
                    [identifier]="section.id.toString()"
                    [disabled]="(hasOUWritePermissions$ | async) === false"
                  >
                    <!-- Declare a context variable uploadData, and pass it the "uploadData" from the app-asset-upload component -->
                    <ng-template let-uploadData="uploadData">
                      <!-- Then pass this uploadData to the section, which pass it to the image section. -->
                      <app-section
                        (archived)="removeSection(section)"
                        (saved)="saveSectionFromTemplate(section.id, $event)"
                        [section]="section"
                        [formControlName]="section.id"
                        [uploadData]="uploadData"
                      ></app-section>
                    </ng-template>
                  </app-asset-upload>

                  <app-section
                    *ngIf="section?.type_id !== ESectionType.MEDIA && section?.type_id !== ESectionType.PAGE_END"
                    (saved)="saveSectionFromTemplate(section.id, $event)"
                    (archived)="removeSection(section)"
                    [activitySectionCount]="activitySectionCount$ | async"
                    [activitySectionTypes]="optionalSectionTypes$ | async"
                    [section]="section"
                    [formControlName]="section.id"
                  >
                  </app-section>
                  <div class="button-container" *ngIf="section?.type_id === ESectionType.PAGE_END">
                    <button (click)="removeSection(section)" mat-button type="button">
                      {{ 'activity.section.remove.page_end' | translate }}
                    </button>
                  </div>
                </ng-template>
              </mat-expansion-panel>
            </ng-container>
          </ng-template>
        </app-panel-container>
      </div>
    </ng-template>
    <ng-template #right>
      <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" [(selectedIndex)]="selectedPdfTabIndex" (selectedIndexChange)="renderPdfTab($event)">
        <mat-tab [label]="'activity.label' | translate">
          <div class="pdf-container">
            <div class="spinner-container" *ngIf="activityLoading">
              <mat-spinner></mat-spinner>
            </div>
            <ng2-pdfjs-viewer
              (onDocumentLoad)="onPdfLoadedEvent(activityViewer, 'activity')"
              [viewerId]="(activityId$ | async) + ''"
              [attr.visible]="!activityLoading"
              #activityViewer
            ></ng2-pdfjs-viewer>
          </div>
        </mat-tab>
        <mat-tab [label]="'generic.attachments' | translate">
          <div class="pdf-container" *noContent="!(controls?.activityAttachments$ | async)?.length">
            <div class="spinner-container" *ngIf="attachmentsLoading">
              <mat-spinner></mat-spinner>
            </div>
            <ng2-pdfjs-viewer
              (onDocumentLoad)="onPdfLoadedEvent(attachmentViewer, 'attachment')"
              [viewerId]="(activityId$ | async) + '-attachments'"
              [attr.visible]="!attachmentsLoading"
              #attachmentViewer
            ></ng2-pdfjs-viewer>
          </div>
        </mat-tab>
      </mat-tab-group>
    </ng-template>
  </app-split-page>
</form>
