import { IActivityReviews, IActivity, IAgeGroup, IActivityReview, IActivityReviewScore, IActivityReviewScoreType } from 'typings/doenkids/doenkids.d';
import { IAgeGroupListResponse } from 'typings/api-activity';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormControl, UntypedFormBuilder, Validators, ValidationErrors, UntypedFormGroup } from '@angular/forms';
import { Component, OnInit, ViewEncapsulation, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ActivityService } from 'src/api/activity/activity/activity.service';
import { BehaviorSubject, Observable, Subject, firstValueFrom } from 'rxjs';
import { KeyValue, Location as ALocation } from '@angular/common';
import { isNil, meanBy } from 'lodash';
import { ActivityReviewScoreTypeService } from 'src/api/generic/activity-review-score-type/activity-review-score-type.service';
import { map, takeUntil } from 'rxjs/operators';
import { ActivityScoreTypeQuery } from 'src/api/generic/activity-review-score-type/activity-review-score-type.query';
import { ActivityReviewService } from 'src/api/activity/activity-review/activity-review.service';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { I18nToastProvider } from 'src/providers/i18n-toast.provider';
import { TranslateService } from 'src/app/utils/translate.service';

_('review.job_title.pedagogical_employee');
_('review.job_title.pedagogical_coach');
_('review.job_title.manager');
_('review.job_title.sports_employee');
_('review.job_title.other');

const CharLengthValidator = (maxChars: number) => (control: UntypedFormControl) => {
  if (control.value.length > maxChars) {
    return { tooManyCharacters: true };
  }
  return null;
};

export const REVIEW_MAX_CHARS = 512;

export const REVIEW_MAX_JOB_TITLE_CHARS = 25;

enum EJobTitle {
  PEDAGOGICAL_EMPLOYEE = 'pedagogical_employee',
  PEDAGOGICAL_COACH = 'pedagogical_coach',
  MANAGER = 'manager',
  SPORTS_EMPLOYEE = 'sports_employee',
  OTHER = 'other',
}

@Component({
  selector: 'app-review-input',
  templateUrl: './review-input.component.html',
  styleUrls: ['./review-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReviewInputComponent implements OnInit, OnDestroy {
  public ratingInputs = [];

  public ageGroups$ = new BehaviorSubject<IAgeGroup[]>([]);

  public reviewForm: UntypedFormGroup;

  public item$ = new BehaviorSubject<IActivity>(null);

  public activityLoading$ = new BehaviorSubject(false);

  public scoreTypes$: Observable<IActivityReviewScoreType[]>;

  public stop$ = new Subject();

  public done$ = new BehaviorSubject(false);

  public editing = false;

  public currentReview$ = new BehaviorSubject(undefined);

  public currentRating: number;

  public maxChars = REVIEW_MAX_CHARS;

  public maxJobtitleChars = REVIEW_MAX_JOB_TITLE_CHARS;

  readonly EJobTitle = EJobTitle;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  originalOrder = (_a: KeyValue<string, string>, _b: KeyValue<string, string>): number => 0;

  constructor(
    private $activity: ActivityService,
    private route: ActivatedRoute,
    private $scoreType: ActivityReviewScoreTypeService,
    private scoreTypeQuery: ActivityScoreTypeQuery,
    private $review: ActivityReviewService,
    private session: DoenkidsSessionProvider,
    private fb: UntypedFormBuilder,
    private location: ALocation,
    private changeDetector: ChangeDetectorRef,
    private router: Router,
    private $translateService: TranslateService,
    private $i18nToastProvider: I18nToastProvider,
  ) {
    this.reviewForm = this.fb.group(
      {
        reviewContent: [
          '',
          {
            validators: [Validators.required, CharLengthValidator(REVIEW_MAX_CHARS)],
          },
        ],
        reviewTips: [
          '',
          {
            validators: [CharLengthValidator(REVIEW_MAX_CHARS)],
          },
        ],
        position: [null, { validators: [Validators.required] }],
        positionElaboration: ['', {}],
        ageGroup: [
          '',
          {
            validators: [
              (control: UntypedFormControl): ValidationErrors | null => {
                // Only validate this when the activity has age groups
                if (this.ageGroups$.value.length > 0) {
                  if (isNil(control.value)) {
                    return { noAgeGroupSelected: true };
                  }
                }
                return null;
              },
            ],
          },
        ],
      },
      // We need to also validate the form based on the rating inputs - even though they aren't form controls
      {
        validators: [
          () => {
            const valid = this.ratingInputs?.every((input) => input.value >= 20 && input.value <= 100);
            if (!valid) {
              return { invalidRatingValues: true };
            }

            if (this.reviewForm?.value?.position === EJobTitle.OTHER) {
              if (this.reviewForm?.value?.positionElaboration.length === 0) {
                return { noPositionElaboration: true };
              }
            }
            return null;
          },
        ],
      },
    );

    this.scoreTypes$ = this.scoreTypeQuery.selectAll();
  }

  async ngOnInit() {
    const { id } = this.route.snapshot.params;
    const { reviewId } = this.route.snapshot.queryParams;

    if (!id) {
      console.warn('No valid ID found in URL');
      return;
    }

    this.fetchActivity(id);
    this.fetchAgeGroups(id);
    this.$scoreType.fetchAll();

    if (reviewId) {
      const review = await this.fetchReview(reviewId);
      const dutchTranslations = await this.$translateService.getTranslation('nl-NL');
      const position = [EJobTitle.PEDAGOGICAL_EMPLOYEE, EJobTitle.PEDAGOGICAL_COACH, EJobTitle.SPORTS_EMPLOYEE, EJobTitle.MANAGER].find(
        (jobTitle) => dutchTranslations[`review.job_title.${jobTitle}`] === review.job_title,
      );

      this.reviewForm.patchValue({
        reviewContent: review.review,
        position: position || EJobTitle.OTHER,
        positionElaboration: position === EJobTitle.OTHER ? review.job_title : '',
        ageGroup: review.age_group_id,
        reviewTips: review.recommendation,
      });

      this.ratingInputs = review.review_scores.map((score) => ({
        value: score.score,
        activity_review_score_id: score.type_id,
        name: score.type,
        id: score.score_id,
      }));

      this.editing = true;
    }

    this.scoreTypeQuery
      .selectAll()
      .pipe(takeUntil(this.stop$))
      .subscribe((scoreTypes) => {
        if (!this.ratingInputs.length) {
          this.ratingInputs = scoreTypes.map((type) => ({
            value: 0,
            activity_review_score_id: type.id,
            name: type.name,
            id: null,
          }));
        }
      });
  }

  async fetchActivity(id: number) {
    this.activityLoading$.next(true);

    const activity = await this.$activity.fetch(id);

    if (activity) {
      this.item$.next(activity.data);
    }
    this.activityLoading$.next(false);
  }

  async fetchAgeGroups(id: number) {
    const ageGroups = (await firstValueFrom(this.$activity.ageGroup(id))) as IAgeGroupListResponse;

    if (ageGroups?.items) {
      this.ageGroups$.next(ageGroups.items);
    }
  }

  async fetchReview(id: number) {
    const review = (await this.$review.fetch(id)) as IActivityReviews;
    this.currentReview$.next(review);
    return review;
  }

  calculateTotal() {
    this.currentRating = meanBy(this.ratingInputs, 'value');
    this.reviewForm.updateValueAndValidity();
    this.reviewForm.markAsDirty();
    this.changeDetector.detectChanges();
  }

  async submit() {
    const organizationUnitId = await firstValueFrom(this.session.currentOuId$);
    if (!organizationUnitId) {
      this.$i18nToastProvider.error(_('review_input.organization.invalid'));

      return;
    }

    const { reviewContent, position, positionElaboration, ageGroup, reviewTips } = this.reviewForm.value;

    let jobTitle: string;
    if (position === EJobTitle.OTHER) {
      jobTitle = positionElaboration;
    } else {
      const jobTitleTranslationKey = `review.job_title.${position}`;
      // Keep storing the job title as the dutch translation so it can be consistently identified in ngOnInit for existing and new reviews
      jobTitle =
        this.$translateService.currentLang === 'nl-NL'
          ? this.$translateService.instant(jobTitleTranslationKey)
          : await firstValueFrom(this.$translateService.getTranslation('nl-NL').pipe(map((translations) => translations[jobTitleTranslationKey])));
    }

    if (!jobTitle?.length) {
      this.$i18nToastProvider.error(_('review_input.job_title.invalid'));

      return;
    }

    // Cap jobtitle length
    if (jobTitle.length > REVIEW_MAX_JOB_TITLE_CHARS) {
      jobTitle = jobTitle.slice(0, REVIEW_MAX_JOB_TITLE_CHARS);
    }

    const review: IActivityReview = {
      organization_unit_id: organizationUnitId,
      activity_id: this.item$.value.id,
      review: reviewContent,
      recommendation: reviewTips,
      job_title: jobTitle,
      age_group_id: ageGroup || null,
    };

    const scores: Partial<IActivityReviewScore>[] = this.ratingInputs.map(({ value, activity_review_score_id, id }) => ({
      value,
      activity_review_score_id,
      id,
    }));

    let result;

    if (this.editing) {
      result = await this.$review.update(this.currentReview$.value.id, organizationUnitId, review, scores);
    } else {
      result = await this.$review.create(review, scores);
    }
    if (result) {
      this.done$.next(true);
      await this.fetchReview(this.editing ? this.currentReview$.value.id : result.id);
    }
  }

  functionChange() {
    if (this.reviewForm?.value?.position.value === EJobTitle.OTHER) {
      this.reviewForm.get('positionElaboration').setValue('');
    }
  }

  ngOnDestroy(): void {
    this.stop$.next(undefined);
  }

  goBack() {
    if (this.router.navigated) {
      this.location.back();
    } else {
      this.router.navigate(['/']);
    }
  }
}
