/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-prototype-builtins */
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Location as ALocation } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { ActivatedRoute, Router } from '@angular/router';
import { cloneDeep, get, includes, isArray, isEqual, isNil, isObject, omit, snakeCase, toArray, isEmpty, uniq } from 'lodash';
import * as dayjs from 'dayjs';
import { PdfJsViewerComponent } from 'ng2-pdfjs-viewer';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, skip, startWith, takeUntil, tap } from 'rxjs/operators';
import { fadeInOut } from 'src/animations';
import { PeriodSectionService } from 'src/api/activity/period-section/period-section.service';
import { ProgramOrganizationUnitQuery } from 'src/api/activity/program-organization-unit/program-organization-unit.query';
import { ProgramOrganizationUnitService } from 'src/api/activity/program-organization-unit/program-organization-unit.service';
import { ProgramPeriodListService } from 'src/api/activity/program-period-list/program-period-list.service';
import { ProgramPeriodSectionService } from 'src/api/activity/program-period-section/program-section.service';
import { ProgramPeriodService } from 'src/api/activity/program-period/program-period.service';
import { ProgramSectionTypeService } from 'src/api/activity/program-section-type/program-section-type.service';
import { ProgramStatusQuery } from 'src/api/activity/program-status/program-status.query';
import { ProgramStatusService } from 'src/api/activity/program-status/program-status.service';
import { ProgramTemplateService } from 'src/api/activity/program-template/program-template.service';
import { ProgramQuery } from 'src/api/activity/program/program.query';
import { IExtendedProgramSummary, ProgramService } from 'src/api/activity/program/program.service';
import { TagListQuery } from 'src/api/activity/tag-list/tag-list.query';
import { TagListService } from 'src/api/activity/tag-list/tag-list.service';
import { OrganizationUnitSymbolListQuery } from 'src/api/customer/organization-unit-symbol-list/organization-unit-symbol-list.query';
import { OrganizationUnitSymbolListService } from 'src/api/customer/organization-unit-symbol-list/organization-unit-symbol-list.service';
import { OrganizationUnitTreeService } from 'src/api/customer/organization-unit-tree/organization-unit-tree.service';
import { ProgramAttachmentService } from 'src/api/media/program-attachment/program-attachment.service';
import { PublishProgramService } from 'src/api/publish/program/program.service';
import {
  BrowseOrganizationUnitDialogComponent,
  ISelectedOrganizationUnit,
} from 'src/components/dialogs/browse-organization-unit-dialog/browse-organization-unit-dialog.component';
import { ConfirmationDialogComponent } from 'src/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import {
  CopyProgramDialogComponent,
  ICopyProgramDialogData,
  ICopyProgramDialogResult,
  IPossibleCopyProgramLocation,
} from 'src/components/dialogs/copy-program-dialog/copy-program-dialog.component';
import {
  CreateProgramTemplateDialogComponent,
  ICreateProgramTemplate,
} from 'src/components/dialogs/create-program-template-dialog/create-program-template-dialog.component';
import { DownloadProgramPdfDialogComponent } from 'src/components/dialogs/download-program-pdf-dialog/download-program-pdf-dialog.component';
import { ProgramSymbolsDialogComponent } from 'src/components/dialogs/program-symbols-dialog/program-symbols-dialog.component';
import { TemplateHtmlDialogComponent } from 'src/components/dialogs/template-html-dialog/template-html-dialog.component';
import { ProgramPeriodSectionsComponent } from 'src/components/pages/program-details/program-period-sections/program-period-sections.component';
import { IUploadResponse } from 'typings/custom-app-types';
import { DoenkidsAssetProvider } from 'src/providers/assets.provider';
import { BreakpointsProvider } from 'src/providers/breakpoints.provider';
import { DoenkidsTemplateProvider } from 'src/providers/doenkids-template.provider';
import { DownloadProvider } from 'src/providers/download.provider';
import { FormHelperProvider } from 'src/providers/form-helper.provider';
import { PermissionProvider } from 'src/providers/permission.provider';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { IOrganizationUnitProgramAssignment, IOrganizationUnitProgramRoles, IProgramSummaryPeriod, IProgramSummaryPeriodSection } from 'typings/api-activity';
import { IOrganizationUnitTreeNode } from 'typings/api-customer';
import {
  IOrganizationUnitOverview,
  IOrganizationUnitTagAll,
  IPeriod,
  IPeriodSection,
  IProgram,
  IProgramStatus,
  IProgramSummary,
  IProgramTemplateAttachmentMedia,
} from 'typings/doenkids/doenkids';
import { IMediaItem } from 'typings/section-types';
import { ProgramProvider } from 'src/providers/program.provider';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { I18nTitleStrategy } from 'src//app/utils/i18n-title-strategy';
import { I18nToastProvider } from 'src//providers/i18n-toast.provider';
import { TranslateService } from 'src/app/utils/translate.service';
import { getRootUrlAndQueryParamsFromUrl } from 'src/helpers/url.helper';
import { ActivityService } from 'src/api/activity/activity/activity.service';
import { IEntityWithAccessLevel } from 'typings/api-generic';
import { DoenkidsStaticValuesHelper } from 'src/components/shared/static-values/doenkids-static-values-helper';
import { ShowErrorsImmediatelyErrorMatcher } from 'src/components/shared/form-validators/error-state-matchers';
import { EProgramPeriodSectionType } from 'src/components/shared/section/section.component';

_('program.status.concept');
_('program.status.review');
_('program.status.published');
_('program.status.unpublished');
_('program.status.template');

const MAX_DEFAULT = 50;
const DOENKIDS_DEFAULT_TEMPLATE_ID = 2;
const DAY_PROGRAM_TYPE = 'DATE';
const FREEFORM_PROGRAM_TYPE = 'FREEFORM';

interface IPeriodSectionOverview {
  [periodId: number]: IProgramSummaryPeriodSection[];
}

export enum EProgramStatus {
  CONCEPT = 1,
  REVIEW = 2,
  PUBLISHED = 3,
  UNPUBLISHED = 4,
  TEMPLATE = 5,
}

export function getProgramStatusName(programStatusId: EProgramStatus): string {
  switch (programStatusId) {
    case EProgramStatus.CONCEPT:
      return 'concept';
    case EProgramStatus.REVIEW:
      return 'review';
    case EProgramStatus.PUBLISHED:
      return 'published';
    case EProgramStatus.UNPUBLISHED:
      return 'unpublished';
    case EProgramStatus.TEMPLATE:
      return 'template';
    default:
      return 'unknown';
  }
}

@Component({
  selector: 'app-program-details',
  templateUrl: './program-details.component.html',
  styleUrls: ['./program-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [fadeInOut],
})
export class ProgramDetailsComponent implements OnInit, OnDestroy {
  public programForm: UntypedFormGroup;

  private destroy$ = new Subject<void>();

  public periodForm: UntypedFormGroup;

  public templateImagesForm: UntypedFormGroup;

  public programId: number;

  private organizationUnitId: number;

  public savingProgramPleaseWait$ = new BehaviorSubject(false);

  activeTab = 0;

  public program$ = new BehaviorSubject<IEntityWithAccessLevel<IExtendedProgramSummary>>(null);

  public programPeriods$ = new BehaviorSubject<IProgramSummaryPeriod[]>([]);

  public selectedPeriodId$ = new BehaviorSubject<number>(null);

  public availableLocations$: BehaviorSubject<IOrganizationUnitTreeNode[]> = new BehaviorSubject([]);

  public availableGroups$: BehaviorSubject<IOrganizationUnitTreeNode[]> = new BehaviorSubject([]);

  public programStatusCtrl: UntypedFormControl = new UntypedFormControl('', []);

  isAdmin$: Observable<boolean>;

  isCustomer$: Observable<boolean>;

  isLocation$: Observable<boolean>;

  thereAreCustomerSymbolsToUse$: Observable<boolean>;

  @ViewChild('groupListInput') groupListInput: ElementRef<HTMLInputElement>;

  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  @ViewChild(ProgramPeriodSectionsComponent) periodSections: ProgramPeriodSectionsComponent;

  @ViewChildren(MatExpansionPanel) expansionPanels: QueryList<MatExpansionPanel>;

  public isSmall$: Observable<boolean>;

  public isMobile$: Observable<boolean>;

  public isDoenkidsBeheer$: Observable<boolean>;

  public isReader$: Observable<boolean>;

  public hasParentOUWritePermission$: Observable<boolean>;

  public hasProgramTemplateCreatePermission$: Observable<boolean>;

  public programStatuses$: Observable<IProgramStatus[]>;

  public showProgramTemplateCreate$: Observable<boolean>;

  public showableProgramStatusOptions$: Observable<IProgramStatus[]>;

  private pdfRenderTrigger$ = new Subject<void>();

  /**
   * Subject to render PDF updates
   */
  private pdf$: Subject<string | Blob> = new Subject();

  public pdfLoading$ = new BehaviorSubject(false);

  /** The last time a PDF Render was requested */
  private lastPdfRender: number;

  public hasWriteAccess$: Observable<boolean>;

  @ViewChild('pdfViewer') pdfViewer: PdfJsViewerComponent;

  private programLoading$: Observable<boolean>;

  private programOURoles$: BehaviorSubject<IOrganizationUnitProgramRoles[]> = new BehaviorSubject<IOrganizationUnitProgramRoles[]>([]);

  public programOwner$: Observable<IOrganizationUnitProgramRoles> = this.programOURoles$.pipe(
    takeUntil(this.destroy$),
    filter((value) => !isNil(value)),
    map((roles) => {
      const owners = roles.filter((role) => role.organization_unit_role_id === DoenkidsStaticValuesHelper.PROGRAM_OWNER_ROLE);
      return owners[0];
    }),
  );

  /** Will emit once ngOnInit is done.
   */
  private componentsLoading$ = new BehaviorSubject(true);

  public programsDownloading$: Observable<boolean>;

  private programCopying$ = new BehaviorSubject(false);

  public isTheOwner$ = new BehaviorSubject<boolean>(false);

  public isReadOnly$ = new BehaviorSubject<boolean>(true);

  public pageLoading$: Observable<boolean>;

  public hasProgramExplanationPermission$: Observable<boolean>;

  public programExplanations$: BehaviorSubject<IProgramTemplateAttachmentMedia[]> = new BehaviorSubject<IProgramTemplateAttachmentMedia[]>([]);

  public programAttachments$: BehaviorSubject<IProgramTemplateAttachmentMedia[]> = new BehaviorSubject<IProgramTemplateAttachmentMedia[]>([]);

  public templateImagesCount$: Observable<number>;

  public templateImages$: Observable<string[]>;

  public backUrl = '/calendar';

  public allTags$: Observable<IOrganizationUnitTagAll[]>;

  public parentTags$: BehaviorSubject<IOrganizationUnitTagAll[]> = new BehaviorSubject<IOrganizationUnitTagAll[]>([]);

  public activityPeriodSections$: BehaviorSubject<IPeriodSectionOverview> = new BehaviorSubject<IPeriodSectionOverview>({});

  public rightSideView$: BehaviorSubject<'pdf' | 'periodSections'> = new BehaviorSubject<'pdf' | 'periodSections'>('pdf');

  public possibleContentLanguages$: Observable<string[]>;

  public contentLanguageControl = new FormControl('');

  readonly EProgramStatus = EProgramStatus;

  private originIsInsights: boolean = false;

  private relatedPrograms$ = new BehaviorSubject<IProgram[]>([]);

  public errorStateMatcher = new ShowErrorsImmediatelyErrorMatcher();

  public excludedActivityIds$ = new BehaviorSubject<number[]>([]);

  constructor(
    private $breakpoints: BreakpointsProvider,
    private $doenkidsTemplateProvider: DoenkidsTemplateProvider,
    private $download: DownloadProvider,
    private $formHelper: FormHelperProvider,
    private $permission: PermissionProvider,
    private $session: DoenkidsSessionProvider,
    private programStatusQuery: ProgramStatusQuery,
    private programStatusService: ProgramStatusService,
    private customerSymbolsQuery: OrganizationUnitSymbolListQuery,
    private customerSymbolsService: OrganizationUnitSymbolListService,
    private dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private location: ALocation,
    private organizationUnitTreeService: OrganizationUnitTreeService,
    private periodSectionService: PeriodSectionService,
    private periodSectionTypeService: ProgramSectionTypeService,
    private programOrganizationUnitQuery: ProgramOrganizationUnitQuery,
    private programOrganizationUnitService: ProgramOrganizationUnitService,
    private programPeriodListService: ProgramPeriodListService,
    private programQuery: ProgramQuery,
    private programService: ProgramService,
    private programTemplateService: ProgramTemplateService,
    private publishService: PublishProgramService,
    private route: ActivatedRoute,
    private router: Router,
    public programPeriodService: ProgramPeriodService,
    public programSectionService: ProgramPeriodSectionService,
    private tagListQuery: TagListQuery,
    private tagListService: TagListService,
    private $programAttachment: ProgramAttachmentService,
    private $asset: DoenkidsAssetProvider,
    private $programProvider: ProgramProvider,
    private $translateService: TranslateService,
    private $i18nTitleStrategy: I18nTitleStrategy,
    private $i18nToastProvider: I18nToastProvider,
    private activityService: ActivityService,
    private programPeriodSectionService: ProgramPeriodSectionService,
    private cd: ChangeDetectorRef,
  ) {
    this.isAdmin$ = this.$session.isAdmin$.pipe(takeUntil(this.destroy$));
    this.isReader$ = this.$session.isReader$.pipe(takeUntil(this.destroy$));
    this.isCustomer$ = this.$session.isCurrentOrganizationUnitIsOfTypeCustomer$.pipe(takeUntil(this.destroy$));
    this.isLocation$ = this.$session.isCurrentOrganizationUnitIsOfTypeLocation$.pipe(takeUntil(this.destroy$));
    this.thereAreCustomerSymbolsToUse$ = this.customerSymbolsQuery.selectAll().pipe(
      takeUntil(this.destroy$),
      filter((value) => !isNil(value)),
      map((symbols) => symbols.length > 0),
    );
    this.isSmall$ = this.$breakpoints.isSmall$.pipe(takeUntil(this.destroy$));
    this.isMobile$ = this.$breakpoints.isMobile$.pipe(takeUntil(this.destroy$));
    this.isDoenkidsBeheer$ = this.$permission.isDoenkidsManagement$.pipe(takeUntil(this.destroy$));
    this.hasParentOUWritePermission$ = this.$permission.hasParentOUWritePermissions$.pipe(takeUntil(this.destroy$));
    this.hasProgramTemplateCreatePermission$ = this.$permission.hasProgramTemplateCreatePermissionInOrganizationTree$.pipe(takeUntil(this.destroy$));
    this.programStatuses$ = this.programStatusQuery.selectAll().pipe(takeUntil(this.destroy$));
    this.hasWriteAccess$ = this.program$.pipe(
      takeUntil(this.destroy$),
      map((program) => program?.access_level === 'WRITE'),
      tap((hasWriteAccess) => {
        if (hasWriteAccess && this.programStatusCtrl.disabled) {
          this.programStatusCtrl.enable();
        } else if (!hasWriteAccess && this.programStatusCtrl.enabled) {
          this.programStatusCtrl.disable();
        }
      }),
    );
    this.programLoading$ = this.programQuery.selectLoading().pipe(takeUntil(this.destroy$));
    this.programsDownloading$ = this.$download.programsDownloading$.pipe(takeUntil(this.destroy$));
    this.hasProgramExplanationPermission$ = this.$permission.hasProgramExplanationPermission$.pipe(takeUntil(this.destroy$));
    this.allTags$ = this.tagListQuery.selectAll().pipe(takeUntil(this.destroy$));

    this.showProgramTemplateCreate$ = combineLatest([this.isDoenkidsBeheer$, this.hasProgramTemplateCreatePermission$]).pipe(
      takeUntil(this.destroy$),
      map(([isDoenkidsBeheer, hasProgramTemplateCreatePermission]) => isDoenkidsBeheer || hasProgramTemplateCreatePermission),
    );

    this.showableProgramStatusOptions$ = combineLatest([this.programStatuses$, this.showProgramTemplateCreate$]).pipe(
      takeUntil(this.destroy$),
      map(([programStatuses, showProgramTemplateCreate]) =>
        programStatuses
          .filter((programStatus) => {
            if (programStatus.name.toUpperCase() === 'TEMPLATE') {
              return showProgramTemplateCreate;
            }
            if (programStatus.name.toUpperCase() === 'UNPUBLISHED') {
              return false;
            }
            return true;
          })
          .map((programStatus) => {
            const programStatusName = this.getProgramStatusName(programStatus.id);

            if (programStatusName === 'unknown') {
              return programStatus;
            }

            const clonedStatus = cloneDeep(programStatus);

            clonedStatus.name = this.$translateService.instant(`program.status.${programStatusName}`);

            return clonedStatus;
          }),
      ),
    );

    this.pageLoading$ = combineLatest([
      this.programLoading$,
      this.programCopying$,
      this.componentsLoading$.asObservable(),
    ]).pipe(
      takeUntil(this.destroy$),
      map(([program, programCopying, componentsLoading]) => program || programCopying || componentsLoading),
    );

    this.templateImages$ = combineLatest([this.$doenkidsTemplateProvider.programTemplateImages$, this.program$]).pipe(
      takeUntil(this.destroy$),
      map(([templateImages, program]) => program?.data?.template_options?.bannerImage ?? templateImages),
    );
    this.templateImagesCount$ = this.$doenkidsTemplateProvider.programTemplateImagesCount$.pipe(takeUntil(this.destroy$));

    this.programForm = fb.group({
      name: ['', [Validators.required]],
      content: [''],
      from: [''],
      to: [''],
      customer_location_id: [{
        value: null,
        disabled: true,
      }],
      customer_groups: [[]],
    });

    this.periodForm = fb.group({});
    this.templateImagesForm = fb.group({});

    this.program$.pipe(
      takeUntil(this.destroy$),
      filter((program) => !isNil(program)),
    ).subscribe(async (program) => {
      const programData = program.data;
      this.$i18nTitleStrategy.updateTitleParameters({ program: programData.name });

      await this.setUpProgramDetails(programData);

      this.programPeriods$.next(programData.period ?? []);

      if (this.originIsInsights) {
        this.backUrl = undefined;
      } else if (programData.from && programData.from !== '') {
        this.backUrl = `/calendar?date=${dayjs(programData.from).format('YYYY-MM-DD')}&language=${programData.language}`;
      } else if (programData.to && programData.to !== '') {
        this.backUrl = `/calendar?date=${dayjs(programData.to).format('YYYY-MM-DD')}&language=${programData.language}`;
      }

      this.programStatusCtrl.setValue(program.data.program_status_id);
      this.contentLanguageControl.setValue(programData.language ?? '');
      this.relatedPrograms$.next((await firstValueFrom(this.programService.relatedPrograms(program.data.id)))?.items ?? []);
    });

    this.programPeriods$.pipe(
      takeUntil(this.destroy$),
    ).subscribe((periods) => {
      this.setUpPeriods(periods);
    });

    this.possibleContentLanguages$ = this.$session.ouLanguages$.pipe(takeUntil(this.destroy$));

    this.contentLanguageControl.valueChanges.pipe(
      takeUntil(this.destroy$),
    ).subscribe(async (newLanguage) => {
      const program = (await firstValueFrom(this.program$))?.data;
      const currentLanguage = program?.language;

      if (currentLanguage.toLowerCase() !== newLanguage.toLowerCase()) {
        const programWithNewLanguage = this.relatedPrograms$.value.find((relatedProgram) => relatedProgram.language.toLowerCase() === newLanguage.toLowerCase()
          && relatedProgram.country_code.toLowerCase() === program?.country_code.toLowerCase());

        if (programWithNewLanguage) {
          this.router.navigate([`/organization/${this.organizationUnitId}/program/${programWithNewLanguage.id}`], { replaceUrl: true });
        } else {
          const newProgram = await this.createLanguageCopyOfProgram(newLanguage);
          if (newProgram) {
            await this.router.navigate([`/organization/${this.organizationUnitId}/program/${newProgram.id}`], { replaceUrl: true });
          }
        }
      }
    });

    combineLatest([this.isTheOwner$, this.isReader$]).pipe(
      takeUntil(this.destroy$),
    ).subscribe(([isTheOwner, isReader]) => {
      this.isReadOnly$.next(isReader || !isTheOwner);

      if (isTheOwner && !isReader) {
        if (this.programForm.disabled) {
          this.programForm.enable();
        }
        if (this.periodForm.disabled) {
          this.periodForm.enable();
        }
      } else if (isReader && this.programForm.enabled) {
        this.programForm.disable();
      } else {
        if (this.programForm.enabled) {
          this.programForm.disable();
        }
        if (this.periodForm.enabled) {
          this.periodForm.disable();
        }
      }

    });
  }

  async ngOnInit() {
    this.pdfRenderTrigger$.pipe(takeUntil(this.destroy$), debounceTime(300)).subscribe(() => {
      this.renderPdf();
    });

    combineLatest([this.route.params.pipe(startWith(this.route.snapshot.params)), this.route.queryParams.pipe(startWith(this.route.snapshot.queryParams))]).pipe(
      takeUntil(this.destroy$),
      distinctUntilChanged(isEqual),
    ).subscribe(async ([params, queryParams]) => {
      const programId = parseInt(`${params.id}`, 10);
      this.organizationUnitId = parseInt(`${params.ou}`, 10);
      this.originIsInsights = queryParams.origin === 'insights';

      if (isNil(programId) || Number.isNaN(programId)) {
        this.$i18nToastProvider.error(_('program_details.invalid_program_id'));
        return;
      }

      if (programId !== this.programId) {
        this.programId = programId;
        const programSummary = await this.programService.fetchSummary(programId);

        if (isNil(programSummary)) {
          console.warn('Failed to fetch program');
          this.program$.next(null);
          return;
        }

        this.program$.next(programSummary);

        const periodId = +queryParams.periodId;
        const { periodSections } = queryParams;

        if (periodId) {
          this.selectedPeriodId$.next(periodId);
          this.openPanel(periodId, false);

          if (periodSections) {
            this.setPanelToPeriodSections();
            this.openTab(1);
          } else {
            if (this.rightSideView$.value !== 'pdf') {
              this.rightSideView$.next('pdf');
            }
            this.pdfRenderTrigger$.next();
          }
        } else {
          if (this.rightSideView$.value !== 'pdf') {
            this.rightSideView$.next('pdf');
          }
          this.pdfRenderTrigger$.next();
        }

        // Fetch program owner
        //
        this.fetchProgramOURoles(this.programId);

        this.fetchProgramAttachments();
      }
    });

    // Retrieve the program statuses
    //
    this.programStatusService.fetchAll(MAX_DEFAULT, 0);
    this.periodSectionTypeService.fetchAll(MAX_DEFAULT, 0);

    this.setUpSubscriptions();

    this.componentsLoading$.next(false);

    // Fetch descendant locations
    //
    combineLatest([this.$session.getOrganizationUnit$, this.$session.userPermissions$, this.$session.isAdmin$])
      .pipe(
        filter(([organization, ouPermissions]) => !isNil(organization) && !isNil(ouPermissions)),
        takeUntil(this.destroy$),
      )
      .subscribe(async ([organization, ouPermissions, isAdmin]) => {
        let locations = await this.organizationUnitTreeService.fetchLocationDescendants(organization.parent_organization_unit_id, true);
        const groups = await this.organizationUnitTreeService.fetchGroupDescendants(organization.id, true);
        this.programOrganizationUnitService.fetch(this.programId);
        this.tagListService.fetchAll(organization.id, 1000);

        // If you're not an admin we should only show the location where you have write permissions
        //
        if (!isAdmin) {
          const ouIdsWithWritePermissions = ouPermissions
            .filter((ouPermission) => ouPermission.permission === 'WRITE')
            .map((ouPermission) => ouPermission.organization_unit_id);
          locations = locations.filter((location) => includes(ouIdsWithWritePermissions, location.id));
        }

        this.availableLocations$.next(locations);
        this.availableGroups$.next(groups);
      });

    // What locations to select
    //
    combineLatest([this.availableLocations$, this.programOrganizationUnitQuery.selectAll()])
      .pipe(
        filter(([locations, roles]) => !isNil(locations) && !isNil(roles)),
        takeUntil(this.destroy$),
      )
      .subscribe(([locations, roles]) => {
        const results: number[] = [];

        if (locations.length && roles.length) {
          locations.forEach((group) => {
            const role = roles.find((organization) => organization.organization_unit_id === group.id);
            if (role) {
              if (
                role.organization_unit_role_id >= DoenkidsStaticValuesHelper.PROGRAM_READER_ROLE && // READER, EDITOR, OWNER
                role.organization_unit_role_id < DoenkidsStaticValuesHelper.PROGRAM_REVOKED_ROLE // REVOKED
              ) {
                results.push(role.organization_unit_id);
              }
            }
          });
        }
        // Assign the results to custom_group form control
        //
        this.programForm.get('customer_location_id').setValue(get(results, '0'));
      });

    // What OU's to select in the customer groups
    //
    combineLatest([this.availableGroups$, this.programOURoles$])
      .pipe(
        filter(([groups, roles]) => !isNil(groups) && !isNil(roles)),
        takeUntil(this.destroy$),
      )
      .subscribe(([groups, roles]) => {
        const results: number[] = [];

        if (groups.length && roles.length) {
          groups.forEach((group) => {
            const role = roles.find((organization) => organization.organization_unit_id === group.id);
            if (role && role.organization_unit_role_id !== DoenkidsStaticValuesHelper.PROGRAM_REVOKED_ROLE) {
              results.push(role.organization_unit_id);
            }
          });
        }
        // Assign the results to custom_group form control
        //
        this.programForm.get('customer_groups').setValue(results);
      });

    this.checkForMaxSymbols();
  }

  /** Destroy all subscriptions, leave no subscribers.
   */
  ngOnDestroy() {
    this.destroy$.next();
  }

  async fetchProgramAttachments() {
    const attachments = await this.$programAttachment.fetchAttachmentsWithMedia({ programId: this.programId });
    const explanationAttachments = [];
    const programAttachments = [];

    for (const attachment of attachments.items) {
      // eslint-disable-next-line no-await-in-loop
      const attachmentMedia = (await this.$asset.fetch(attachment.media_id))?.data;

      if (attachmentMedia.purpose === 'program-explanation') {
        explanationAttachments.push(attachment);
      }

      if (attachmentMedia.purpose === 'program-attachment') {
        programAttachments.push(attachment);
      }
    }

    this.programAttachments$.next(programAttachments);
    this.programExplanations$.next(explanationAttachments);
  }

  private setUpSubscriptions() {
    const fromCtrl = this.programForm.get('from');
    const toCtrl = this.programForm.get('to');

    // yeah the skip 1 is ugly but we need it to skip the initial value change which is the initial setting of the date
    //
    fromCtrl.valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged(isEqual), skip(1)).subscribe((dateString) => {
      let from = dayjs.tz(dateString);
      from = from.set('hours', 8).set('minutes', 30);
      fromCtrl.setValue(from.toDate().toISOString(), { onlySelf: true, emitEvent: false });

      this.updateDayProgramPeriodDays(fromCtrl.value, toCtrl.value);
    });

    // yeah the skip 1 is ugly but we need it to skip the initial value change which is the initial setting of the date
    //
    toCtrl.valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged(isEqual), skip(1)).subscribe((dateString) => {
      let to = dayjs.tz(dateString);
      to = to.set('hours', 18);
      toCtrl.setValue(to.toDate().toISOString(), { onlySelf: true, emitEvent: false });

      this.updateDayProgramPeriodDays(fromCtrl.value, toCtrl.value);
    });

    // Render the current PDF.
    //
    this.pdf$.pipe(takeUntil(this.destroy$), filter((value) => !isNil(value))).subscribe((pdf) => {
      if (this.pdfViewer) {
        if (typeof pdf === 'string') {
          this.pdfViewer.pdfSrc = encodeURIComponent(pdf);
        } else {
          this.pdfViewer.pdfSrc = pdf;
        }
        this.pdfViewer.refresh();
      }
      this.pdfLoading$.next(false);
    });

    // Reset form values.
    //
    combineLatest([this.templateImages$, this.$doenkidsTemplateProvider.programTemplateImagesCount$]).pipe(
      takeUntil(this.destroy$),
    ).subscribe(([templateImages, imageCount]) => {
      this.setupTemplateImagesControls(templateImages, imageCount);
    });

    // Re-render the PDF every time a new OU is selected.
    //
    this.$session.getOrganizationUnitSwitch$
      .pipe(
        takeUntil(this.destroy$),
        filter((ou) => !isNil(ou)),
        distinctUntilChanged(isEqual),
      )
      .subscribe((organizationUnit: IOrganizationUnitOverview) => {
        if (
          organizationUnit.id !== this.organizationUnitId &&
          (organizationUnit.organization_unit_type_id === DoenkidsStaticValuesHelper.ORGANIZATION_UNIT_TYPE_LOCATION ||
            organizationUnit.organization_unit_type_id === DoenkidsStaticValuesHelper.ORGANIZATION_UNIT_TYPE_GROUP)
        ) {
          this.router.navigate([`/organization/${organizationUnit.id}/program/${this.programId}`]);
        } else {
          const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: '400px',
            minWidth: '320px',
            data: {
              title: this.$translateService.instant(_('program_details.wrong_organizational_unit_dialog.title')),
              description: this.$translateService.instant(_('program_details.wrong_organizational_unit_dialog.description')),
            },
          });

          dialogRef.afterClosed().subscribe(() => {
            this.router.navigate(['/calendar']);
          });
        }
      });

    // When the owner changes, retrieve the symbols for that customer
    this.programOwner$.pipe(takeUntil(this.destroy$)).subscribe((owner) => {
      this._fetchCustomerSymbols(owner?.organization_unit_id);
    });
  }

  /**
   * Fetch the customer symbol to be used in the period activities
   */
  private async _fetchCustomerSymbols(ouId?: number) {
    if (!isNil(ouId)) {
      // Fetch all customer symbols.
      //
      return this.customerSymbolsService.fetchAll(ouId, 500, 0, 'display_order', 'desc');
    }
    // If no organization unit ID was passed, or no organization is selected right now, clear the store.
    //
    this.customerSymbolsService.reset();
    return null;
  }

  private async setUpProgramDetails(program: IProgramSummary) {
    // Patch the current location id value in the form.
    //
    this.programForm.patchValue({
      name: program.name,
      content: program.content,
      from: program.from,
      to: program.to,
    });

    const templateImages = await firstValueFrom(this.templateImages$);

    if (templateImages) {
      this.templateImagesForm.patchValue(templateImages);
    }
  }

  private async setUpPeriods(periods: IProgramSummaryPeriod[]) {
    // Create form controls for every period fetched.
    //
    for (const period of periods) {
      this.addPeriodControl(period);
      const activityPeriodSections: IProgramSummaryPeriodSection[] = [];
      for (const section of (period.section ?? [])) {
        if (section.type_id === EProgramPeriodSectionType.ACTIVITY) {
          activityPeriodSections.push(section);
        }
      }
      const currentPeriodSections = this.activityPeriodSections$.value;
      currentPeriodSections[period.id] = activityPeriodSections.sort((sectionA, sectionB) => sectionA.order - sectionB.order);
    }
  }

  async updatePeriodActivities() {
    this.programService.fetchSummary(this.programId).then((summary) => {
      this.program$.next(summary);
    });
  }


  // Max symbols handler
  //
  private async checkForMaxSymbols() {
    const maxSymbols = await firstValueFrom(this.$doenkidsTemplateProvider.programTemplateMaxSymbolCount$);

    const activities = await firstValueFrom(this.activityPeriodSections$);

    const activityPeriods = Object.values(activities);

    // Loop through the periods and then through the
    // activity to check for max symbols.
    // If there are too many symbols in an activity, trigger the
    // select symbols modal.
    //
    let triggerModal = false;

    if (maxSymbols) {
      activityPeriods.forEach((periodArray) => {
        periodArray.filter(async (activity) => {
          if (activity?.data?.customer_symbol?.length > maxSymbols) {
            triggerModal = true;
          }
        });
      });
    }

    if (triggerModal) {
      const dialogRef = this.dialog.open(ProgramSymbolsDialogComponent, {
        minWidth: '320px',
        minHeight: '400px',
        panelClass: 'program-symbols-dialog-panel',
        data: {
          programId: this.programId,
          periods: this.programPeriods$.value,
          maxSymbols,
          title: this.$translateService.instant(_('program.symbols.dialog.title'), { maxSymbols }),
          subtitle: this.$translateService.instant(_('program.symbols.dialog.subtitle'), { maxSymbols }),
        },
      });

      const result = await firstValueFrom(dialogRef.afterClosed());
      // Rerender the pdf.
      //
      if (result === 'confirm') {
        this.pdfRenderTrigger$.next();
      }
    }
  }

  private setupTemplateImagesControls(templateImages: string[], imageCount: number) {
    this.$formHelper.removeControls(this.templateImagesForm);

    for (let i = 0; i < imageCount; i++) {
      this.templateImagesForm.addControl(i.toString(), this.fb.control(templateImages[i]));
    }
  }

  /**
   * Add a period formcontrol.
   * @param period The period to add controls for, for the initial form data.
   */
  public addPeriodControl(period: IProgramSummaryPeriod) {
    const periodControl = this.fb.group(period);
    this.periodForm.addControl(period.id.toString(), periodControl);
  }

  /**
   * Change publication status
   */
  async changePublicationStatus(status: IProgramStatus) {
    const currentStatusId = (await firstValueFrom(this.program$)).data.program_status_id;
    const dialogResult = await this.promptSetPublishStatus(status.id);

    if (dialogResult === 'confirm') {
      const programDetails = await firstValueFrom(this.program$);
      const programStatuses = await firstValueFrom(this.programStatuses$);
      let newStatusId = status.id;
      const newStatus = programStatuses.find((programStatus) => programStatus.id === status.id);

      if (newStatus?.name?.toUpperCase() === 'UNPUBLISHED') {
        const conceptStatus = programStatuses.find((programStatus) => programStatus.name === 'CONCEPT');
        if (!isNil(conceptStatus)) {
          newStatusId = conceptStatus.id as number;
        }
      }
      const program: IProgram = {
        id: programDetails.data.id,
        name: programDetails.data.name as string,
        from: programDetails.data.from as string,
        to: programDetails.data.to as string,
        program_status_id: newStatusId,
      };

      await this.programService.update(program);

      if (newStatus.id === EProgramStatus.PUBLISHED) {
        await this.updateProgramGroups(false);
      }

      const currentOuId = await firstValueFrom(this.$session.currentOuId$);
      this.$programProvider.fetchUnpublishedProgramCount(currentOuId);
    } else {
      this.programStatusCtrl.setValue(currentStatusId);
    }
  }

  /** Generates a dialog that prompts the user to change their publish status, and informs them of what will change.
   * @private
   * @param {number} statusId The status ID that was selected.
   */
  private async promptSetPublishStatus(statusId: number) {
    const availableGroups = (await firstValueFrom(this.availableGroups$));
    const availableGroupsLength = availableGroups.length;
    const chosenGroups: number[] = this.programForm.get('customer_groups').value;
    const chosenGroupsLength = chosenGroups?.length ?? 0;
    const shouldHaveChosenGroups = availableGroupsLength > 0 && chosenGroupsLength === 0;

    let description: string | string[];
    let descriptionParams: any;
    switch (statusId) {
      case EProgramStatus.CONCEPT:
        description = _('program_details.status.change.to_concept');
        break;
      case EProgramStatus.REVIEW:
        description = _('program_details.status.change.to_review');
        break;
      case EProgramStatus.PUBLISHED:
        description = [_('program_details.status.change.to_published')];
        if (shouldHaveChosenGroups && isArray(description)) {
          description.unshift(_('program_details.status.change.to_published_no_groups'));
        } else if (chosenGroupsLength > 0) {
          description.unshift(_('program_details.status.change.to_published_with_groups'));
          const selectedGroups = availableGroups.filter((group) => chosenGroups.includes(group.id));
          descriptionParams = {
            'groups': selectedGroups.map((selectedGroup) => selectedGroup.name).map((groupName) => `<li>${groupName}</li>`),
          };
        }
        break;
      case EProgramStatus.UNPUBLISHED:
        description = _('program_details.status.change.to_unpublished');
        break;
      case EProgramStatus.TEMPLATE:
        description = _('program_details.status.change.to_template');
        break;
      default:
        break;
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      minWidth: '320px',
      data: {
        title: this.$translateService.instant(_('program_details.dialog.status_change.title')),
        description: this.$translateService.instant(description, descriptionParams),
      },
    });

    const dialogResult = await firstValueFrom(dialogRef.afterClosed());

    return dialogResult;
  }

  /**
   * Add a period to the current program, with corresponding formcontrol.
   */
  public addPeriod() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      minWidth: '320px',
      data: {
        title: this.$translateService.instant(_('generic.add')),
        description: this.$translateService.instant(_('program_details.field.add.dialog.description')),
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      const currentEndDate = this.programForm.get('to').value;
      if (result === 'confirm') {
        // Create a new period.
        //
        const period: IPeriod = {
          program_id: this.programId,
          name: '',
        };

        // Check the last period in this program
        //
        const activePeriodSections: IProgramSummaryPeriod[] = toArray(this.periodForm.value);
        if (isObject(activePeriodSections)) {
          // sort the periods in descending order on the order field (so the last period according to the order will end up as first in the array)
          // and grab the first entry of that
          //
          const lastPeriod = activePeriodSections.sort((a: IProgramSummaryPeriod, b: IProgramSummaryPeriod) => b.order - a.order)[0];

          // Check if the last one has a start date
          //
          if (lastPeriod && lastPeriod.period_type === DAY_PROGRAM_TYPE) {
            let nextDate = dayjs(lastPeriod.start_date).add(1, 'day');

            // skip weekend days
            //
            if (this.checkIfDayIsInWeekend(nextDate)) {
              while (this.checkIfDayIsInWeekend(nextDate)) {
                nextDate = nextDate.add(1, 'day');
              }
            }

            // if we got past the max date of the program set to the last periods start date
            // this way we always know that we are within program boundaries
            //
            if (nextDate.isAfter(dayjs(currentEndDate))) {
              nextDate = dayjs(lastPeriod.start_date);
            }

            const { start, end } = this.createDayProgramStartAndEndDate(nextDate.toISOString());
            period.start_date = start;
            period.end_date = end;
          }

          period.period_type = lastPeriod ? lastPeriod.period_type : FREEFORM_PROGRAM_TYPE;
        }

        await this.programPeriodService.create(period);

        this.updatePeriodActivities();

        // Render the pdf to display the new period.
        //
        this.pdfRenderTrigger$.next();
      }
    });
  }

  switchBetweenPDFandPeriodSections() {
    const currentView = this.rightSideView$.value;

    if (currentView === 'pdf') {
      this.setPanelToPeriodSections();
    } else if (currentView === 'periodSections') {
      this.setPanelToPdf();
    }
  }

  async detectAllCollapsed() {
    const periodPanels = this.expansionPanels.filter((expansionPanel) => !expansionPanel.accordion);

    const periodPanelsExpanded = periodPanels.map((periodPanel) => periodPanel.expanded);

    if (!periodPanelsExpanded.includes(true)) {
      this.selectedPeriodId$.next(null);
      if (this.rightSideView$.value !== 'pdf') {
        await this.setPanelToPdf();
      }
      const currentQueryParams = await firstValueFrom(this.route.queryParams);
      const newQueryParams = omit(currentQueryParams, 'periodId');
      this.router.navigate([], { replaceUrl: true, queryParams: newQueryParams, relativeTo: this.route });
    }
  }

  async openPanel(periodId, navigate = true) {
    // Make sure we actually want to navigate here.
    //
    if (navigate && this.selectedPeriodId$.value !== periodId) {
      // Update the url.
      //
      const currentQueryParams = await firstValueFrom(this.route.queryParams);
      const newQueryParams = omit(currentQueryParams, 'opened');
      this.router.navigate([`/organization/${this.organizationUnitId}/program/${this.programId}`], {
        queryParams: { ...newQueryParams, periodId },
        replaceUrl: true,
      });
    }

    this.selectedPeriodId$.next(periodId);
  }

  /**
   * Show an HTML version of the PDF in a popup
   */
  showHTML() {
    this.dialog.open(TemplateHtmlDialogComponent, {
      maxWidth: '810px',
      width: '80vw  ',
      minWidth: '320px',
      minHeight: '80vh',
      panelClass: 'template-html-dialog',
      data: {
        programId: this.programId,
        templateId: DOENKIDS_DEFAULT_TEMPLATE_ID,
      },
    });
  }

  async saveTemplateSettings() {
    await this._saveProgramDetails();
    this.pdfRenderTrigger$.next();
  }

  /** Remove an activity from the program */
  async removeSection(periodSection: IPeriodSection) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      minWidth: '320px',
      data: {
        title: this.$translateService.instant(_('generic.confirm')),
        description: this.$translateService.instant(_('program_details.activity.remove.dialog.description')),
      },
    });

    const result = await firstValueFrom(dialogRef.afterClosed());

    if (result === 'confirm') {
      await this.periodSectionService.archive(periodSection.id);
      this.pdfRenderTrigger$.next();
    }
  }

  async saveDetails() {
    const promises: Array<Promise<any>> = [];
    this.savingProgramPleaseWait$.next(true);

    try {
      promises.push(this._saveProgramDetails());
      promises.push(this._savePeriods());

      // make the code adapt to that fact
      //
      if (!isNil(this.periodSections)) {
        promises.push(this.periodSections.saveProgramPeriodSections());
      }

      await Promise.all(promises);

      this.fetchProgramOURoles(this.programId);

      this.pdfRenderTrigger$.next();
      this.pdfLoading$.next(true);
    } catch (error) {
      console.error('Error while trying to save program details', error);
    }

    firstValueFrom(
      this.pdfLoading$.pipe(
        takeUntil(this.destroy$),
        filter((loading) => loading === false),
      ),
    ).then(() => {
      this.savingProgramPleaseWait$.next(false);
    });
  }

  async _saveProgramDetails() {
    const currentProgram = (await firstValueFrom(this.program$))?.data;
    const thePromiseWeMake: Promise<any>[] = [];

    const { name, content, from, to } = this.programForm.value;
    const placeHolderImages = this.templateImagesForm.value;

    const detailsPromise = this.programService.update({
      id: currentProgram.id,
      name,
      content,
      from,
      to,
      template_options: {
        bannerImage: placeHolderImages,
      },
    } as IProgram);

    thePromiseWeMake.push(detailsPromise);
    thePromiseWeMake.push(this.updateProgramGroups());

    return Promise.all(thePromiseWeMake);
  }

  async _savePeriods() {
    // Get all the sections in this activity.
    //
    const programPeriodsList = (await firstValueFrom(this.programPeriods$));

    const promises: Promise<IPeriod>[] = [];

    // Go through every section
    //
    for (const period of programPeriodsList) {
      // Save the section.
      //
      const promise = this._savePeriod(period);
      promises.push(promise);
    }

    const allResults = await Promise.all(promises);
    return allResults;
  }

  async savePeriod(period: IProgramSummaryPeriod) {
    await this._savePeriod(period);
    this.pdfRenderTrigger$.next();
  }

  async _savePeriod(period: IProgramSummaryPeriod) {
    // Get the values from the period form.
    //
    const periodFormValues = this.periodForm.value;

    // Figure out whether the periodForm contains a value with this period ID
    //
    if (periodFormValues.hasOwnProperty(period.id)) {
      // Get the current and original value of the form.
      //
      const updatedPeriod: IPeriod = periodFormValues[period.id];

      // when the type of the period is date we need to convert the start date and end date of the period
      // to a date with time and timezone else the database won't accept it. Also we sync the end date
      // with the start date at the moment as this for now will always be the same. This can change over time
      // we fix the date filled in as utc as otherwise the backend will convert it to utc time which will
      // give an offset of 1 or 2 hours depending on day light savings
      //
      if (updatedPeriod.period_type === DAY_PROGRAM_TYPE) {
        const { start, end } = this.createDayProgramStartAndEndDate(updatedPeriod.start_date);
        updatedPeriod.start_date = start;
        updatedPeriod.end_date = end;
      }

      // we have to this ugly hack because the original period's order gets updated from panel-container component but this isn't propagated to
      // the form control. If we wouldn't replace the order we would only be able to have the order on which the page originally loaded
      //
      updatedPeriod.order = period.order;

      // If the original period is an actual filled in value, and the original period is not equal to the current one, update.
      //
      if (isObject(period) && !isEqual(omit(period, ['section']), omit(updatedPeriod, ['section']))) {
        return this.programPeriodService.update({
          id: period.id,
          program_id: this.programId,
          name: updatedPeriod.name,
          subtitle: updatedPeriod.subtitle,
          order: updatedPeriod.order ?? period.order,
          summary: updatedPeriod.summary,
          start_date: updatedPeriod.start_date,
          end_date: updatedPeriod.end_date,
          period_type: updatedPeriod.period_type,
        } as IPeriod);
      }
    }
    return null;
  }

  removePeriod(period: IProgramSummaryPeriod) {
    const periodName =
      period.period_type === 'FREEFORM'
        ? period.name || this.$translateService.instant(_('generic.field'))
        : period.start_date
          ? this.toTitleCase(this.$translateService.getDayjsLocaleInstance(period.start_date).format('dddd D MMMM YYYY'))
          : this.$translateService.instant(_('generic.field'));

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      minWidth: '320px',
      data: {
        title: this.$translateService.instant(_('generic.confirm')),
        description: this.$translateService.instant(_('program_details.period.remove.confirm.dialog.description'), { periodName: periodName.trim() }),
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result === 'confirm') {
        this.selectedPeriodId$.next(null);
        await this.programPeriodService.archive(period.id);
        this.periodForm.removeControl(period?.id?.toString());
        this.pdfRenderTrigger$.next();
        this.programPeriodListService.fetchAll(this.programId);
      }
    });
  }

  async removeProgram() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      minWidth: '320px',
      data: {
        title: this.$translateService.instant(_('generic.confirm')),
        description: this.$translateService.instant(_('program.remove.confirm.dialog.description')),
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result === 'confirm') {
        await this.programService.archive(this.programId);
        const currentOu = await firstValueFrom(this.$session.getOrganizationUnit$);
        this.$programProvider.fetchUnpublishedProgramCount(currentOu.id);
        if (this.backUrl) {
          const { rootUrl, queryParams } = getRootUrlAndQueryParamsFromUrl(this.backUrl);
          this.router.navigate([rootUrl], { queryParams });
        } else if (this.originIsInsights) {
          this.location.back();
        } else {
          this.router.navigate(['/']);
        }
      }
    });
  }

  /** Renders the pdf using the publish API.
   */
  async renderPdf() {
    this.pdfLoading$.next(true);

    const currentTime = new Date().getTime();
    this.lastPdfRender = currentTime;
    const thisRenderStartedAt = this.lastPdfRender;

    if (this.pdfViewer) {
      this.pdfViewer.pdfSrc = null;
    }

    let program: IExtendedProgramSummary;

    try {
      program = (await firstValueFrom(this.program$))?.data;
    } catch (e) {
      this.$i18nToastProvider.error(_('program_details.pdf.retrieval_failed'));
      this.pdfLoading$.next(false);
      return;
    }

    if (isNil(program)) {
      this.$i18nToastProvider.error(_('program_details.pdf.retrieval_failed'));
      this.pdfLoading$.next(false);
      // Don't render the program before it is fetched.
      //
      return;
    }

    const fileName = snakeCase(get(program, 'name'));

    let pdf: string | Blob;

    try {
      const request = {
        program_id: program.id,
        organization_unit_id: this.organizationUnitId,
        template_id: DOENKIDS_DEFAULT_TEMPLATE_ID,
      };
      pdf = await this.publishService.getProgramPdfUrl(request, fileName);
    } catch (e) {
      this.$i18nToastProvider.error(_('program_details.pdf.retrieval_failed'));
      this.pdfLoading$.next(false);
    }

    // Only render the last time the PDF was requested to render to prevent 'flickering'
    //
    if (pdf && thisRenderStartedAt === this.lastPdfRender) {
      this.pdf$.next(pdf);
    }
  }

  openTab(tab: number = 0) {
    this.activeTab = tab;

    if (tab === 1 && isNil(this.selectedPeriodId$.value)) {
      this.pdfRenderTrigger$.next();
    }
  }

  async addTemplateImage(mediaItem: IUploadResponse | IMediaItem, controlName: number) {
    const templateImageControl = this.templateImagesForm.get(controlName.toString());
    const isAdmin = await firstValueFrom(this.isAdmin$);
    let shouldRerenderPdf = false;

    if (isAdmin && isEqual(templateImageControl.value, mediaItem.uuid)) {
      templateImageControl.setValue(null);
      this.cd.detectChanges();
      if (this.rightSideView$.value === 'pdf') {
        shouldRerenderPdf = true;
      }
    }

    if (mediaItem.uuid) {
      templateImageControl.setValue(mediaItem.uuid);
    } else {
      templateImageControl.reset();
    }

    if (shouldRerenderPdf) {
      this.pdfRenderTrigger$.next();
    }
  }

  private async promptSaveDialog(extraMessage: string) {
    if (get(this.periodSections, 'periodSectionsForm.pristine') || isNil(this.periodSections)) {
      return null;
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      minWidth: '320px',
      data: {
        title: this.$translateService.instant(_('generic.confirm')),
        description: this.$translateService.instant(_('program_details.save.confirm.dialog.description'), { extraMessage: extraMessage || undefined }),
      },
    });

    const response = await firstValueFrom(dialogRef.afterClosed());

    return response;
  }

  async setPanelToPdf(extraMessage?: string) {
    const response = await this.promptSaveDialog(extraMessage);

    if (response === 'confirm') {
      if (!isNil(this.periodSections)) {
        const results = await this.periodSections.saveProgramPeriodSections();
        if (isNil(results)) {
          console.warn('Could not save sections');
          return;
        }
      }
      this.updatePeriodActivities();
    }

    this.rightSideView$.next('pdf');
    const currentQueryParams = await firstValueFrom(this.route.queryParams);
    const newQueryParams = omit(currentQueryParams, ['periodSections', 'opened']);
    this.router.navigate([], { replaceUrl: true, queryParams: newQueryParams, relativeTo: this.route });
    this.pdfRenderTrigger$.next();
  }

  async setPanelToPeriodSections() {
    this.rightSideView$.next('periodSections');
    const currentQueryParams = await firstValueFrom(this.route.queryParams);
    const newQueryParams = { ...currentQueryParams, periodSections: true };
    this.router.navigate([], { replaceUrl: true, queryParams: newQueryParams, relativeTo: this.route });
  }

  /**
   * Downloads the pdf using the pushish API
   */
  async downloadPDF() {
    const program = (await firstValueFrom(this.program$))?.data;
    const programTitle = get(program, 'name') ? `"${program.name}"` : this.$translateService.instant(_('program_details.this_program'));

    this.dialog.open(DownloadProgramPdfDialogComponent, {
      width: '400px',
      minWidth: '320px',
      data: {
        title: this.$translateService.instant(_('generic.download')),
        description: this.$translateService.instant(_('program_details.pdf.download.dialog.description'), { programTitle }),
        programTitle,
        program,
      },
    });
  }

  async createProgramTemplate() {
    const isDoenkidsBeheer = await firstValueFrom(this.isDoenkidsBeheer$);
    const hasProgramTemplateCreatePermission = await firstValueFrom(this.hasProgramTemplateCreatePermission$);
    const hasWriteAccess = await firstValueFrom(this.hasWriteAccess$);

    if (!isDoenkidsBeheer && !hasProgramTemplateCreatePermission && !hasWriteAccess) {
      this.$i18nToastProvider.error(_('program_details.error.unauthorized'));

      return;
    }

    const program = (await firstValueFrom(this.program$))?.data;

    if (isNil(program)) {
      this.$i18nToastProvider.error(_('program_details.error.unavailable'));

      return;
    }

    const programTitle = get(program, 'name') ? `"${program.name}"` : this.$translateService.instant(_('program_details.this_program'));

    const dialogRef = this.dialog.open(CreateProgramTemplateDialogComponent, {
      width: '400px',
      minWidth: '320px',
      data: {
        programTitle,
      },
    });

    const result = (await firstValueFrom(dialogRef.afterClosed())) as Partial<ICreateProgramTemplate>;

    if (isNil(result)) {
      return;
    }

    const { intended_duration, media_uuid, program_category_id, organization_unit_id } = result;
    const { name, id: program_id, language } = program;
    const countryCode = await firstValueFrom(this.$session.ouCountryCode$);

    const programTemplate = await this.programTemplateService.create(organization_unit_id, {
      name,
      program_id,
      media_uuid,
      intended_duration,
      program_category_id,
      program_template_status_id: 3,
      country_code: countryCode,
      language,
    });

    if (isNil(programTemplate)) {
      this.$i18nToastProvider.error(_('program_details.error.template_creation_failed'), { programTitle });
    } else {
      // the ou id in the route will switch ou's for us so we can directly edit the new template
      // we already call destroy here so that the change in ou isn't picked up anymore by this component as there appears
      // to be a race condition in destroying this component and creating the template preview component
      // probably because of the pdf rendering
      //
      this.destroy$.next();
      this.router.navigate([`/organization/${organization_unit_id}/template/${programTemplate.id}`]);
    }
  }

  async copyProgramToOtherLocation() {
    const browseOrganizationUnitDialog = this.dialog.open(BrowseOrganizationUnitDialogComponent, {
      width: '600px',
      minWidth: '420px',
      data: {
        title: this.$translateService.instant(_('program_details.copy.dialog.title')),
        description: this.$translateService.instant(_('program_details.copy.dialog.description')),
        allowMultipleSelection: true,
        selectableOrganizationUnitTypeIds: [DoenkidsStaticValuesHelper.ORGANIZATION_UNIT_TYPE_LOCATION],
        writeableOnly: true,
        initialOrganizationUnitSelection: [],
      },
    });

    const results: ISelectedOrganizationUnit[] = await firstValueFrom(browseOrganizationUnitDialog.afterClosed());

    if (!isNil(results) && results.length > 0) {
      const program = (await firstValueFrom(this.program$))?.data;

      const locations: IPossibleCopyProgramLocation[] = [];

      results.forEach((result) => {
        const locationOption: IPossibleCopyProgramLocation = {
          id: result.id,
          name: result.name,
        };

        locations.push(locationOption);
      });

      const { name, to, from } = program;
      const data: ICopyProgramDialogData = {
        locations,
        name,
        to,
        from,
      };

      const dialogRef = this.dialog.open(CopyProgramDialogComponent, {
        width: '400px',
        minWidth: '320px',
        data,
      });

      const programOverride: ICopyProgramDialogResult = await firstValueFrom(dialogRef.afterClosed());

      if (isNil(programOverride)) {
        return;
      }

      this.programCopying$.next(true);

      if (programOverride.locations) {
        const succesfullCopies: string[] = [];
        const failedCopies: string[] = [];
        for (const location of programOverride.locations) {
          // eslint-disable-next-line no-await-in-loop


          const copiedProgram = await this.programService.copy(
            {
              id: program.id,
              name: program.name,
              content: program.content,
              from: program.from,
              to: program.to,
              template_options: program.template_options,
              program_category_id: parseInt(`${program.program_category}`, 10),
              program_status_id: parseInt(`${program.program_status_id}`, 10),
              activity_type_id: parseInt(`${program.activity_type}`, 10),
              language: program.language,
              country_code: program.country_code,
              ...omit(programOverride, 'location'),
            } as IProgram,
            location.id,
          );
          if (isNil(copiedProgram)) {
            failedCopies.push(location.name);
          } else {
            succesfullCopies.push(location.name);
          }
        }

        if (failedCopies.length > 0) {
          if (succesfullCopies.length === 0) {
            this.$i18nToastProvider.error(_('program_details.copy.failed.all'), {
              failedCopies: failedCopies.join(', ') || undefined,
              failedCopyCount: failedCopies.length,
            });
          } else {
            this.$i18nToastProvider.error(_('program_details.copy.failed.combo'), {
              failedCopies: failedCopies.join(', ') || undefined,
              failedCopyCount: failedCopies.length,
              successfulCopies: succesfullCopies.join(', ') || undefined,
              successfulCopyCount: succesfullCopies.length,
            });
          }


          return;
        }

        if (failedCopies.length === 0 && succesfullCopies.length > 0) {
          this.$i18nToastProvider.success(_('program_details.copy.success'), {
            successfulCopyCount: succesfullCopies.length,
            firstSuccessfulCopy: succesfullCopies[0],
          });
        }

        if (programOverride.locations.length === 1) {
          this.programCopying$.next(false);
          this.$session.selectOrganizationUnit(programOverride.locations[0].id);
          this.router.navigate(['calendar/'], { queryParams: { date: programOverride.from } });
        }
      }

      this.programCopying$.next(false);
    }
  }

  async createLanguageCopyOfProgram(language: string) {
    this.programCopying$.next(true);
    const currentOrganization = await firstValueFrom(this.$session.getOrganizationUnit$);
    const program = (await firstValueFrom(this.program$))?.data;

    const newProgram = await this.programService.copy({
      id: program.id,
      name: program.name,
      from: program.from,
      to: program.to,
      country_code: currentOrganization.country_code,
      language,
    }, currentOrganization.id);

    const periods = (await this.programPeriodListService.fetchAll(newProgram.id, 100, 0, 'order', 'asc', true))?.items ?? [];
    const nonRelatedActivities: any[] = [];

    for (const period of periods) {
      const periodSections = (await this.programPeriodSectionService.fetchAllByPeriodId(period.id, 50, 0, 'order', 'ASC', true))?.items ?? [];
      const activityPeriodSections = periodSections.filter((periodSection) => periodSection.type_id === EProgramPeriodSectionType.ACTIVITY);

      for (const activityPeriodSection of activityPeriodSections) {
        if (activityPeriodSection.data.activity_id) {
          const currentActivity = await this.activityService.fetch(activityPeriodSection.data.activity_id);
          if (currentActivity && currentActivity.data?.language && newProgram?.language && currentActivity.data.language !== newProgram.language) {
            nonRelatedActivities.push(currentActivity);
          }
        }
      }
    }

    this.programCopying$.next(false);

    if (nonRelatedActivities.length > 0) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '400px',
        minWidth: '320px',
        data: {
          title: this.$translateService.instant(
            _('program_details.copy.missed_activities.title'),
          ),
          description: this.$translateService.instant(_('program_details.copy.missed_activities.description'), {
            activities: nonRelatedActivities.map((activity) => activity.title ?? '').join(', '),
            activityCount: nonRelatedActivities.length,
          }),
        },
      });

      await firstValueFrom(dialogRef.afterClosed());
    }

    return newProgram;
  }

  /** Open a dialog to select symbols.
   */
  async selectSymbols() {
    if (isEmpty(this.programPeriods$.value)) {
      this.$i18nToastProvider.error(_('program_symbols_dialog.no_activities.toast'));
      return;
    }
    const maxSymbols = await firstValueFrom(this.$doenkidsTemplateProvider.programTemplateMaxSymbolCount$);

    const dialogRef = this.dialog.open(ProgramSymbolsDialogComponent, {
      minWidth: '320px',
      minHeight: '400px',
      panelClass: 'program-symbols-dialog-panel',
      data: {
        programId: this.programId,
        periods: this.programPeriods$.value,
        maxSymbols,
        title: this.$translateService.instant(_('program_details.symbols.dialog.title')),
      },
    });

    const result = await firstValueFrom(dialogRef.afterClosed());

    // Rerender the pdf.
    //
    if (result === 'confirm') {
      if (this.rightSideView$.value === 'pdf') {
        this.pdfRenderTrigger$.next();
      }

      this.updatePeriodActivities();
    }
  }

  createDayProgramStartAndEndDate(date: string): { start: string; end: string } {
    const start = dayjs(date).set('hour', 9).set('minutes', 0).utc().format('YYYY-MM-DDTHH:mm:ssZ');
    const end = dayjs(date).set('hour', 17).set('minutes', 0).utc().format('YYYY-MM-DDTHH:mm:ssZ');
    return { start, end };
  }

  async convertProgramToDayProgram(periods: IProgramSummaryPeriod[]) {
    const currentProgram = (await firstValueFrom(this.program$))?.data;
    let fromDate = dayjs(currentProgram.from);
    const toDate = dayjs(currentProgram.to);
    periods.forEach((period: IProgramSummaryPeriod) => {
      const { start, end } = this.createDayProgramStartAndEndDate(fromDate.toISOString());
      const patchedPeriod: Partial<IPeriod> = {};
      patchedPeriod.start_date = start;
      patchedPeriod.end_date = end;
      patchedPeriod.period_type = DAY_PROGRAM_TYPE;

      let nextFromDate = fromDate.add(1, 'day');

      // skip weekend days
      //
      if (this.checkIfDayIsInWeekend(nextFromDate)) {
        while (this.checkIfDayIsInWeekend(nextFromDate)) {
          nextFromDate = nextFromDate.add(1, 'day');
        }
      }

      if (nextFromDate.isBefore(toDate)) {
        fromDate = nextFromDate;
      }

      this.periodForm.get(period?.id?.toString()).patchValue(patchedPeriod);
    });
  }

  async updateDayProgramPeriodDays(startDate, endDate) {
    const periods = await firstValueFrom(this.programPeriods$) ?? [];
    const sortedPeriods = periods.sort((periodA, periodB) => periodA.order - periodB.order);
    const firstPeriodItem = sortedPeriods && sortedPeriods[0] ? periods[0] : null;
    if (firstPeriodItem) {
      const currentFirstPeriod = this.periodForm.get(firstPeriodItem?.id?.toString());
      if (currentFirstPeriod?.value.period_type === DAY_PROGRAM_TYPE) {
        let fromDate = dayjs(startDate);
        const toDate = dayjs(endDate);

        periods.forEach((period: IProgramSummaryPeriod) => {
          const { start, end } = this.createDayProgramStartAndEndDate(fromDate.toISOString());
          const patchedPeriod: Partial<IPeriod> = {};
          patchedPeriod.start_date = start;
          patchedPeriod.end_date = end;
          patchedPeriod.period_type = DAY_PROGRAM_TYPE;

          let nextFromDate = fromDate.clone().add(1, 'day');

          // skip weekend days
          //
          if (this.checkIfDayIsInWeekend(nextFromDate)) {
            while (this.checkIfDayIsInWeekend(nextFromDate)) {
              nextFromDate = nextFromDate.add(1, 'day');
            }
          }

          if (nextFromDate.isBefore(toDate)) {
            fromDate = nextFromDate;
          }

          // Patch period form control
          //
          if (period?.id) {
            const formCtrl = this.periodForm.get(period.id.toString());
            if (formCtrl) {
              formCtrl.patchValue(patchedPeriod);
            }
          }
        });
      }
    }
  }

  convertProgramToFreeForm(periods: IProgramSummaryPeriod[]) {
    (periods ?? []).forEach((period: IProgramSummaryPeriod) => {
      // Patch period form control
      //
      if (period?.id) {
        const formCtrl = this.periodForm.get(period.id.toString());
        if (formCtrl) {
          formCtrl.patchValue({
            period_type: FREEFORM_PROGRAM_TYPE,
          });
        }
      }
    });
  }

  /**
   * Check if a day is a weekday. This should always be called with a dayjs object
   * @param date
   */
  checkIfDayIsInWeekend(date: dayjs.Dayjs) {
    const weekDayNumber = date.day();
    return weekDayNumber === 6 || weekDayNumber === 0; // dayjs marks 6 as Saturday and 0 as Sunday
  }

  async fetchProgramOURoles(programId: number) {
    const programOrganizationUnits = (await firstValueFrom(this.programService.organizationUnits(programId))) as IOrganizationUnitProgramRoles[];

    this.programOURoles$.next(programOrganizationUnits);
  }

  onPdfLoadedEvent() {
    this.pdfViewer?.PDFViewerApplication?.preferences?.set('externalLinkTarget', 2);
  }

  goToEventLogs() {
    this.router.navigate([`/organization/${this.organizationUnitId}/program/${this.programId}/events`]);
  }

  // Drop event for moving items around.
  //
  async dropPeriod(event: CdkDragDrop<{ id?: number; order?: number }[]>) {
    // Get the event values.
    //
    const { previousIndex, currentIndex, container } = event;

    // If the previous index is the exact same as the current, we don't need to move anything.
    //
    if (previousIndex === currentIndex) {
      return;
    }

    // Get the original list of items from before the drag and drop event,
    // and retrieve the dropped item from it.
    //
    const items = container.data as IProgramSummaryPeriod[];
    const droppedItem = items[previousIndex];
    const castedDroppedItem: IPeriod = {
      id: droppedItem.id,
      program_id: this.programId,
      name: droppedItem.name,
      order: droppedItem.order,
    };

    // Update the store optimistically by passing the third param as true.
    // Save the first promise to be awaited later.
    //
    const firstPromise = this.programPeriodService.update({ ...castedDroppedItem, order: currentIndex + 1 });

    // Because the store is already being updated optimistically,
    // we only need to await the promises and restore the previous state if any promises fail.
    //
    const promises = [firstPromise];

    // Loop through all items in the drop list and determine whether they need to be updated.
    //
    for (let index = 0; index < items.length; index++) {
      // If the current item is actually the previous item, continue.
      //
      if (index === previousIndex) {
        // eslint-disable-next-line no-continue
        continue;
      }

      // Get the current item,
      // and prepare the update promise.
      //
      const item = items[index];
      const castedItem: IPeriod = {
        id: item.id,
        program_id: this.programId,
        name: item.name,
        order: item.order,
      };
      let promise: Promise<any>;

      // If the current item comes after the original dragged place, but before the drop position,
      // Save the index as the order. ( Less means the item moves up. )
      //
      if (index > previousIndex && index <= currentIndex) {
        promise = this.programPeriodService.update({ ...castedItem, order: index });

        // If the current item comes before the original dragged place, but after the drop position,
        // add 2 to the index and save that value as the order. ( More means the item moves down. )
        //
      } else if (index < previousIndex && index >= currentIndex) {
        promise = this.programPeriodService.update({ ...castedItem, order: index + 2 });
      }

      if (!isNil(promise)) {
        // Add the saved promise to the list of promises.
        //
        promises.push(promise);
      }
    }

    // Await all promises at the same time.
    //
    await Promise.all(promises);

    this.updatePeriodActivities();
    this.renderPdf();
  }

  async openPeriodSectionActivity(periodId: number, activityId: number) {
    const currentQueryParams = await firstValueFrom(this.route.queryParams);
    const newQueryParams = { ...currentQueryParams, periodId, opened: `${activityId}` };
    await this.router.navigate([], { replaceUrl: true, queryParams: newQueryParams, relativeTo: this.route });

    this.selectedPeriodId$.next(periodId);

    this.setPanelToPeriodSections();
  }

  toTitleCase(value: string) {
    return value?.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
  }

  async downloadAttachment(attachment: IProgramTemplateAttachmentMedia) {
    // For iOS Safari, we first need to open the window in a *synchronous* function. Async window.open calls do NOT work.
    // https://stackoverflow.com/questions/20696041/window-openurl-blank-not-working-on-imac-safari
    //
    // I removed the window.open calls, and replaced them with html anchor element clicks.
    //
    // eslint-disable-next-line no-undef
    const a = document.createElement('a');
    this.$asset.getUrl(attachment.uuid, { program_id: this.programId }).then((mediaAssetUrl) => {
      a.href = mediaAssetUrl;
      a.target = '_blank';
      a.download = attachment.filename;
      a.click();
    });
  }

  public handleIsTheOwnerChange(isTheOwner: boolean) {
    this.isTheOwner$.next(isTheOwner);
  }

  protected getProgramStatusName(programStatusId: EProgramStatus): string {
    return getProgramStatusName(programStatusId);
  }

  periodHasActivities(period: IProgramSummaryPeriod) {
    return this.activityPeriodSections$.value[period.id]?.length > 0 ;
  }

  periodIsSelected(period: IProgramSummaryPeriod) {
    return this.selectedPeriodId$.value !== period.id;
  }

  getPeriodSectionActivities(period: IProgramSummaryPeriod) {
    return this.activityPeriodSections$.value[period.id];
  }

  private async updateProgramGroups(showConfirmation: boolean = true) {
    const currentProgram = (await firstValueFrom(this.program$))?.data;
    const { customer_groups } = this.programForm.value;
    const assignmentsToModify: IOrganizationUnitProgramAssignment[] = [];

    // only change the assignments when an actual group has been unselected
    // or when it was changed in the past
    // otherwise leave it as is
    //
    const availableGroups = await firstValueFrom(this.availableGroups$);
    const currentRoles = this.programOURoles$.value;
    const groupNamesPreviouslySelectedButNotAnymore: string[] = [];
    const groupNamesCurrentlySelected: string[] = [];
    if (availableGroups && availableGroups.length > 0 && customer_groups) {
      const atLeastOneIsSelected = customer_groups.length > 0;
      availableGroups.forEach((group) => {
        const isSelected = customer_groups.includes(group.id);
        const groupRole = currentRoles.find((currentRole) => currentRole.organization_unit_id === group.id);

        /**
         * First we check if there is a record in the database that defines the role of this group
         */
        if (groupRole) {
          /**
           * There is a record and this group is now selected and was (before) revoked. So now we change it to reader
           */
          if (isSelected && groupRole.organization_unit_role_id === DoenkidsStaticValuesHelper.PROGRAM_REVOKED_ROLE) {
            groupNamesCurrentlySelected.push(group.name);
            assignmentsToModify.push({
              organization_unit_id: group.id,
              organization_unit_role_id: DoenkidsStaticValuesHelper.PROGRAM_READER_ROLE,
              program_id: currentProgram.id,
            });
            /**
             * There is a record and this group is now not selected and was (before) a reader role (so selected). So now we change it to revoked
             */
          } else if (!isSelected && groupRole.organization_unit_role_id === DoenkidsStaticValuesHelper.PROGRAM_READER_ROLE) {
            groupNamesPreviouslySelectedButNotAnymore.push(group.name);
            assignmentsToModify.push({
              organization_unit_id: group.id,
              organization_unit_role_id: DoenkidsStaticValuesHelper.PROGRAM_REVOKED_ROLE,
              program_id: currentProgram.id,
            });
          }
          /**
           * There is no record for this group in the database but it is selected so we assign it the reader role
           */
        } else if (isSelected) {
          groupNamesCurrentlySelected.push(group.name);
          assignmentsToModify.push({
            organization_unit_id: group.id,
            organization_unit_role_id: DoenkidsStaticValuesHelper.PROGRAM_READER_ROLE,
            program_id: currentProgram.id,
          });
          /**
           * There is no record for this group in the database. This group is not selected but there was a selection
           * This means we have to revoke the 'other' groups, and this group is one of those in that category.
           */
        } else if (atLeastOneIsSelected && !isSelected) {
          assignmentsToModify.push({
            organization_unit_id: group.id,
            organization_unit_role_id: DoenkidsStaticValuesHelper.PROGRAM_REVOKED_ROLE,
            program_id: currentProgram.id,
          });
        }
      });
    }

    if (assignmentsToModify.length > 0) {
      if (this.programStatusCtrl.value === EProgramStatus.PUBLISHED && showConfirmation) {
        const description: any[] = [_('program_details.save.groups.description')];
        const descriptionParams: any = {};

        if (groupNamesCurrentlySelected.length > 0) {
          description.push(_('program_details.save.groups.currently_selected'));
          descriptionParams.selectedGroups = groupNamesCurrentlySelected.map((groupName) => `<li>${groupName}</li>`);
        }

        if (groupNamesPreviouslySelectedButNotAnymore.length > 0) {
          description.push(_('program_details.save.groups.previously_selected'));
          descriptionParams.previouslySelectedGroups = groupNamesPreviouslySelectedButNotAnymore.map((groupName) => `<li>${groupName}</li>`);
        }

        const dialog = this.dialog.open(ConfirmationDialogComponent, {
          data: {
            title: this.$translateService.instant(_('generic.confirm')),
            description: this.$translateService.instant(description, descriptionParams),
          },
        });
        const result = await firstValueFrom(dialog.afterClosed());

        if (result !== 'confirm') {
          return;
        }
      }
      const assignmentPromise = this.programOrganizationUnitService.assign({
        programId: currentProgram.id,
        assignments: assignmentsToModify,
      });
      return assignmentPromise;
    }
  }

  private async fetchProgramActivityStatus(program: IProgram) {
    const currentOU = await firstValueFrom(this.$session.currentOuId$);

    const activityStatuses = await this.programService.getProgramActivityStatus(program.id, currentOU);

    this.excludedActivityIds$.next(
      uniq(activityStatuses
        .filter((activityStatus) => activityStatus.organization_unit_role_id === DoenkidsStaticValuesHelper.ACTIVITY_REVOKED_ROLE)
        .map((activityStatus) => activityStatus.activity_id)),
    );
  }
}
