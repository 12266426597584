import {
  ChangeDetectorRef,
  Component, EventEmitter, ExistingProvider, forwardRef, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewEncapsulation,
} from '@angular/core';
import {
  ControlValueAccessor, UntypedFormBuilder, UntypedFormControl, NG_VALUE_ACCESSOR, UntypedFormGroup,
} from '@angular/forms';
import { noop, isArray, cloneDeep } from 'lodash';
import {
  IActivityMaterial,
  IActivityTips,
  IActivityVariations,
  IActivityWhat,
  IGlossary, IMediaItem, IRequiredMaterial, ISmallstepsConclusion, ISmallstepsInstruction, ISmallstepsIntroduction, ISmallstepsPreparation, ISources, IYoutube,
} from 'typings/section-types';
import {
  ISection, IPeriodSection, ISectionType, ISectionMedia,
} from 'typings/doenkids/doenkids';
import { IActivity } from 'typings/period-section-types';
import { isNil } from '@datorama/akita';
import { UploadableDirective } from 'src/directives/uploadable.directive';
import { PermissionProvider } from 'src/providers/permission.provider';
import { DoenkidsTemplateProvider } from 'src/providers/doenkids-template.provider';
import { Observable, Subject, takeUntil } from 'rxjs';
import { TranslateService } from 'src/app/utils/translate.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { IProgramSummaryPeriodSection } from 'typings/api-activity';

_('section.glossary.add_input');
_('section.required_material.add_input');
_('section.sources.add_input');
_('section.youtube.add_input');

export interface ISectionChangedEvent {
  formData: {
    data: any;
    columns: number;
  };
  forceUpdate: boolean;
}

export interface ISectionForm {
  columns?: number;
  data: ISection | IPeriodSection;
}

export interface IActivitySectionCount {
  [key: number]: number;
}

export enum ESectionType {
  BASIC_DETAILS = 1,
  ACTIVITY_TIPS = 2,
  ACTIVITY_VARIATIONS = 3,
  ACTIVITY_WHAT = 4,
  GLOSSARY = 5,
  /**
   * Archived
   */
  REQUIRED_MATERIAL = 6,
  SOURCES = 7,
  YOUTUBE = 8,
  MEDIA = 9,
  MATERIAL = 10,
  PAGE_END = 11,
  SMALLSTEPS_PREPARATION = 12,
  SMALLSTEPS_INTRODUCTION = 13,
  SMALLSTEPS_INSTRUCTION = 14,
  SMALLSTEPS_CONCLUSION = 15,
}

export function getNameForSectionType(sectionTypeId: ESectionType): string {
  switch (sectionTypeId) {
    case ESectionType.ACTIVITY_TIPS:
      return 'activity_tips';
    case ESectionType.ACTIVITY_VARIATIONS:
      return 'activity_variations';
    case ESectionType.ACTIVITY_WHAT:
      return 'activity_what';
    case ESectionType.GLOSSARY:
      return 'glossary';
    case ESectionType.REQUIRED_MATERIAL:
      return 'required_material';
    case ESectionType.SOURCES:
      return 'sources';
    case ESectionType.YOUTUBE:
      return 'youtube';
    case ESectionType.MEDIA:
      return 'media';
    case ESectionType.MATERIAL:
      return 'material';
    case ESectionType.PAGE_END:
      return 'page_end';
    case ESectionType.SMALLSTEPS_PREPARATION:
      return 'smallsteps_preparation';
    case ESectionType.SMALLSTEPS_INTRODUCTION:
      return 'smallsteps_introduction';
    case ESectionType.SMALLSTEPS_INSTRUCTION:
      return 'smallsteps_instruction';
    case ESectionType.SMALLSTEPS_CONCLUSION:
      return 'smallsteps_conclusion';
    default:
      return 'unknown';
  }
}

export enum EProgramPeriodSectionType {
  ACTIVITY = 1,
  MEDIA = 2,
  ACTIVITY_WHAT = 3,
  PAGE_END = 4,
}

_('program.period.section_type.activity');
_('program.period.section_type.activity_what');
_('program.period.section_type.media');
_('program.period.section_type.page_end');

export function getNameForProgramPeriodSectionType(sectionTypeId: EProgramPeriodSectionType): string {
  switch (sectionTypeId) {
    case EProgramPeriodSectionType.ACTIVITY:
      return 'activity';
    case EProgramPeriodSectionType.ACTIVITY_WHAT:
      return 'activity_what';
    case EProgramPeriodSectionType.MEDIA:
      return 'media';
    case EProgramPeriodSectionType.PAGE_END:
      return 'page_end';
    default:
      return 'unknown';
  }
}

export enum ESectionContainerType {
  ACTIVITY,
  PROGRAM_PERIOD,
}

export enum EActivityStatusType {
  CONCEPT = 1,
  REVIEW = 2,
  PUBLISHED = 3,
  UNPUBLISHED = 4,
}

export const SECTION_TYPE_DEFAULT_SECTION = {
  title: '',
  subtitle: '',
  content: '',
};

export const SECTION_TYPE_DEFAULT_VALUES = {
  [ESectionType.ACTIVITY_TIPS]: (SECTION_TYPE_DEFAULT_SECTION as IActivityTips),
  [ESectionType.ACTIVITY_VARIATIONS]: (SECTION_TYPE_DEFAULT_SECTION as IActivityVariations),
  [ESectionType.ACTIVITY_WHAT]: (SECTION_TYPE_DEFAULT_SECTION as IActivityWhat),
  [ESectionType.MATERIAL]: (SECTION_TYPE_DEFAULT_SECTION as IActivityMaterial),
  [ESectionType.GLOSSARY]: ({
    title: '',
    subtitle: '',
    term: [{
      name: '',
      description: '',
    }],
  } as IGlossary),
  [ESectionType.REQUIRED_MATERIAL]: ({
    title: '',
    subtitle: '',
    material: [{
      name: '',
      description: '',
      image: '',
    }],
  } as IRequiredMaterial),
  [ESectionType.SOURCES]: ({
    title: '',
    subtitle: '',
    source: [''],
  } as ISources),
  [ESectionType.YOUTUBE]: ({
    title: '',
    subtitle: '',
    video: [''],
  } as IYoutube),
  [ESectionType.MEDIA]: ({
    title: '',
    subtitle: '',
    media: [{
      caption: '',
      description: '',
      uuid: '',
    }],
  } as ISectionMedia),
  [ESectionType.SMALLSTEPS_PREPARATION]: ({
    title: 'Voorbereiding',
    subtitle: '',
    content: '',
  } as ISmallstepsPreparation),
  [ESectionType.SMALLSTEPS_INTRODUCTION]: ({
    title: 'Introductie',
    subtitle: '',
    content: '',
  } as ISmallstepsIntroduction),
  [ESectionType.SMALLSTEPS_INSTRUCTION]: ({
    title: 'Aan de slag',
    subtitle: '',
    content: '',
  } as ISmallstepsInstruction),
  [ESectionType.SMALLSTEPS_CONCLUSION]: ({
    title: 'Afronding',
    subtitle: '',
    content: '',
  } as ISmallstepsConclusion),
};

export const SECTION_CONTROL_VALUE_ACCESSOR: ExistingProvider = {
  provide: NG_VALUE_ACCESSOR,
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  useExisting: forwardRef(() => SectionComponent),
  multi: true,
};

@Component({
  selector: 'app-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [SECTION_CONTROL_VALUE_ACCESSOR],
})
export class SectionComponent extends UploadableDirective implements OnInit, OnChanges, OnDestroy, ControlValueAccessor {
  private stop$ = new Subject<void>();

  @Input() public section: ISection | IPeriodSection | IProgramSummaryPeriodSection;

  @Input() public sectionContainerType = ESectionContainerType.ACTIVITY;

  @Input() public activitySectionCount: IActivitySectionCount;

  @Input() public activitySectionTypes: ISectionType[];

  @Input() public readOnly = false;

  @Output()
    archived = new EventEmitter<void>();

  @Output()
    saved = new EventEmitter<ISectionChangedEvent>();

  public sectionDisplayName = '';

  /** Value we will call whenever our form is touched */
  private onTouchedCallback: Function = noop;

  /** Function we will call whenever the value changes */
  private onChangeCallback: (val: number) => void = noop;

  public disableActivityColumns$: Observable<boolean>;

  public form: UntypedFormGroup;

  public allowedToEdit$: Observable<boolean>;

  public canRemoveLastSectionOfType = true;

  readonly ESectionType = ESectionType;

  readonly EProgramPeriodSectionType = EProgramPeriodSectionType;

  readonly ESectionContainerType = ESectionContainerType;

  readonly sectionTypesWithUserConfigurableInputCount = [
    ESectionType.GLOSSARY,
    ESectionType.REQUIRED_MATERIAL,
    ESectionType.SOURCES,
    ESectionType.YOUTUBE,
  ];

  readonly mediaPurpose = 'period';

  @HostListener('blur') onBlur() {
    this.onTouchedCallback();
  }

  constructor(
    private fb: UntypedFormBuilder,
    $doenkidsTemplate: DoenkidsTemplateProvider,
    $permission: PermissionProvider,
    private $translateService: TranslateService,
    private cd: ChangeDetectorRef,
  ) {
    super();

    this.disableActivityColumns$ = $doenkidsTemplate.programDisableActivityColumns$.pipe(takeUntil(this.stop$));

    this.allowedToEdit$ = $permission.hasOUWritePermissions$.pipe(takeUntil(this.stop$));
  }

  ngOnInit() {
    this.form = this.fb.group({
      data: [],
      columns: [1],
    });

    this.sectionDisplayName = this.section.type_id === ESectionType.BASIC_DETAILS
      ? this.$translateService.instant(_('generic.activity'))
      : this.$translateService.instant(_('generic.field'));

    this.form.valueChanges.pipe(takeUntil(this.stop$)).subscribe((sectionValue) => {
      this.onChangeCallback(sectionValue);
    });

    this.setCanRemoveLastSectionOfType();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes.section && !changes.section.firstChange)
      || (changes.activitySectionCount && !changes.activitySectionCount.firstChange)
      || (changes.activitySectionTypes && !changes.activitySectionTypes.firstChange)) {
      this.setCanRemoveLastSectionOfType();
    }
  }

  ngOnDestroy(): void {
    this.stop$.next();
  }

  writeValue(value: any) {
    this.form.get('data').setValue(value.data);
    this.form.get('columns').setValue(value.columns);
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  async remove() {
    if (this.canRemoveLastSectionOfType) {
      this.archived.emit();
    }
  }

  addImage(mediaItem: IMediaItem) {
    const sectionControl = this.form.get('data') as UntypedFormControl;
    const mediaSection = (sectionControl.value as any);
    if (isArray(mediaSection.media)) {
      mediaSection.media.push(mediaItem);
    } else {
      mediaSection.media = [mediaItem];
    }
    sectionControl.patchValue(mediaSection);
  }

  addActivityImage(mediaItem: IMediaItem) {
    const activityControl = this.form.get('data') as UntypedFormControl;
    let activity: Partial<IActivity> = cloneDeep(activityControl.value);
    activity = !isNil(activity) ? activity : {};
    if (mediaItem.uuid) {
      activity.media_uuid = mediaItem.uuid;
    } else {
      activity.media_uuid = null;
    }
    activityControl.setValue(activity);
  }

  addControl() {
    const sectionControl = this.form.get('data') as UntypedFormControl;
    const glossarySectionTerms = (sectionControl.value as IGlossary)?.term || [];
    const requiredMaterials = (sectionControl.value as IRequiredMaterial)?.material;
    const sourcesSection = (sectionControl.value as ISources) || { source: [] };
    const sources: string[] = sourcesSection?.source;
    const youtubeSection = (sectionControl.value as IYoutube) || { video: [] };
    const videos: string[] = youtubeSection?.video;

    switch (this.section.type_id) {
      case ESectionType.GLOSSARY:
        // Add a new IGlossaryTerm object and patch the sectionControlValue. The component can take care of the formControls.
        //
        glossarySectionTerms.push({
          description: '',
          name: '',
        });
        sectionControl.patchValue((sectionControl.value as IGlossary));
        break;
      case ESectionType.REQUIRED_MATERIAL:
        // Add a new IRequiredMaterialItem object and patch the sectionControlValue. The component can take care of the formControls.
        //
        requiredMaterials.push({
          description: '',
          image: '',
          name: '',
        });
        sectionControl.patchValue((sectionControl.value as IRequiredMaterial));
        break;
      case ESectionType.SOURCES:
        // Add an empty value to the value array, and patch the sectionControlValue. The component can take care of the formControls.
        //
        sources.push('');
        sectionControl.patchValue(sourcesSection);
        break;
      case ESectionType.YOUTUBE:
        // Add an empty value to the value array, and patch the sectionControlValue. The component can take care of the formControls.
        //
        videos.push('');
        sectionControl.patchValue(youtubeSection);
        break;
      default:
        break;
    }
  }

  save(forceUpdate: boolean = false) {
    const data = this.form.value;
    this.saved.emit({
      formData: data,
      forceUpdate,
    });
  }

  setCanRemoveLastSectionOfType() {
    if (!this.activitySectionCount || !this.activitySectionTypes) {
      this.canRemoveLastSectionOfType = true;
      return;
    }

    const currentSectionType = this.activitySectionTypes.find((activitySectionType) => activitySectionType.id === this.section?.type_id);
    const currentActivitySectionCount = this.activitySectionCount[this.section?.type_id];

    if (!currentSectionType || !currentActivitySectionCount || !currentSectionType.required) {
      this.canRemoveLastSectionOfType = true;
      return;
    }

    this.canRemoveLastSectionOfType = currentActivitySectionCount > 1;
  }

  getNameForSectionType(sectionTypeId: ESectionType): string {
    return getNameForSectionType(sectionTypeId);
  }
}
