import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subject, distinctUntilChanged, map, takeUntil } from 'rxjs';
import { TranslateService, getEnabledLanguages } from 'src/app/utils/translate.service';
import { InfoDialogComponent } from 'src/components/dialogs/info-dialog/info-dialog.component';
import { DoenKidsPreferencesProvider, TPDFRenderer } from 'src/providers/preferences.provider';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { isNil } from 'lodash';

_('generic.language.select.label');
_('generic.language.select.description');
_('generic.locale.select.label');
_('generic.locale.select.description');

@Component({
  selector: 'app-user-preferences',
  templateUrl: './user-preferences.component.html',
  styleUrls: ['./user-preferences.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserPreferencesComponent implements OnDestroy {
  private stop$ = new Subject<void>();

  public isAdmin$: Observable<boolean>;

  public pdfWriterControl: FormControl<TPDFRenderer | 'none'>;

  public availableLocales: string[];

  public selectedLocale: FormControl<string>;

  public selectedContentLanguage: FormControl<string>;

  public availableContentLanguages$: Observable<string[]>;

  constructor(
    $session: DoenkidsSessionProvider,
    private preference$: DoenKidsPreferencesProvider,
    private $translateService: TranslateService,
    private $dialog: MatDialog,
  ) {

    this.isAdmin$ = $session.isAdmin$.pipe(takeUntil(this.stop$));
    this.pdfWriterControl = new FormControl<TPDFRenderer | 'none'>('none');

    this.preference$.pdfRendererPreference$.pipe(takeUntil(this.stop$)).subscribe((pdfRendererPreference) => {
      const parsedPreference = isNil(pdfRendererPreference) ? 'none' : pdfRendererPreference;
      this.pdfWriterControl.setValue(parsedPreference);
    });

    this.pdfWriterControl.valueChanges.pipe(takeUntil(this.stop$)).subscribe((controlValue) => {
      const parsedControlValue = controlValue === 'none' ? null : controlValue;
      this.preference$.setPDFRendererPreference(parsedControlValue);
    });

    this.selectedContentLanguage = new FormControl<string>('');

    this.selectedContentLanguage.valueChanges.pipe(takeUntil(this.stop$), distinctUntilChanged()).subscribe((contentLanguage) => {
      $session.setPreferredContentLanguage(contentLanguage);
    });

    $session.preferredContentLanguage$.pipe(takeUntil(this.stop$)).subscribe((contentLanguage) => {
      this.selectedContentLanguage.setValue(contentLanguage);
    });

    this.availableContentLanguages$ = $session.ouLanguages$.pipe(takeUntil(this.stop$));

    this.availableContentLanguages$.subscribe((contentLanguages) => {
      if (contentLanguages.length === 1) {
        this.selectedContentLanguage.disable();
      } else {
        this.selectedContentLanguage.enable();
      }
    });

    this.selectedLocale = new FormControl<string>('');

    this.selectedLocale.valueChanges.pipe(takeUntil(this.stop$), distinctUntilChanged()).subscribe((language) => {
      $translateService.use(language);
    });

    $translateService.onInitialLangAndLangChange$.pipe(
      takeUntil(this.stop$),
      map((langEvent) => langEvent.lang),
    ).subscribe((contentLanguage) => {
      this.selectedLocale.setValue(contentLanguage);
    });
    this.availableLocales = getEnabledLanguages();

    if (this.availableLocales.length === 1) {
      this.selectedLocale.disable();
    }
  }

  ngOnDestroy(): void {
    this.stop$.next();
  }

  async openInfoWindow($event: MouseEvent, type: string) {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }

    this.$dialog.open(InfoDialogComponent, {
      width: '400px',
      minWidth: '320px',
      data: {
        title: this.$translateService.instant(`generic.${type}.select.label`),
        description: this.$translateService.instant(`generic.${type}.select.description`),
      },
    });
  }
}
