/* eslint-disable no-undef */
/* eslint-disable max-classes-per-file */
import { NewsfeedItemComponent } from 'src/components/shared/newsfeed-item/newsfeed-item.component';

import { BrowserModule } from '@angular/platform-browser';
import {
  NgModule, LOCALE_ID, ErrorHandler, Injectable, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER,
} from '@angular/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DoenkidsAssetProvider } from 'src/providers/assets.provider';
import { DoenKidsAuth0Provider } from 'src/providers/auth0.provider';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { HomeComponent } from 'src/components/pages/home/home.component';

import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpBackend } from '@angular/common/http';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TitleStrategy } from '@angular/router';

import { environment } from 'src/environments/environment';
import { ProfilePanelComponent } from 'src/components/layout/split-pane/profile-pane/profile-panel.component';
import { ScreenSizeProvider } from 'src/providers/screen-size.provider';
import { SplitPaneComponent } from 'src/components/layout/split-pane/split-pane.component';
import { OrganizationUnitSelectorComponent } from 'src/components/layout/split-pane/profile-pane/organization-unit-selector/organization-unit-selector.component';
import { BrowseOrganizationUnitDialogComponent } from 'src/components/dialogs/browse-organization-unit-dialog/browse-organization-unit-dialog.component';
import { ConfirmationDialogComponent } from 'src/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { UserDialogComponent } from 'src/components/dialogs/user-dialog/user-dialog.component';
import { ActivityAggregationsProvider } from 'src/providers/activity-aggregations.provider';
import { ActivitySelectionProvider } from 'src/providers/activity-selection.provider';
import { BreakpointsProvider } from 'src/providers/breakpoints.provider';
import { PermissionProvider } from 'src/providers/permission.provider';
import { ScrollEndDirective } from 'src/directives/scroll-end.directive';
import { ActivitySearchComponent } from 'src/components/pages/activity-search/activity-search.component';
import { ActivitySearchBarComponent } from 'src/components/shared/activity-search-bar/activity-search-bar.component';
import { ActivityStatusFiltersComponent } from 'src/components/pages/activity-search/activity-status-filters/activity-status-filters.component';
import { SplitPageComponent } from 'src/components/layout/split-page/split-page.component';
import { ResponsiveToolbarContentComponent } from 'src/components/layout/responsive-toolbar-content/responsive-toolbar-content.component';
import { NgxMasonryComponent } from 'src/components/layout/ngx-masonry/ngx-masonry.component';
import { NgxMasonryDirective } from 'src/components/layout/ngx-masonry/ngx-masonry.directive';
import { ActivityCardComponent } from 'src/components/shared/activity-card/activity-card.component';
import { TruncatePipe } from 'src/pipes/truncate.pipe';
import { SafePipe } from 'src/pipes/safe.pipe';
import { HighlightPipe } from 'src/pipes/highlight.pipe';
import { SelectedActivitiesComponent } from 'src/components/pages/activity-search/selected-activities/selected-activities.component';
import { SearchChipsComponent } from 'src/components/shared/search-chips/search-chips.component';
import { SearchAggregationsComponent } from 'src/components/shared/search-aggregations/search-aggregations.component';
import {
  TranslateModule, TranslateLoader, TranslateCompiler, MissingTranslationHandler, TranslateService as NgxTranslateService,
} from '@ngx-translate/core';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { ActivityDetailsComponent } from 'src/components/pages/activity-details/activity-details.component';
import { ActivityControlsComponent } from 'src/components/pages/activity-details/activity-controls/activity-controls.component';
import { TemplateHtmlDialogComponent } from 'src/components/dialogs/template-html-dialog/template-html-dialog.component';
import { DoenkidsTemplateProvider } from 'src/providers/doenkids-template.provider';
import { TagInputModule } from 'ngx-chips';
import { SectionComponent } from 'src/components/shared/section/section.component';
import { ActivityMaterialSectionComponent } from 'src/components/shared/sections/activity-material-section/activity-material-section.component';
import { ActivityTipsSectionComponent } from 'src/components/shared/sections/activity-tips-section/activity-tips-section.component';
import { ActivityVariationsSectionComponent } from 'src/components/shared/sections/activity-variations-section/activity-variations-section.component';
import { ActivityWhatSectionComponent } from 'src/components/shared/sections/activity-what-section/activity-what-section.component';
import { GlossarySectionComponent } from 'src/components/shared/sections/glossary-section/glossary-section.component';
import { ImageSectionComponent } from 'src/components/shared/sections/image-section/image-section.component';
import { RequiredMaterialSectionComponent } from 'src/components/shared/sections/required-material-section/required-material-section.component';
import { SourcesSectionComponent } from 'src/components/shared/sections/sources-section/sources-section.component';
import { YoutubeSectionComponent } from 'src/components/shared/sections/youtube-section/youtube-section.component';
import { ImageUploadComponent } from 'src/components/shared/asset-upload/asset-upload.component';
import { ChoiceDialogComponent } from 'src/components/dialogs/choice-dialog/choice-dialog.component';
import { NgxUploaderModule } from '@angular-ex/uploader';
import { QuillModule } from 'ngx-quill';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { PanelContainerComponent } from 'src/components/layout/panel-container/panel-container.component';
import { DragHandleComponent } from 'src/components/shared/drag-handle/drag-handle.component';
import { SelectMediaComponent } from 'src/components/shared/select-media/select-media.component';
import { ImageSearchDialogComponent } from 'src/components/dialogs/image-search-result/image-search-result.component';
import { UploadProgressBarComponent } from 'src/components/shared/upload-progress-bar/upload-progress-bar.component';
import { DoenKidsCalendarComponent } from 'src/components/pages/calendar/calendar.component';
import { ActivityPreviewComponent } from 'src/components/pages/activity-preview/activity-preview.component';
import { ProgramDetailsComponent } from 'src/components/pages/program-details/program-details.component';
import { ProgramPeriodSectionsComponent } from 'src/components/pages/program-details/program-period-sections/program-period-sections.component';
import { DownloadProgramPdfDialogComponent } from 'src/components/dialogs/download-program-pdf-dialog/download-program-pdf-dialog.component';
import { ProgramSymbolsDialogComponent } from 'src/components/dialogs/program-symbols-dialog/program-symbols-dialog.component';
import { CreateProgramTemplateDialogComponent } from 'src/components/dialogs/create-program-template-dialog/create-program-template-dialog.component';
import { CopyProgramDialogComponent } from 'src/components/dialogs/copy-program-dialog/copy-program-dialog.component';
import { ProgramPeriodComponent } from 'src/components/pages/program-details/program-period/program-period.component';
import { PeriodActivitySectionComponent } from 'src/components/shared/section/period-activity-section/period-activity-section.component';
import { UserListComponent } from 'src/components/pages/user-list/user-list.components';
import { BaseActivitySearchComponent } from 'src/components/pages/base-activity-search/base-activity-search.component';
import { PeriodActivitySectionDialogComponent } from 'src/components/dialogs/period-activity-section-dialog/period-activity-section-dialog.component';
import { SettingsOverviewComponent } from 'src/components/pages/settings-overview/settings-overview.component';
import { ProgramTemplatesComponent } from 'src/components/pages/program-templates/program-templates.component';
import { UserSearchComponent } from 'src/components/pages/user-search/user-search.component';
import { UserFormComponent } from 'src/components/layout/user-form/user-form.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { EmptyPageMessageComponent } from 'src/components/shared/empty-page-message/empty-page-message.component';
import { CreateProgramTemplateBundleDialogComponent } from 'src/components/dialogs/create-program-bundle-dialog/create-program-bundle-dialog.component';
import { ProgramTemplatePreviewComponent } from 'src/components/pages/program-template-preview/program-template-preview.component';
import { ProgramTemplateBundlePreviewComponent } from 'src/components/pages/program-template-bundle-preview/program-template-bundle-preview.component';
import { OrganizationUnitDetailsComponent } from 'src/components/pages/organization-unit-details/organization-unit-details.component';
import { UserDetailsComponent } from 'src/components/pages/user-details/user-details.component';
import { ProgramCategoryListComponent } from 'src/components/pages/settings-overview/program-category-list/program-category-list.component';

import { OrganizationUnitTableComponent } from 'src/components/pages/organization-unit-details/organization-unit-table/organization-unit-table.component';
import { TemplateSettingsComponent } from 'src/components/pages/organization-unit-details/template-settings/template-settings.component';
import { TemplateSettingOptionsComponent } from 'src/components/pages/organization-unit-details/template-settings/template-setting-options/template-setting-options.component';

import { TranslationsComponent } from 'src/components/pages/organization-unit-details/translations/translations.component';
import { AddOrganizationDialogComponent } from 'src/components/dialogs/add-organization-dialog/add-organization-dialog.component';
import { DoenkidsSpeedDialComponent } from 'src/components/layout/doenkids-speed-dial/doenkids-speed-dial.component';
import { CustomerSymbolsComponent } from 'src/components/pages/organization-unit-details/customer-symbols/customer-symbols.component';
import { AddOrganizationCustomerSymbolDialogComponent } from 'src/components/dialogs/add-organization-customer-symbol-dialog/add-organization-customer-symbol-dialog.component';
import { MigratePeriodSectionsDialogComponent } from 'src/components/dialogs/migrate-period-sections-dialog/migrate-period-sections-dialog.component';
import { KonnectFormComponent } from 'src/components/pages/organization-unit-details/konnect-form/konnect-form.component';
import { ConnectingAnimationComponent } from 'src/components/pages/organization-unit-details/konnect-form/connecting-animation/connecting-animation.component';
import { ProgramTemplateStatusComponent } from 'src/components/dialogs/program-template-status/program-template-status.component';
import { AddExistingUserDialogComponent } from 'src/components/dialogs/add-existing-user-dialog/add-existing-user-dialog.component';
import { OrganizationUsersComponent } from 'src/components/pages/organization-unit-details/organization-users/organization-users.component';
import { ConnectKonnectOUDialogComponent } from 'src/components/dialogs/connect-konnect-ou-dialog/connect-konnect-ou-dialog.component';
import { BaseActivityPreviewComponent } from 'src/components/pages/base-activity-preview/base-activity-preview.component';
import { BrowserNotSupportedComponent } from 'src/components/browser-not-supported/browser-not-supported.component';
import { PublishActivityDialogComponent } from 'src/components/dialogs/publish-activity-dialog/publish-activity-dialog.component';
import { ReviewActivityDialogComponent } from 'src/components/dialogs/review-activity-dialog/review-activity-dialog.component';
import { ConnectJibbieOuDialogComponent } from 'src/components/dialogs/connect-jibbie-ou-dialog/connect-jibbie-ou-dialog.component';
import { InfoDialogComponent } from 'src/components/dialogs/info-dialog/info-dialog.component';
import { CreateProgramBookletComponent } from 'src/components/dialogs/create-program-booklet/create-program-booklet.component';
import { ApiKeyDialogComponent } from 'src/components/dialogs/api-key-dialog/api-key-dialog.component';
import { DownloadActivityPdfDialogComponent } from 'src/components/dialogs/download-activity-pdf-dialog/download-activity-pdf-dialog.component';
import { MigratePeriodSectionsToOtherProgramDialogComponent } from 'src/components/dialogs/migrate-period-sections-to-other-program-dialog/migrate-period-sections-to-other-program-dialog.component';
import { ActiveUsersComponent } from 'src/components/pages/active-users/active-users.component';
import { UserActivityComponent } from 'src/components/pages/user-activity/user-activity.component';
import { SmallstepsPreparationComponent } from 'src/components/shared/sections/smallsteps-preparation/smallsteps-preparation.component';
import { SmallstepsIntroductionComponent } from 'src/components/shared/sections/smallsteps-introduction/smallsteps-introduction.component';
import { SmallstepsInstructionComponent } from 'src/components/shared/sections/smallsteps-instruction/smallsteps-instruction.component';
import { SmallstepsConclusionComponent } from 'src/components/shared/sections/smallsteps-conclusion/smallsteps-conclusion.component';
import { ProgramEventLogComponent } from 'src/components/pages/program-event-log/program-event-log.component';
import { ActivityEventLogComponent } from 'src/components/pages/activity-event-log/activity-event-log.component';
import { ProgramTemplateReorderComponent } from 'src/components/pages/program-template-reorder/program-template-reorder.component';
import { ProgramTemplateAttachmentDialogComponent } from 'src/components/dialogs/program-template-attachment-dialog/program-template-attachment-dialog.component';
import { DoenkidsFileDownloader } from 'src/providers/download-files.provider';
import { DownloadTrayComponent } from 'src/components/shared/download-tray/download-tray.component';
import { getSaver, SAVER } from 'src/providers/file-save.provider';
import { DownloadTrayItemComponent } from 'src/components/shared/download-tray-item/download-tray-item.component';
import { DateAdapter, MAT_DATE_LOCALE, NativeDateAdapter } from '@angular/material/core';
import { MediaTagsComponent } from 'src/components/pages/media-tags/media-tags.component';
import { AddMediaTagsDialogComponent } from 'src/components/dialogs/add-media-tags-dialog/add-media-tags-dialog.component';
import { ArchiveMediaItemDialogComponent } from 'src/components/dialogs/archive-media-item-dialog/archive-media-item-dialog.component';
import { ProgramTemplateTagDialogComponent } from 'src/components/dialogs/program-template-tag-dialog/program-template-tag-dialog.component';
import { PinbordComponent } from 'src/components/pages/pinbord/pinbord.component';
import { AddActivityToPinbordFolderDialogComponent } from 'src/components/dialogs/add-activity-to-pinbord-folder-dialog/add-activity-to-pinbord-folder-dialog.component';
import { AddNewPinbordFolderDialogComponent } from 'src/components/dialogs/add-new-pinbord-folder-dialog/add-new-pinbord-folder-dialog.component';
import { PinbordFolderDetailsComponent } from 'src/components/pages/pinbord-folder-details/pinbord-folder-details.component';
import { PinbordFolderComponent } from 'src/components/pages/pinbord/pinbord-folder/pinbord-folder.component';
import { RemoveActivityFromPinbordFolderDialogComponent } from 'src/components/dialogs/remove-activity-from-pinbord-folder-dialog/remove-activity-from-pinbord-folder-dialog.component';
import { ActivityOverviewComponent } from 'src/components/pages/activity-overview/activity-overview.component';
import { UpdateOuLrkIdentifierDialog } from 'src/components/dialogs/update-ou-lrk-identifier-dialog/update-ou-lrk-identifier-dialog.component';
import { ReviewOverviewComponent } from 'src/components/shared/reviews/review-overview/review-overview.component';
import { ReviewInputComponent } from 'src/components/shared/reviews/review-input/review-input.component';
import { ReviewComponent } from 'src/components/shared/reviews/review-overview/review/review.component';
import { ActivityReviewOverviewComponent } from 'src/components/pages/activity-review/activity-review-overview/activity-review-overview.component';
import { ActivityReviewComponent } from 'src/components/pages/activity-review/activity-review.component';
import { IntersectionDirective } from 'src/directives/appear.directive';
import { CardListItemComponent } from 'src/components/shared/card-list/card-list-item/card-list-item.component';
import { OwnerChipComponent } from 'src/components/owner-chip/owner-chip.component';
import { TruncateArrayPipe } from 'src/pipes/truncate_array.pipe';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { BaseNewsItemsComponent } from '../components/pages/base-news-items/base-news-items.component';
import { NewsfeedComponent } from '../components/shared/newsfeed/newsfeed.component';
import { OrganizationUnitManageNewsfeedComponent } from '../components/pages/organization-unit-details/organization-unit-manage-newsfeed/organization-unit-manage-newsfeed.component';
import { NewsItemViewComponent } from '../components/shared/news-item-view/news-item-view.component';
import { HtmlToPlaintextPipe } from '../pipes/html-to-plaintext.pipe';
import { SharedModule } from './shared.module';
import { SentryErrorHandler } from './app-sentry-config';
import { lineBreakMatcher, shiftEnterBinding } from '../assets/quill/shift-enter-binding';
import { AppRoutingModule } from './app-routing.module';
import { InsightsProgramStatusProgramListComponent } from '../components/pages/insights-program-status-program-list/insights-program-status-program-list.component';
import { AppComponent } from './app.component';
import { ReviewApprovalComponent } from '../components/pages/review-approval/review-approval.component';
import { CardListComponent } from '../components/shared/card-list/card-list.component';
import { ReviewActivityDescriptionComponent } from '../components/shared/reviews/review-activity-description/review-activity-description.component';
import { ReviewPreviewComponent } from '../components/pages/review-approval/review-preview/review-preview.component';
import 'web-component-essentials';
import { SwiperDirective } from 'src/directives/swiper.directive';
import { FromNowPipe } from '../pipes/from-now.pipe';
import { LoggingMissingTranslationHandler } from './utils/logging-missing-translation-handler';
import { LocaleIdProvider } from './utils/locale-id-provider';
import { I18nListPipe } from '../pipes/i18n-list-pipe';
import { I18nTitleStrategy } from './utils/i18n-title-strategy';
import { translateServiceInitializer, TranslateService } from './utils/translate.service';
import { LayoutComponent } from '../components/layout/layout/layout.component';
import { LoginComponent } from '../components/pages/login/login.component';
import { UserPreferencesComponent } from 'src/components/pages/settings-overview/user-preferences/user-preferences.component';
import { TagsComponent } from 'src/components/pages/settings-overview/tags/tags.component';
import { SupportProvider } from 'src/providers/support.provider';
import { PdfWriterTemplateSettingsComponent } from 'src/components/pages/organization-unit-details/pdf-writer-template-settings/pdf-writer-template-settings.component';
import { ReplaceImageDialogComponentComponent } from 'src/components/dialogs/replace-image-dialog-component/replace-image-dialog-component.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpBackend: HttpBackend) {
  return new MultiTranslateHttpLoader(httpBackend, [
    './assets/i18n/core/',
    './assets/i18n/api/',
  ]);
}

// Disable animations on devices that don't support them
//
const disableAnimations = !('animate' in document.documentElement)
  || (navigator && /iPhone OS (8|9|10|11|12|13)_/.test(navigator.userAgent));

@Injectable({
  providedIn: 'root',
})
export class CustomDateAdapter extends NativeDateAdapter {
  getFirstDayOfWeek(): number {
    return 1;
  }
}

@NgModule({
  declarations: [
    AppComponent,
    ActiveUsersComponent,
    ActivityCardComponent,
    ActivityControlsComponent,
    ActivityDetailsComponent,
    ActivityMaterialSectionComponent,
    ActivityPreviewComponent,
    ActivitySearchBarComponent,
    ActivitySearchComponent,
    ActivityStatusFiltersComponent,
    ActivityTipsSectionComponent,
    ActivityVariationsSectionComponent,
    ActivityWhatSectionComponent,
    ArchiveMediaItemDialogComponent,
    AddExistingUserDialogComponent,
    AddOrganizationCustomerSymbolDialogComponent,
    AddOrganizationDialogComponent,
    BaseActivityPreviewComponent,
    BaseActivitySearchComponent,
    BrowserNotSupportedComponent,
    ChoiceDialogComponent,
    BrowseOrganizationUnitDialogComponent,
    ConfirmationDialogComponent,
    ConnectingAnimationComponent,
    ConnectJibbieOuDialogComponent,
    ConnectKonnectOUDialogComponent,
    CopyProgramDialogComponent,
    CreateProgramBookletComponent,
    CreateProgramTemplateBundleDialogComponent,
    CreateProgramTemplateDialogComponent,
    CustomerSymbolsComponent,
    DoenKidsCalendarComponent,
    DoenkidsSpeedDialComponent,
    DownloadProgramPdfDialogComponent,
    DownloadActivityPdfDialogComponent,
    DownloadTrayComponent,
    DownloadTrayItemComponent,
    DragHandleComponent,
    EmptyPageMessageComponent,
    GlossarySectionComponent,
    HighlightPipe,
    HomeComponent,
    ImageSearchDialogComponent,
    ImageSectionComponent,
    ImageUploadComponent,
    InfoDialogComponent,
    KonnectFormComponent,
    LayoutComponent,
    LoginComponent,
    MigratePeriodSectionsDialogComponent,
    NgxMasonryComponent,
    NgxMasonryDirective,
    OrganizationUnitDetailsComponent,
    OrganizationUnitSelectorComponent,
    OrganizationUnitTableComponent,
    OrganizationUsersComponent,
    PanelContainerComponent,
    PeriodActivitySectionComponent,
    PeriodActivitySectionDialogComponent,
    ProfilePanelComponent,
    ProgramCategoryListComponent,
    ProgramDetailsComponent,
    ProgramPeriodComponent,
    ProgramPeriodSectionsComponent,
    ProgramSymbolsDialogComponent,
    ProgramTemplateBundlePreviewComponent,
    ProgramTemplatePreviewComponent,
    ProgramTemplatesComponent,
    ProgramTemplateStatusComponent,
    PublishActivityDialogComponent,
    RequiredMaterialSectionComponent,
    ResponsiveToolbarContentComponent,
    ReviewActivityDialogComponent,
    SafePipe,
    ScrollEndDirective,
    SearchAggregationsComponent,
    SearchChipsComponent,
    SectionComponent,
    SelectedActivitiesComponent,
    SelectMediaComponent,
    SettingsOverviewComponent,
    SourcesSectionComponent,
    SplitPageComponent,
    SplitPaneComponent,
    TemplateSettingOptionsComponent,
    TemplateHtmlDialogComponent,
    TemplateSettingsComponent,
    TranslationsComponent,
    TruncatePipe,
    UploadProgressBarComponent,
    UserActivityComponent,
    UserDetailsComponent,
    UserDialogComponent,
    UserFormComponent,
    UserListComponent,
    UserSearchComponent,
    YoutubeSectionComponent,
    ApiKeyDialogComponent,
    MigratePeriodSectionsToOtherProgramDialogComponent,
    SmallstepsPreparationComponent,
    SmallstepsIntroductionComponent,
    SmallstepsInstructionComponent,
    SmallstepsConclusionComponent,
    ActivityEventLogComponent,
    ProgramEventLogComponent,
    ProgramTemplateReorderComponent,
    ProgramTemplateAttachmentDialogComponent,
    MediaTagsComponent,
    AddMediaTagsDialogComponent,
    InsightsProgramStatusProgramListComponent,
    ProgramTemplateTagDialogComponent,
    PinbordComponent,
    AddActivityToPinbordFolderDialogComponent,
    AddNewPinbordFolderDialogComponent,
    PinbordFolderDetailsComponent,
    PinbordFolderComponent,
    RemoveActivityFromPinbordFolderDialogComponent,
    NewsfeedComponent,
    NewsfeedItemComponent,
    NewsItemViewComponent,
    OrganizationUnitManageNewsfeedComponent,
    HtmlToPlaintextPipe,
    FromNowPipe,
    I18nListPipe,
    ActivityOverviewComponent,
    BaseNewsItemsComponent,
    UpdateOuLrkIdentifierDialog,
    ReviewComponent,
    ReviewInputComponent,
    ReviewOverviewComponent,
    ActivityReviewComponent,
    ActivityReviewOverviewComponent,
    IntersectionDirective,
    ReviewApprovalComponent,
    CardListComponent,
    CardListItemComponent,
    ReviewActivityDescriptionComponent,
    ReviewPreviewComponent,
    OwnerChipComponent,
    TruncateArrayPipe,
    SwiperDirective,
    UserPreferencesComponent,
    TagsComponent,
    PdfWriterTemplateSettingsComponent,
    ReplaceImageDialogComponentComponent,
  ],
  imports: [
    SharedModule,
    BrowserAnimationsModule.withConfig({ disableAnimations }),
    BrowserModule,
    ColorPickerModule,
    CommonModule,
    DragDropModule,
    HttpClientModule,
    NgxUploaderModule,
    PdfJsViewerModule,
    TagInputModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend],
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: LoggingMissingTranslationHandler,
      },
    }),
    QuillModule.forRoot({
      modules: {
        keyboard: {
          bindings: {
            linebreak: shiftEnterBinding,
          },
        },
        clipboard: {
          matchers: [
            ['br', lineBreakMatcher],
            ['BR', lineBreakMatcher],
          ],
        },
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'], // toggled buttons
          [{ header: 1 }, { header: 2 }], // custom button values
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          [{ font: [] }],
          [{ align: [] }],
          ['link'],
          ['clean'], // remove formatting button
        ],
      },
    }),
    MatSlideToggleModule,
  ],
  providers: [
    ScreenSizeProvider,
    DoenKidsGenericApiProvider,
    DoenKidsAuth0Provider,
    DoenkidsAssetProvider,
    DoenkidsSessionProvider,
    ActivityAggregationsProvider,
    ActivitySelectionProvider,
    BreakpointsProvider,
    PermissionProvider,
    DoenkidsTemplateProvider,
    DoenkidsFileDownloader,
    SupportProvider,
    { provide: SAVER, useFactory: getSaver },
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    {
      provide: LOCALE_ID,
      deps: [NgxTranslateService],
      useClass: LocaleIdProvider,
    },
    {
      provide: MAT_DATE_LOCALE,
      deps: [NgxTranslateService],
      useFactory: (translateService) => translateService.currentLang,
    },
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: 'googleTagManagerId', useValue: environment.production ? 'GTM-NTKWBFX' : 'GTM-5FFWKZ3' },
    { provide: TitleStrategy, useExisting: I18nTitleStrategy },
    {
      provide: APP_INITIALIZER,
      useFactory: translateServiceInitializer,
      deps: [TranslateService],
      multi: true,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
