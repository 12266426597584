/**
 * I don't want the spread number in the code that have no meaning
 * to other developers. That's why I buid this class
 */
export class DoenkidsStaticValuesHelper {
  static ORGANIZATION_UNIT_TYPE_NODE = 1;

  static ORGANIZATION_UNIT_TYPE_CUSTOMER = 2;

  static ORGANIZATION_UNIT_TYPE_LOCATION = 3;

  static ORGANIZATION_UNIT_TYPE_GROUP = 4;

  static DOENKIDS_IDENTIFIER = 1;

  static ACTIVITY_READER_ROLE = 1;

  static ACTIVITY_EDITOR_ROLE = 2;

  static ACTIVITY_OWNER_ROLE = 3;

  static ACTIVITY_REVOKED_ROLE = 4;

  static PROGRAM_READER_ROLE = 1;

  static PROGRAM_EDITOR_ROLE = 2;

  static PROGRAM_OWNER_ROLE = 3;

  static PROGRAM_REVOKED_ROLE = 4;

  static ACTIVITY_STATUS_CONCEPT = 1;

  static ACTIVITY_STATUS_REVIEW = 2;

  static ACTIVITY_STATUS_PUBLISHED = 3;

  static ACTIVITY_STATUS_UNPUBLISHED = 4;

}
