import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { ITemplateOptions } from 'typings/api-publish';

@Injectable({ providedIn: 'root' })
export class OrganizationUnitCustomerTemplateService {
  constructor(private $baseApi: DoenKidsGenericApiProvider) {}

  update(organizationUnitId: number, templateId: number, templateSettings: ITemplateOptions) {
    return firstValueFrom(this.$baseApi.genericPutCall(`/customer/organization-unit/${organizationUnitId}/template/${templateId}/settings`, templateSettings));
  }

  archive(organizationUnitId: number, templateId: number) {
    return firstValueFrom(this.$baseApi.genericDeleteCall(`/customer/organization-unit/${organizationUnitId}/template/${templateId}`));
  }
}
