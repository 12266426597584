<form [formGroup]="form" (ngSubmit)="saveSettings(form.value)">
  <div class="wrapper">
    <div class="title-and-save-button-container">
      <h2>{{ 'template_settings.title' | translate }}</h2>
      <button mat-button class="save-button" type="submit" [disabled]="!form.dirty">
        <mat-icon>save</mat-icon>
        {{ 'generic.save_changes' | translate }}
      </button>
    </div>
    <p>{{ 'template_settings.description' | translate: { activityTypes: undefined } }}</p>
  </div>
  <div>
    <app-empty-page-message
      *ngIf="!customProgramTemplateId && !customActivityTemplateId"
      icon="insert_drive_file"
      [message]="'template_settings.no_settings' | translate"
    ></app-empty-page-message>
    <mat-accordion>
      <ng-container *ngFor="let control of getGroupControls() | keyvalue; first as isFirst">
        <app-template-setting-options [template]="control.key" [isFirst]="isFirst" [formControl]="control.value"></app-template-setting-options>
      </ng-container>
    </mat-accordion>
  </div>
</form>
