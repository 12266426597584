/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  Component, ExistingProvider, forwardRef, HostListener, Input, ViewEncapsulation,
} from '@angular/core';
import { UntypedFormBuilder, NG_VALUE_ACCESSOR, AbstractControl, ControlValueAccessor, FormGroup } from '@angular/forms';
import { firstValueFrom, map, noop, Observable, Subject, takeUntil } from 'rxjs';
import { ITemplateOptions, ITemplateOptionSettings } from 'typings/api-publish';
import { DoenkidsAssetProvider } from 'src/providers/assets.provider';
import { IUploadResponse } from 'typings/custom-app-types';
import { IMediaItem } from 'typings/section-types';
import { TranslateService } from 'src/app/utils/translate.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';

export const TEMPLATE_SETTING_OPTIONS_CONTROL_VALUE_ACCESSOR: ExistingProvider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TemplateSettingOptionsComponent),
  multi: true,
};

interface ITemplateForm {
  label: string;
  control: FormGroup;
}

@Component({
  selector: 'app-template-setting-options',
  templateUrl: './template-setting-options.component.html',
  styleUrls: ['./template-setting-options.component.scss'],
  providers: [TEMPLATE_SETTING_OPTIONS_CONTROL_VALUE_ACCESSOR],
  encapsulation: ViewEncapsulation.None,
})
export class TemplateSettingOptionsComponent implements ControlValueAccessor {
  private stop$ = new Subject<void>();

  private valueChange$ = new Subject<void>();

  /** Value we will call whenever our form is touched */
  private onTouchedCallback: Function = noop;

  private onChange: Function = noop;

  @Input() template: string;

  @Input() isFirst: boolean = false;

  public headingFontFileName;

  public bodyFontFileName;

  public forms: ITemplateForm[];

  private childActivityTypes$: Observable<string[]>;

  @HostListener('blur') onBlur() {
    this.onTouchedCallback();
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private assetService: DoenkidsAssetProvider,
    private $translate: TranslateService,
    private $session: DoenkidsSessionProvider,
  ) {
    this.forms = [];

    this.childActivityTypes$ = this.$session.getOrganizationUnit$.pipe(
      takeUntil(this.stop$),
      map((organizationUnit) => organizationUnit.child_activity_types),
    );
  }

  ngOnDestroy() {
    this.stop$.next();
  }

  async writeValue(value: ITemplateOptions) {
    if (value) {
      this.valueChange$.next();
      const allControl = await this.createActivityTypeForm(value);
      const controls = [
        { label: 'all', control: allControl },
      ];

      const childActivityTypes =  await firstValueFrom(this.childActivityTypes$);

      for (const childActivityType of childActivityTypes) {
        const controlGroup = await this.createActivityTypeForm(value[childActivityType]);
        controls.push({ label: childActivityType, control: controlGroup });
      }

      this.forms = controls;
    }
  }

  registerOnChange(fn: any): void {
    // this.form.valueChanges.subscribe(fn);
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      for (const formEntry of this.forms) {
        formEntry.control.disable();
      }
    } else {
      for (const formEntry of this.forms) {
        formEntry.control.enable();
      }
    }
  }

  async createActivityTypeForm(value?: ITemplateOptionSettings) {
    let headingFontFileName: string;

    if (value?.headingFontUuid) {
      const response = await this.assetService.fetchByUuid(value.headingFontUuid);
      headingFontFileName = response.filename;
    }

    let bodyFontFileName: string;
    if (value?.bodyFontUuid) {
      const response = await this.assetService.fetchByUuid(value.bodyFontUuid);
      bodyFontFileName = response.filename;
    }

    const newGroup = this.formBuilder.group({
      headingFontFileName: [headingFontFileName ?? ''],
      headingFontUuid: [value?.headingFontUuid ?? ''],
      bodyFontFileName: [bodyFontFileName ?? ''],
      bodyFontUuid: [value?.bodyFontUuid ?? ''],
      bodyBackgroundColor: [value?.bodyBackgroundColor ?? ''],
      headingTextColor: [value?.headingTextColor ?? ''],
      subHeadingTextColor: [value?.subHeadingTextColor ?? ''],
      bodyTextColor: [value?.bodyTextColor ?? ''],
      headerImageUuid: [value?.headerImageUuid ?? ''],
      footerImageUuid: [value?.footerImageUuid ?? ''],
    });

    newGroup.valueChanges.pipe(
      takeUntil(this.stop$),
      takeUntil(this.valueChange$),
    ).subscribe(() => {
      this.triggerOnChange();
    });

    return newGroup;
  }

  selectFont(group: FormGroup, item: IUploadResponse | IMediaItem, formControlName: 'bodyFontUuid' | 'headingFontUuid') {
    if (item && item.uuid) {
      group.get(formControlName).setValue(item.uuid);
      if (formControlName === 'bodyFontUuid') {
        group.get('bodyFontFileName').setValue((item as IUploadResponse)?.filename ?? (item as IMediaItem).description);
      } else {
        group.get('headingFontFileName').setValue((item as IUploadResponse)?.filename ?? (item as IMediaItem).description);
      }
    } else {
      group.get(formControlName).setValue(null);
      if (formControlName === 'bodyFontUuid') {
        group.get('bodyFontFileName').setValue('');
      } else {
        group.get('headingFontFileName').setValue('');
      }
    }
  }

  selectImage($event: { uuid: string }, formControl: AbstractControl) {
    if ($event && $event.uuid) {
      formControl.setValue($event.uuid);
    } else {
      formControl.setValue(null);
    }
  }

  getPanelDescription(templateForm: ITemplateForm) {
    if (templateForm.label === 'all') {
      return this.$translate.instant(_('template_settings.applied_to.all'));
    }

    return `${this.$translate.instant(_('template_settings.applied_to'))} ${templateForm.label.toUpperCase()}`;
  }

  triggerOnChange() {
    const allValues = this.forms.find((form) => form.label === 'all');

    const otherValues = this.forms.filter((form) => form.label !== 'all');

    const mappedOtherValues = otherValues.map((form) => {
      const tmpObj = {};
      tmpObj[form.label] = form.control.value;
      return tmpObj;
    });

    const newOptions: ITemplateOptions = Object.assign({}, {
      ...allValues.control.value,
    }, ...mappedOtherValues);

    this.onChange(newOptions);
  }
}
