/* eslint-disable @typescript-eslint/no-useless-constructor */
import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { ITemplateWithSettings } from 'typings/api-publish';

export interface IOrganizationUnitTemplate {
  id: number;
  templates: ITemplateWithSettings[];
}

export interface ITemplateState extends EntityState<ITemplateWithSettings> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'template' })
export class TemplateStore extends EntityStore<ITemplateState, ITemplateWithSettings> {
  constructor() {
    super({});
  }
}
