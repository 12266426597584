import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { first, get, isEqual, isNil } from 'lodash';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable, Subject } from 'rxjs';
import { debounceTime, filter, map, shareReplay, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { ProgramCategoryListQuery } from 'src/api/activity/program-category-list/program-category-list.query';
import { ProgramCategoryListService } from 'src/api/activity/program-category-list/program-category-list.service';
import { ProgramPeriodListQuery } from 'src/api/activity/program-period-list/program-period-list.query';
import { ProgramPeriodListService } from 'src/api/activity/program-period-list/program-period-list.service';
import { ProgramPeriodSectionQuery } from 'src/api/activity/program-period-section/program-section.query';
import { ProgramPeriodSectionService } from 'src/api/activity/program-period-section/program-section.service';
import { ProgramTagsService } from 'src/api/activity/program-tags/program-tags.service';
import { ProgramTemplateQuery } from 'src/api/activity/program-template/program-template.query';
import { ProgramTemplateService } from 'src/api/activity/program-template/program-template.service';
import { IProgramTemplateWithAccesLevel } from 'src/api/activity/program-template/program-template.store';
import { ProgramService } from 'src/api/activity/program/program.service';
import { TagListQuery } from 'src/api/activity/tag-list/tag-list.query';
import { CustomerSymbolService } from 'src/api/customer/customer-symbol/customer-symbol.service';
import { OrganizationUnitProgramTemplateService } from 'src/api/customer/organization-unit-program-template/organization-unit-progam-template.service';
import { ProgramTemplateAttachmentService } from 'src/api/media/program-template-attachment/program-template-attachment.service';
import { ConfirmationDialogComponent } from 'src/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { ProgramTemplateAttachmentDialogComponent } from 'src/components/dialogs/program-template-attachment-dialog/program-template-attachment-dialog.component';
import { DoenkidsAssetProvider } from 'src/providers/assets.provider';
import { BreakpointsProvider } from 'src/providers/breakpoints.provider';
import { PermissionProvider } from 'src/providers/permission.provider';
import { ProgramCreationProvider } from 'src/providers/program-creation.provider';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { IPeriodSectionListResponse, IProgramCategoryWithOrganizationUnit, IProgramTemplateListResponse } from 'typings/api-activity';
import { IUploadResponse } from 'typings/custom-app-types';
import {
  IActivity,
  IActivityType,
  ICustomerSymbol,
  IOrganizationUnitOverview,
  IOrganizationUnitProgramTemplate,
  IOrganizationUnitTagAll,
  IPeriod,
  IPeriodSection,
  IProgramTemplate,
  IProgramTemplateAttachmentMedia,
  IProgramTemplateStatus,
} from 'typings/doenkids/doenkids';
import { IMediaItem } from 'typings/section-types';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { DownloadProgramPdfDialogComponent } from 'src/components/dialogs/download-program-pdf-dialog/download-program-pdf-dialog.component';
import { EProgramPeriodSectionType } from 'src/components/shared/section/section.component';
import { I18nTitleStrategy } from 'src/app/utils/i18n-title-strategy';
import { I18nToastProvider } from 'src/providers/i18n-toast.provider';
import { TranslateService } from 'src/app/utils/translate.service';
import { ActivityService } from 'src/api/activity/activity/activity.service';
import { PdfJsViewerComponent } from 'ng2-pdfjs-viewer';
import { IPublishProgramRequest } from 'typings/api-publish';
import { PublishProgramService } from 'src/api/publish/program/program.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { DoenkidsStaticValuesHelper } from 'src/components/shared/static-values/doenkids-static-values-helper';

const PROGRAM_TEMPLATE_DURATION_LABELS: { [index: string]: string } = {
  week: _('program_template_preview.duration_labels.week'),
  two_weeks: _('program_template_preview.duration_labels.two_weeks'),
  summer_program: _('program_template_preview.duration_labels.summer'),
};

@Component({
  selector: 'app-program-template-preview',
  templateUrl: './program-template-preview.component.html',
  styleUrls: ['./program-template-preview.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProgramTemplatePreviewComponent implements OnInit, OnDestroy {
  private stop$ = new Subject<void>();

  private templateChange$ = new Subject<void>();

  public destroy$ = new Subject<void>();

  public selectedOUId: number;

  public programTemplateForm: UntypedFormGroup;

  public programTemplateId: number;

  public expanded: boolean;

  public periods$: Observable<IPeriod[]>;

  public queryParams = new BehaviorSubject<Params>(null);

  public baseOnly$ = new BehaviorSubject(false);

  public isRevoked$ = new BehaviorSubject(false);

  public bundleId$ = new BehaviorSubject<number>(null);

  public programTemplate$: Observable<IProgramTemplateWithAccesLevel>;

  public programTemplateOwner$: Observable<IOrganizationUnitProgramTemplate>;

  public isTheOwner: boolean;

  public isApproved$ = new BehaviorSubject(false);

  public canBeApproved$: Observable<boolean>;

  public periodSections: { [index: number]: IPeriodSection[] } = [];

  public hasOUWritePermissions$: Observable<boolean>;

  public hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$: Observable<boolean>;

  public hasProgramExplanationPermission$: Observable<boolean>;

  private programTemplateLoading$: Observable<boolean>;

  private periodsLoading$: Observable<boolean>;

  private periodSectionsLoading$: Observable<boolean>;

  private isCopying$ = new BehaviorSubject<boolean>(false);

  public loading$: Observable<boolean>;

  public isAdmin$: Observable<boolean>;

  public isReader$: Observable<boolean>;

  public isRootNode$: Observable<boolean>;

  public isCustomer$: Observable<boolean>;

  public isLocation$: Observable<boolean>;

  public currentOUDetails$: Observable<IOrganizationUnitOverview>;

  public hasWriteAccess$: Observable<boolean>;

  public okoTypes$: Observable<IActivityType[]>;

  protected readonly programTemplateDurationLabels = PROGRAM_TEMPLATE_DURATION_LABELS;

  public isSmall$: Observable<boolean>;

  public programAttachmentList$ = new BehaviorSubject<IProgramTemplateAttachmentMedia[]>([]);

  public programExplanationAttachmentList$ = new BehaviorSubject<IProgramTemplateAttachmentMedia[]>([]);

  public programCategories$: Observable<IProgramCategoryWithOrganizationUnit[]>;

  public canBeForked$: Observable<boolean>;

  private programTemplateTags$: BehaviorSubject<IOrganizationUnitTagAll[]> = new BehaviorSubject<IOrganizationUnitTagAll[]>([]);

  // The tags are loaded from the doenkids session provider on ou load
  //
  public allTags$: Observable<IOrganizationUnitTagAll[]>;

  public defaultTemplateAttachmentLimit = 5;

  public currentTemplateAttachmentLimit = this.defaultTemplateAttachmentLimit;

  public templateAttachmentLimitIncrements = 5;

  public currentOUId$: Observable<number>;

  public hasCustomerSymbolPermission$: Observable<boolean>;

  public fetchedCustomerSymbols$ = new BehaviorSubject<Record<number, ICustomerSymbol>>({});

  protected readonly EProgramPeriodSectionType = EProgramPeriodSectionType;

  public isDoenkidsBeheer$: Observable<boolean>;

  public hasProgramTemplateCreatePermission$: Observable<boolean>;

  public programActivityType$: Observable<IActivityType>;

  public possibleContentLanguages$: Observable<string[]>;

  public contentLanguageControl = new FormControl('');

  private relatedProgramTemplates$: Observable<IProgramTemplateListResponse>;

  private routeOuId$ = new BehaviorSubject<number>(null);

  public approveIsTranslate$: Observable<boolean>;

  @ViewChild('pdfViewer') pdfViewer: PdfJsViewerComponent;

  public pdfLoading$ = new BehaviorSubject<boolean>(false);

  /** The last time a PDF Render was requested */
  private lastPdfRender: number;

  /** Subject to render PDF updates
   */
  public pdf$: BehaviorSubject<string | Blob> = new BehaviorSubject<string>('');

  constructor(
    fb: UntypedFormBuilder,
    private $breakpoint: BreakpointsProvider,
    private $permissions: PermissionProvider,
    private $programCreate: ProgramCreationProvider,
    private $session: DoenkidsSessionProvider,
    private assetService: DoenkidsAssetProvider,
    private programTemplateAttachmentService: ProgramTemplateAttachmentService,
    private programPeriodSectionQuery: ProgramPeriodSectionQuery,
    private programPeriodSectionService: ProgramPeriodSectionService,
    private programPeriodService: ProgramPeriodListService,
    private programTemplatePeriodQuery: ProgramPeriodListQuery,
    private programPeriodListService: ProgramPeriodListService,
    private programTemplateQuery: ProgramTemplateQuery,
    private programTemplateService: ProgramTemplateService,
    private programCategoryQuery: ProgramCategoryListQuery,
    private programCategoryService: ProgramCategoryListService,
    private programService: ProgramService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private matDialog: MatDialog,
    private tagListQuery: TagListQuery,
    private programTagService: ProgramTagsService,
    private organizationUnitProgramTemplateService: OrganizationUnitProgramTemplateService,
    private $customerSymbol: CustomerSymbolService,
    private $translateService: TranslateService,
    private $i18nTitleStrategy: I18nTitleStrategy,
    private $i18nToastProvider: I18nToastProvider,
    private activityService: ActivityService,
    private publishProgramService: PublishProgramService,
  ) {
    this.hasOUWritePermissions$ = this.$permissions.hasOUWritePermissions$.pipe(takeUntil(this.stop$));
    this.hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$ = this.$permissions.hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$.pipe(
      takeUntil(this.stop$),
    );
    this.hasProgramExplanationPermission$ = this.$permissions.hasProgramExplanationPermission$.pipe(takeUntil(this.stop$));
    this.programTemplateLoading$ = this.programTemplateQuery.selectLoading().pipe(takeUntil(this.stop$));
    this.periodsLoading$ = this.programTemplatePeriodQuery.selectLoading().pipe(takeUntil(this.stop$));
    this.periodSectionsLoading$ = this.programPeriodSectionQuery.selectLoading().pipe(takeUntil(this.stop$));
    this.isAdmin$ = this.$session.isAdmin$.pipe(takeUntil(this.stop$));
    this.isReader$ = this.$session.isReader$.pipe(takeUntil(this.stop$));
    this.isRootNode$ = this.$session.isCurrentOrganizationUnitARootNode$.pipe(takeUntil(this.stop$));
    this.isCustomer$ = this.$session.isCurrentOrganizationUnitIsOfTypeCustomer$.pipe(takeUntil(this.stop$));
    this.isLocation$ = this.$session.isCurrentOrganizationUnitIsOfTypeLocation$.pipe(takeUntil(this.stop$));
    this.currentOUDetails$ = this.$session.getOrganizationUnit$.pipe(takeUntil(this.stop$));
    this.okoTypes$ = this.$session.availableActivityTypes$.pipe(takeUntil(this.stop$));
    this.isSmall$ = this.$breakpoint.isSmall$.pipe(takeUntil(this.stop$));
    this.programCategories$ = this.programCategoryQuery.selectAll().pipe(takeUntil(this.stop$));
    this.allTags$ = this.tagListQuery.selectAll().pipe(takeUntil(this.stop$));
    this.hasCustomerSymbolPermission$ = this.$permissions.hasCustomerSymbolsPermission$.pipe(takeUntil(this.stop$));
    this.isDoenkidsBeheer$ = this.$permissions.isDoenkidsManagement$.asObservable().pipe(takeUntil(this.stop$));
    this.hasProgramTemplateCreatePermission$ = this.$permissions.hasProgramTemplateCreatePermissionInOrganizationTree$
      .asObservable()
      .pipe(takeUntil(this.stop$));

    this.programTemplateForm = fb.group({
      name: ['', Validators.required],
      coverImage: ['', Validators.required],
      content: [''],
      category: [0, Validators.required],
      typeOko: [0, Validators.required],
      duration: ['', Validators.required],
      tags: [[]],
    });

    this.periods$ = this.programTemplatePeriodQuery.selectAll().pipe(takeUntil(this.stop$));

    this.programTemplate$ = this.programTemplateQuery.select().pipe(
      takeUntil(this.stop$),
      filter((value) => !isNil(value) && value.id === this.programTemplateId),
    );

    this.programTemplateOwner$ = this.programTemplate$.pipe(
      takeUntil(this.stop$),
      switchMap((programTemplate) => this.programTemplateService.fetchOwner(programTemplate.id)),
      map((owners) => {
        const ownerRoles = owners.filter((owner) => owner.organization_unit_role_id === DoenkidsStaticValuesHelper.PROGRAM_OWNER_ROLE);
        return ownerRoles[0];
      }),
      shareReplay(1),
    );

    this.canBeApproved$ = combineLatest([this.baseOnly$, this.programTemplate$]).pipe(
      takeUntil(this.stop$),
      map(([baseOnly, template]) => baseOnly && !template.replace_program_template_id),
    );

    this.loading$ = combineLatest([this.programTemplateLoading$, this.periodsLoading$, this.periodSectionsLoading$, this.isCopying$]).pipe(
      takeUntil(this.stop$),
      map((loading) => loading.includes(true)),
    );

    this.hasWriteAccess$ = this.programTemplate$.pipe(
      takeUntil(this.stop$),
      map((value) => value.access_level === 'WRITE'),
    );

    this.canBeForked$ = combineLatest([
      this.programTemplate$,
      this.baseOnly$,
      this.hasOUWritePermissions$,
      this.$session.isCurrentOrganizationUnitARootNode$,
    ]).pipe(
      takeUntil(this.stop$),
      map(([template, baseOnly, canWrite, isARootNode]) => ![baseOnly, canWrite, isARootNode].includes(false) && !template.replace_program_template_id),
    );

    this.currentOUId$ = this.$session.currentOuId$.pipe(takeUntil(this.stop$));

    this.activeRoute.queryParams.pipe(takeUntil(this.stop$)).subscribe((query) => {
      this.queryParams.next(query);
      this.baseOnly$.next(query.baseOnly === true || query.baseOnly === 'true');
      this.isRevoked$.next(query.isRevoked === true || query.isRevoked === 'true');
      const bundleId = parseInt(`${query.bundleId}`, 10);
      this.bundleId$.next(bundleId);
      if (!Number.isNaN(bundleId) && this.contentLanguageControl.enabled) {
        this.contentLanguageControl.disable();
      } else if (Number.isNaN(bundleId) && this.contentLanguageControl.disabled) {
        this.contentLanguageControl.enable();
      }
    });

    this.programActivityType$ = combineLatest([
      this.programTemplateForm.get('typeOko').valueChanges.pipe(startWith(this.programTemplateForm.get('typeOko').value)),
      this.okoTypes$,
      this.$translateService.onInitialLangAndLangChange$,
    ]).pipe(
      takeUntil(this.stop$),
      map(([currentFormValue, activityTypes]) => {
        const currentValue = activityTypes.find((activityType) => activityType.id === currentFormValue);

        if (currentValue) {
          return currentValue;
        }

        return {
          id: 0,
          name: this.$translateService.instant('activity_type.all'),
        } as IActivityType;
      }),
    );

    this.possibleContentLanguages$ = this.$session.ouLanguages$.pipe(takeUntil(this.stop$));

    this.contentLanguageControl.valueChanges.pipe(takeUntil(this.stop$)).subscribe(async (newLanguage) => {
      const programTemplate = await firstValueFrom(this.programTemplate$);
      const currentLanguage = programTemplate.language;
      const currentOUId = await firstValueFrom(this.currentOUId$);

      if (currentLanguage.toLowerCase() !== newLanguage.toLowerCase()) {
        const relatedTemplates = (await firstValueFrom(this.relatedProgramTemplates$))?.items ?? [];
        const templateWithNewLanguage = relatedTemplates.find(
          (relatedProgramTemplate) =>
            relatedProgramTemplate.language.toLowerCase() === newLanguage.toLowerCase() &&
            relatedProgramTemplate.country_code.toLowerCase() === programTemplate.country_code.toLowerCase(),
        );

        if (templateWithNewLanguage) {
          this.router.navigate([`/organization/${currentOUId}/template/${templateWithNewLanguage.id}`], { replaceUrl: true });
        } else {
          const newProgramTemplate = await this.createLanguageCopyOfProgramTemplate(newLanguage);
          if (newProgramTemplate) {
            await this.router.navigate([`/organization/${currentOUId}/template/${newProgramTemplate.id}`], { replaceUrl: true });
          }
        }
      }
    });

    this.approveIsTranslate$ = combineLatest([this.programTemplate$, this.$session.ouCountryCode$]).pipe(
      takeUntil(this.stop$),
      map(([template, ouCountryCode]) => template?.country_code !== ouCountryCode),
    );
  }

  async ngOnInit() {
    this.activeRoute.params.pipe(takeUntil(this.stop$)).subscribe(async (params) => {
      this.routeOuId$.next(parseInt(`${params.ou}`, 10));
      this.programTemplateId = parseInt(`${params.id}`, 10);
      try {
        await this.programTemplateService.fetch(this.programTemplateId);
      } catch (e) {
        this.router.navigate(['']);
      }
      this.fetchProgramTemplateAttachments();

      if (isNil(this.programTemplateId) || Number.isNaN(this.programTemplateId)) {
        this.$i18nToastProvider.error(_('program.template_preview.incorrect_url'));

        return;
      }
    });

    this.programTemplate$.pipe(takeUntil(this.stop$)).subscribe(async (programTemplate) => {
      if (isNil(programTemplate)) {
        this.$i18nToastProvider.error(_('program.template_preview.load.failed'));

        return;
      }
      this.renderPDF();
      this.templateChange$.next();
      this.contentLanguageControl.setValue(programTemplate.language ?? '');
      this.relatedProgramTemplates$ = this.programTemplateService
        .relatedProgramTemplates(programTemplate.id)
        .pipe(takeUntil(this.stop$), takeUntil(this.templateChange$));

      this.$i18nTitleStrategy.updateTitleParameters({ template: programTemplate.name });

      const programTemplateTags = (await this.programTagService.fetchAll(programTemplate.program_id)) ?? [];

      // Set form values
      //
      this.programTemplateForm.setValue({
        coverImage: programTemplate.media_uuid,
        category: programTemplate.program_category_id,
        content: programTemplate.content,
        duration: programTemplate.intended_duration,
        name: programTemplate.name,
        typeOko: programTemplate.activity_type_id,
        tags: programTemplateTags,
      });

      this.programTemplateTags$.next(programTemplateTags);

      const periods = await this.programPeriodService.fetchAll(programTemplate.program_id, 100, 0, 'order', 'asc');

      if (isNil(periods)) {
        this.$i18nToastProvider.error(_('program.template_preview.fields.load.failed'));

        return;
      }

      const periodSectionPromises = [];

      if (periods.items.length > 0) {
        periods.items.forEach((period) => {
          const { id: periodId } = period;
          const periodSectionPromise = this.programPeriodSectionService.fetchAllByPeriodId(periodId, 100, 0, 'order', 'ASC').then((periodSectionsResult) => {
            this.periodSections[period.id] = isNil(periodSectionsResult) ? [] : periodSectionsResult.items;
            this._fetchCustomerSymbols(periodSectionsResult);
            return periodSectionsResult;
          });
          periodSectionPromises.push(periodSectionPromise);
        });
      } else {
        this.programPeriodSectionService.setLoading(false);
      }

      const results = await Promise.all(periodSectionPromises);

      const failureCount = results.filter((section) => isNil(section)).length;
      if (failureCount > 0) {
        this.$i18nToastProvider.failureSummary('program_periods', failureCount, periods.items.length);
      }
    });

    this.currentOUId$.pipe(debounceTime(300)).subscribe((organizationUnitId) => {
      this.selectedOUId = organizationUnitId;
      if (this.selectedOUId !== this.routeOuId$.value) {
        this.router.navigate([`/organization/${this.selectedOUId}/template/${this.programTemplateId}`], {
          replaceUrl: true,
          queryParams: this.queryParams.value,
        });
      }
      this.programCategoryService.fetchAll(this.selectedOUId, {});
      this.fetchApprovalStatus();
    });

    // Render the current PDF.
    //
    this.pdf$
      .pipe(
        filter((value) => !isNil(value) && value !== ''),
        takeUntil(this.stop$),
      )
      .subscribe((pdf) => {
        if (this.pdfViewer) {
          if (typeof pdf === 'string') {
            this.pdfViewer.pdfSrc = encodeURIComponent(pdf);
          } else {
            this.pdfViewer.pdfSrc = pdf;
          }
          this.pdfViewer.refresh();
        }
      });
  }

  ngOnDestroy() {
    this.stop$.next();
  }

  async fetchProgramTemplateAttachments() {
    const attachments = await this.programTemplateAttachmentService.fetchAttachmentsWithMedia({ programTemplateId: this.programTemplateId });
    const nonExplanationAttachments = [];
    const explanationAttachments = [];
    for (const attachment of attachments) {
      // eslint-disable-next-line no-await-in-loop
      const attachmentMedia = (await this.assetService.fetch(attachment.media_id))?.data;

      if (attachmentMedia?.purpose === 'program-explanation') {
        explanationAttachments.push(attachment);
      } else {
        nonExplanationAttachments.push(attachment);
      }
    }

    this.programAttachmentList$.next(nonExplanationAttachments);
    this.programExplanationAttachmentList$.next(explanationAttachments);
  }

  async fetchApprovalStatus() {
    try {
      const result = await this.programTemplateService.approvalFetch(this.selectedOUId, this.programTemplateId);
      this.isApproved$.next(!isNil(result?.id) && result.organization_unit_role_id !== 4);
    } catch (error) {
      this.isApproved$.next(false);
    }
  }

  /**
   * Fetch the customer symbol to be used in the period activities
   */
  private async _fetchCustomerSymbols(periodSectionList: IPeriodSectionListResponse) {
    const fetchedSymbols: Record<number, ICustomerSymbol> = this.fetchedCustomerSymbols$.value;
    for (const periodSection of periodSectionList?.items ?? []) {
      if (periodSection.data?.customer_symbol?.length > 0) {
        for (const customerSymbolId of periodSection.data.customer_symbol) {
          if (!fetchedSymbols[customerSymbolId]) {
            // eslint-disable-next-line no-await-in-loop
            const symbol = await this.$customerSymbol.fetch(customerSymbolId);
            fetchedSymbols[customerSymbolId] = symbol.data;
          }
        }
      }
    }

    this.fetchedCustomerSymbols$.next(fetchedSymbols);
  }

  async editProgram() {
    const programTemplate = await firstValueFrom(this.programTemplate$);
    this.router.navigate([`/organization/${this.selectedOUId}/program/${programTemplate.program_id}`]);
  }

  async changePublicationStatus(status: IProgramTemplateStatus) {
    const hasWriteAccess = await firstValueFrom(this.hasWriteAccess$);

    if (hasWriteAccess) {
      const programTemplate = await firstValueFrom(this.programTemplate$);
      const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
        width: '400px',
        minWidth: '320px',
        data: {
          title: this.$translateService.instant(_('program.template_preview.status.change.dialog.title')),
          description: this.$translateService.instant(_('program.template_preview.status.change.dialog.description'), {
            status_name: this.translateStatusName(status.name),
          }),
        },
      });

      dialogRef.afterClosed().subscribe(async (result) => {
        if (result === 'confirm') {
          programTemplate.program_template_status_id = status.id;
          await this.programTemplateService.update(programTemplate);
          this.programTemplateService.fetch(programTemplate.id);
        }
      });
    }
  }

  async removeApproval() {
    const programTemplate = await firstValueFrom(this.programTemplate$);
    const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
      width: '400px',
      minWidth: '320px',
      data: {
        title: this.$translateService.instant(_('program.template_preview.approval.remove.dialog.title')),
        description: this.$translateService.instant(_('program.template_preview.approval.remove.dialog.description'), {
          templateName: programTemplate.name,
        }),
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result === 'confirm') {
        await this.programTemplateService.approvalRemove(this.selectedOUId, programTemplate.id);
        await this.fetchApprovalStatus();
        this.router.navigate([`/organization/${this.selectedOUId}/templates`]);
      }
    });
  }

  async approveTemplate() {
    const programTemplate = await firstValueFrom(this.programTemplate$);
    const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
      width: '400px',
      minWidth: '320px',
      data: {
        title: this.$translateService.instant(_('program.template_preview.approve.dialog.title')),
        description: this.$translateService.instant(_('program.template_preview.approve.dialog.description'), {
          templateName: programTemplate.name,
        }),
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result === 'confirm') {
        await this.programTemplateService.approve(this.selectedOUId, programTemplate.id);
        await this.fetchApprovalStatus();
        this.router.navigate([`/organization/${this.selectedOUId}/template/${programTemplate.id}`]);
      }
    });
  }

  async createProgram() {
    const currentProgramTemplate = await firstValueFrom(this.programTemplate$);
    if (isNil(currentProgramTemplate)) {
      this.$i18nToastProvider.error(_('program.template_preview.create.unavailable'));

      return;
    }

    if (currentProgramTemplate.id !== this.programTemplateId) {
      this.$i18nToastProvider.error(_('program.template_preview.create.not_loaded'));

      return;
    }

    this.$programCreate.promptProgramFromTemplateDetails(currentProgramTemplate);
  }

  async addCoverImage(mediaItem: IUploadResponse | IMediaItem) {
    const currentProgramTemplate = await firstValueFrom(this.programTemplate$);
    const mediaUuid = mediaItem.uuid ? mediaItem.uuid : '';
    const { coverImage } = this.programTemplateForm.value;
    this.programTemplateForm.get('coverImage').setValue(mediaUuid || null);
    const result = await this.programTemplateService.update({ ...currentProgramTemplate, media_uuid: mediaUuid });

    if (isNil(result)) {
      this.$i18nToastProvider.error(_('program.template_preview.cover_image.update.failed'));
      this.programTemplateForm.get('coverImage').setValue(coverImage);
    }
  }

  async save() {
    const currentProgramTemplate = await firstValueFrom(this.programTemplate$);
    const { name, coverImage, content, category, typeOko, duration } = this.programTemplateForm.value;

    await this.saveTags();

    const result = await this.programTemplateService.update({
      ...currentProgramTemplate,
      name,
      content,
      media_uuid: coverImage,
      intended_duration: duration,
      program_category_id: category,
      activity_type_id: typeOko,
    });

    if (isNil(result)) {
      this.$i18nToastProvider.error(_('program.template_preview.update.failed'));
    } else {
      this.$i18nToastProvider.success(_('program.template_preview.update.success'));
    }
  }

  async removeProgramTemplate() {
    try {
      const { programTemplateId } = this;
      await this.programTemplateService.archive(programTemplateId);

      this.router.navigate([`/organization/${this.selectedOUId}/templates`]);
    } catch (e) {
      this.$i18nToastProvider.error(_('program.template_preview.delete.failure'));
    }
  }

  async addAttachment() {
    const programTemplate = await firstValueFrom(this.programTemplate$);

    const dialogRef = this.matDialog.open(ProgramTemplateAttachmentDialogComponent, {
      width: '500px',
      minWidth: '320px',
      data: {
        programTemplateId: programTemplate.id,
        purpose: 'program-attachment',
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      this.fetchProgramTemplateAttachments();
    });
  }

  async addExplanationAttachment() {
    const programTemplate = await firstValueFrom(this.programTemplate$);

    const dialogRef = this.matDialog.open(ProgramTemplateAttachmentDialogComponent, {
      width: '500px',
      minWidth: '320px',
      data: {
        programTemplateId: programTemplate.id,
        purpose: 'program-explanation',
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      this.fetchProgramTemplateAttachments();
    });
  }

  async removeAttachment($event: any, attachment: IProgramTemplateAttachmentMedia) {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }

    const { programTemplateId } = this;

    await this.programTemplateAttachmentService.delete(programTemplateId, attachment.media_id);
    this.fetchProgramTemplateAttachments();
  }

  async forkTemplate(isTranslate: boolean = false) {
    let newTemplate: IProgramTemplate;
    if (isTranslate) {
      const ouLanguages = await firstValueFrom(this.$session.ouLanguages$);
      const currentTemplate = await firstValueFrom(this.programTemplate$);
      let langToChangeTo = currentTemplate.language;
      if (!ouLanguages.includes(currentTemplate.language)) {
        langToChangeTo = first(ouLanguages);
      }
      newTemplate = await this.createLanguageCopyOfProgramTemplate(langToChangeTo);
    } else if (!isTranslate) {
      const currentProgramTemplate = await firstValueFrom(this.programTemplate$);
      const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
        width: '400px',
        minWidth: '320px',
        data: {
          title: this.$translateService.instant(_('generic.confirm')),
          description: this.$translateService.instant(_('program.template_preview.fork.dialog.title'), {
            templateName: currentProgramTemplate.name,
          }),
        },
      });

      const result = await firstValueFrom(dialogRef.afterClosed());

      if (result === 'confirm') {
        newTemplate = await this.programTemplateService.copy(this.selectedOUId, this.programTemplateId);
      }
    }

    if (newTemplate) {
      this.programTemplateService.fetch(newTemplate.id);
      this.programTemplateId = newTemplate.id;
      this.router.navigate([`/organization/${this.selectedOUId}/template/${newTemplate.id}`]);
    }
  }

  async unforkTemplate() {
    const { programTemplateId } = this;
    await this.programTemplateService.archive(programTemplateId);
  }

  async downloadAttachment(attachment: IProgramTemplateAttachmentMedia, event: MouseEvent) {
    // For iOS Safari, we first need to open the window in a *synchronous* function. Async window.open calls do NOT work.
    // https://stackoverflow.com/questions/20696041/window-openurl-blank-not-working-on-imac-safari
    //
    // I removed the window.open calls, and replaced them with html anchor element clicks.
    //
    event.preventDefault();
    event.stopPropagation();
    const a = document.createElement('a');
    this.assetService.getUrl(attachment.uuid, { program_template_id: this.programTemplateId }).then((mediaAssetUrl) => {
      fetch(mediaAssetUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = attachment.filename;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        });
    });
  }

  async previewAttachment(attachment: IProgramTemplateAttachmentMedia) {
    const a = document.createElement('a');
    this.assetService.getUrl(attachment.uuid, { program_template_id: this.programTemplateId }).then((mediaAssetUrl) => {
      a.href = mediaAssetUrl;
      a.target = '_blank';
      a.download = attachment.filename;
      a.click();
    });
  }

  async editAttachment(attachment: IProgramTemplateAttachmentMedia, event: MouseEvent) {
    const programTemplate = await firstValueFrom(this.programTemplate$);
    event.preventDefault();
    event.stopPropagation();

    const dialogRef = this.matDialog.open(ProgramTemplateAttachmentDialogComponent, {
      width: '500px',
      minWidth: '320px',
      data: {
        programTemplateId: programTemplate.id,
        currentAttachment: attachment,
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      this.fetchProgramTemplateAttachments();
    });
  }

  /**
   * Downloads the pdf
   */
  async downloadPDF() {
    const programTemplate = await firstValueFrom(this.programTemplate$);
    const program = await this.programService.fetch(programTemplate.program_id);
    const programTitle = get(program, 'name') ? `"${program.name}"` : this.$translateService.instant(_('program_details.this_program'));

    this.matDialog.open(DownloadProgramPdfDialogComponent, {
      width: '400px',
      minWidth: '320px',
      data: {
        title: this.$translateService.instant(_('generic.download')),
        description: this.$translateService.instant(_('program_details.pdf.download.dialog.description'), { programTitle }),
        programTitle,
        program,
      },
    });
  }

  navigateToActivity(section: IPeriodSection) {
    if (section.name === 'activity') {
      const activityId = section.data.activity_id;
      if (activityId) {
        this.router.navigate([`/activities/preview/${activityId}`]);
      } else {
        this.$i18nToastProvider.error(_('program.template_preview.activity.navigate.failure'));
      }
    }
  }

  private translateStatusName(statusName: string): string {
    switch (statusName.toUpperCase()) {
      case 'CONCEPT':
      case 'REVIEW':
      case 'PUBLISHED':
      case 'UNPUBLISHED':
      case 'TEMPLATE':
        return this.$translateService.instant(`program.status.${statusName.toLowerCase()}`);
      default:
        return statusName;
    }
  }

  loadMoreAttachments() {
    this.currentTemplateAttachmentLimit += this.templateAttachmentLimitIncrements;
  }

  async saveTags() {
    const programTemplate = await firstValueFrom(this.programTemplate$);
    const { tags } = this.programTemplateForm.value;
    const currentTags = this.programTemplateTags$.value;
    const promises = [];

    const addedValues = [];
    const removedValues = [];

    // find the newly added values
    //
    const newlyAddedValues = tags.filter((currentFormValue) => !currentTags.find((currentValue) => isEqual(currentValue.id, currentFormValue.id)));
    // for each newly added value in the form to the addedValues array
    //
    newlyAddedValues.forEach((addedValue) => {
      addedValues.push(addedValue.id);
    });

    // filter out the removed values
    //
    const removedCurrentValues = currentTags.filter((currentValue) => !tags.find((formValue) => isEqual(formValue.id, currentValue.id)));

    // for each removed value call the add it to the removedValues array
    //
    removedCurrentValues.forEach((removedValue) => {
      removedValues.push(removedValue.id);
    });

    addedValues.forEach((addedValue: number) => {
      promises.push(this.programTagService.update(programTemplate.program_id, addedValue));
    });

    removedValues.forEach((removedValue: number) => {
      promises.push(this.programTagService.remove(programTemplate.program_id, removedValue));
    });

    await Promise.all(promises);
  }

  async excludeProgramTemplate() {
    const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
      width: '400px',
      minWidth: '320px',
      data: {
        title: this.$translateService.instant(_('program.template_preview.exclude.dialog.title')),
        description: this.$translateService.instant(_('program.template_preview.exclude.dialog.description')),
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result === 'confirm') {
        const programTemplate = await firstValueFrom(this.programTemplate$);

        // magic number 4 is the value for a REVOKE record
        //
        await firstValueFrom(this.organizationUnitProgramTemplateService.update(this.selectedOUId, programTemplate.id, 4));

        if (this.baseOnly$.value) {
          this.router.navigate([`/organization/${this.selectedOUId}/base/templates`]);
        } else {
          this.router.navigate([`/organization/${this.selectedOUId}/templates`]);
        }
      }
    });
  }

  public handleIsTheOwnerChange(isTheOwner: boolean) {
    if (isTheOwner) {
      this.programTemplateForm.enable();
      if (Number.isNaN(this.bundleId$.value)) {
        this.contentLanguageControl.enable();
      }
    } else {
      this.programTemplateForm.disable();
      this.contentLanguageControl.disable();
    }
  }

  downloadPoster() {
    // For iOS Safari, we first need to open the window in a *synchronous* function. Async window.open calls do NOT work.
    //
    // I removed the window.open calls, and replaced them with html anchor element clicks.
    //
    // eslint-disable-next-line no-undef
    const a = document.createElement('a');
    this._downloadPoster(a);
  }

  private async _downloadPoster(a: HTMLAnchorElement) {
    const currentProgramTemplate = await firstValueFrom(this.programTemplate$);
    const mediaUuid = get(currentProgramTemplate, 'media_uuid');

    if (isNil(currentProgramTemplate) || isNil(mediaUuid)) {
      this.$i18nToastProvider.error(_('program.poster.download.not_available'));
      return;
    }

    const mediaPromise = this.assetService.fetchByUuid(mediaUuid);
    const mediaAssetUrlPromise = this.assetService.getUrl(mediaUuid, { program_template_id: this.programTemplateId });

    const results = await Promise.all([mediaPromise, mediaAssetUrlPromise]);

    const media = results[0];
    const mediaAssetUrl = results[1];

    if (isNil(mediaAssetUrl) || mediaAssetUrl === '') {
      this.$i18nToastProvider.error(_('generic.error.download'), { item: this.$translateService.instant(_('generic.the_poster')) });
      return;
    }

    // Later, we assign the assetUrl to the location we already opened. This can be done asynchronously just fine.
    //
    a.target = '_blank';
    a.href = mediaAssetUrl;
    a.download = get(media, 'filename') ? media.filename : 'poster';
    a.click();
  }

  async createLanguageCopyOfProgramTemplate(language: string) {
    this.isCopying$.next(true);
    const programTemplate = await firstValueFrom(this.programTemplate$);

    const newProgramTemplate = await this.programTemplateService.copy(this.selectedOUId, programTemplate.id, undefined, undefined, language);

    if (programTemplate.language !== language) {
      const periods = (await this.programPeriodListService.fetchAll(newProgramTemplate.program_id, 100, 0, 'order', 'asc', true))?.items ?? [];
      const nonTranslatedActivities: IActivity[] = [];

      for (const period of periods) {
        const periodSections = (await this.programPeriodSectionService.fetchAllByPeriodId(period.id, 50, 0, 'order', 'ASC', true))?.items ?? [];
        const activityPeriodSections = periodSections.filter((periodSection) => periodSection.type_id === EProgramPeriodSectionType.ACTIVITY);

        for (const activityPeriodSection of activityPeriodSections) {
          if (activityPeriodSection.data.activity_id) {
            const currentActivity = await this.activityService.fetch(activityPeriodSection.data.activity_id);
            if (currentActivity && currentActivity.data.language !== newProgramTemplate.language) {
              nonTranslatedActivities.push(currentActivity.data);
            }
          }
        }
      }
      this.isCopying$.next(false);
      if (nonTranslatedActivities.length > 0) {
        const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
          width: '400px',
          minWidth: '320px',
          data: {
            title: this.$translateService.instant(_('program_template.copy.missed_activities.title')),
            description: this.$translateService.instant(_('program_template.copy.missed_activities.description'), {
              activities: nonTranslatedActivities.map((activity) => activity.name ?? '').join(', '),
              activityCount: nonTranslatedActivities.length,
            }),
          },
        });

        await firstValueFrom(dialogRef.afterClosed());
      }
    } else {
      this.isCopying$.next(false);
    }

    return newProgramTemplate;
  }

  titleIsNil(title: string) {
    return isNil(title);
  }

  tabChange(tab: MatTabChangeEvent) {
    if (tab.index === 1) {
      setTimeout(() => this.renderPDF(), 0);
    }
  }

  async renderPDF() {
    this.pdfLoading$.next(true);

    const thisRenderStartedAt = new Date().getTime();
    this.lastPdfRender = thisRenderStartedAt;

    if (this.pdfViewer) {
      this.pdfViewer.pdfSrc = null;
    }

    let programTemplate: IProgramTemplateWithAccesLevel;
    let OUDetails: IOrganizationUnitOverview;

    try {
      programTemplate = await firstValueFrom(this.programTemplate$);
      OUDetails = await firstValueFrom(this.currentOUDetails$);
    } catch (e) {
      // this happens when we already destroyed the component but are still trying to render.
      // if the component is already destroyed we don't wanna render the pdf anymore so we just return
      //
      return;
    }

    let pdf: string | Blob;
    try {
      const programPdfRequest: IPublishProgramRequest = {
        program_id: programTemplate.program_id,
        organization_unit_id: OUDetails.id,
        template_id: 2,
      };

      pdf = await this.publishProgramService.getProgramPdfUrl(programPdfRequest);
    } catch (e) {
      if (thisRenderStartedAt === this.lastPdfRender) {
        this.$i18nToastProvider.error(_('activity.preview.pdf.download.failed'), { name: programTemplate.name });
        this.pdfLoading$.next(false);
      }
    }

    // Only render the last time the PDF was requested to render to prevent 'flickering'
    //
    if (pdf && thisRenderStartedAt === this.lastPdfRender) {
      this.pdf$.next(pdf);
    }
  }

  onPdfLoadedEvent() {
    this.pdfLoading$.next(false);
    this.pdfViewer?.PDFViewerApplication?.preferences?.set('externalLinkTarget', 2);
  }

  getDurationLabels(intendedDuration: string) {
    return this.programTemplateDurationLabels[intendedDuration];
  }

  getPeriodSections(periodId: number) {
    return this.periodSections[periodId];
  }
}
