import { Injectable } from '@angular/core';
import { transaction } from '@datorama/akita';
import { IActivityReview, IActivityReviewScore } from 'typings/doenkids/doenkids';
import { DoenKidsGenericApiProvider } from 'src/providers/generic.provider';
import { IServiceResponse } from 'typings/api-generic';
import { IActivityReviewOverviewResponse } from 'typings/api-activity';
import { Subject, firstValueFrom } from 'rxjs';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { I18nToastProvider } from 'src/providers/i18n-toast.provider';

@Injectable({ providedIn: 'root' })
export class ActivityReviewService {
  public reviewUpdated$ = new Subject();

  constructor(
    private $baseApi: DoenKidsGenericApiProvider,
    private $i18nToastProvider: I18nToastProvider,
  ) { }

  @transaction()
  async create(review: IActivityReview, scores: Partial<IActivityReviewScore>[]) {
    try {
      const response = await firstValueFrom(this.$baseApi.genericPostCall('/activity/activity-review', { review, scores })) as IActivityReview;
      return response;
    } catch (error) {
      console.warn('[ACTIVITY-REVIEW-SERVICE], user has already created a review for this activity');
      this.$i18nToastProvider.error(_('activity_review.already_reviewed'));
    }
    return null;
  }

  @transaction()
  async fetch(activityReviewId: number): Promise<IActivityReview> {
    try {
      const result = await firstValueFrom(this.$baseApi.genericGetCall(`/activity/activity-review/review/${activityReviewId}`));
      return result as IActivityReview;
    } catch (error) {
      console.warn('[ACTIVITY-REVIEW-SERVICE]: Couldn\'t fetch review', error);
      return undefined;
    }
  }

  @transaction()
  async fetchAll(activityId: number, limit = 100, skip = 0, sortField?: string, sortDirection?: string) {
    try {
      const result = await firstValueFrom(this.$baseApi.genericListCall(`/activity/activity-review/${activityId}`, limit, skip, sortField, sortDirection)) as { items: IActivityReview[] };
      return result;
    } catch (error) {
      console.warn('[ACTIVITY-REVIEW-SERVICE]: Couldn\'t fetch review list', error);
    }
    return null;
  }

  @transaction()
  async fetchOverview(activityId: number): Promise<IActivityReviewOverviewResponse> {
    try {
      const result = await firstValueFrom(this.$baseApi.genericGetCall(`/activity/activity-review/overview/${activityId}`)) as IActivityReviewOverviewResponse;
      return result;
    } catch (error) {
      console.warn('[ACTIVITY-REVIEW-SERVICE]: Couldn\'t fetch review overview', error);
    }
    return null;
  }

  @transaction()
  async fetchByStatus(status: string, organizationUnitId: number, limit = 100, skip = 0, sortField?: string, sortDirection?: string) {
    try {
      const result = await firstValueFrom(this.$baseApi.genericListCall('/activity/activity-review/status', limit, skip, sortField, sortDirection,
        { status, organization_unit_id: organizationUnitId })) as { items: IActivityReview[] };
      return result;
    } catch (error) {
      console.warn('[ACTIVITY-REVIEW-SERVICE]: Couldn\'t fetch review list by status', error);
    }
    return null;
  }

  async delete(activityReviewId: number): Promise<IServiceResponse> {
    try {
      const response = await firstValueFrom(this.$baseApi.genericDeleteCall(`/activity/activity-review/${activityReviewId}`)) as IServiceResponse;
      return response;
    } catch (error) {
      console.warn('[ACTIVITY-REVIEW-SERVICE]: Couldn\'t delete review', error);
    }
    return null;
  }

  async fetchNewCount(organization_unit_id: number) {
    try {
      const response = await firstValueFrom(this.$baseApi.genericListCall('/activity/activity-review/new/count', undefined, undefined, undefined, undefined,
        { organization_unit_id })) as { count: number };
      return response;
    } catch (error) {
      console.warn('[ACTIVITY-REVIEW-SERVICE]: Couldn\'t fetch new review count', error);
    }
    return null;
  }

  async update(activityReviewId: number, organizationUnitId: number, review: Partial<IActivityReview>, scores?: Partial<IActivityReviewScore>[]): Promise<IActivityReview> {
    try {
      const response = await firstValueFrom(this.$baseApi.genericPutCall(`/activity/activity-review/${activityReviewId}`, { review, scores },
        { organization_unit_id: organizationUnitId })) as IActivityReview;
      this.reviewUpdated$.next(undefined);
      return response;
    } catch (error) {
      console.warn('[ACTIVITY-REVIEW-SERVICE]: Couldn\'t update review', error);
    }
    return null;
  }

  async createComment(activityReviewId: number, comment: string) {
    try {
      const response = await firstValueFrom(this.$baseApi.genericPostCall(`/activity/activity-review/review/${activityReviewId}/comment`, { value: comment })) as IActivityReview;
      this.reviewUpdated$.next(undefined);
      return response;
    } catch (error) {
      console.warn('[ACTIVITY-REVIEW-SERVICE]: Couldn\'t update review', error);
    }
    return null;
  }

  async updateComment(activityReviewCommentId: number, comment: string) {
    try {
      const response = await firstValueFrom(this.$baseApi.genericPutCall(`/activity/activity-review/review/comment/${activityReviewCommentId}`, { value: comment })) as IActivityReview;
      this.reviewUpdated$.next(undefined);
      return response;
    } catch (error) {
      console.warn('[ACTIVITY-REVIEW-SERVICE]: Couldn\'t update review', error);
    }
    return null;
  }
}
