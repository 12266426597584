import { ActivityReviewService } from 'src/api/activity/activity-review/activity-review.service';
import { Component, OnInit, ViewEncapsulation, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ISection, IActivity, IActivityStatus, ISearchActivity, IAttachmentMedia, IOrganizationUnitOverview } from 'typings/doenkids/doenkids';
import { Observable, Subject, combineLatest, BehaviorSubject, firstValueFrom } from 'rxjs';
import { map, filter, takeUntil, debounceTime, distinctUntilChanged, mergeMap } from 'rxjs/operators';
import { isNil, get, sortBy, isEqual, intersection, size, isBoolean, uniqBy } from 'lodash';
import { IYoutube } from 'typings/section-types';
import { AssetService } from 'src/api/media/asset/asset.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { ActivityQuery } from 'src/api/activity/activity/activity.query';
import { SectionService } from 'src/api/activity/section/section.service';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { SectionQuery } from 'src/api/activity/section/section.query';
import { PublishActivityService } from 'src/api/publish/activity/activity.service';
import { BreakpointsProvider } from 'src/providers/breakpoints.provider';
import { ActivityService } from 'src/api/activity/activity/activity.service';
import { PermissionProvider } from 'src/providers/permission.provider';
import { IPublishActivityRequest } from 'typings/api-publish';
import { IAssetUrl, IAttachmentListResponse } from 'typings/api-media';
import { OrganizationUnitActivityService } from 'src/api/customer/organization-unit-activity/organization-unit-activity.service';
import { ActivityStatusQuery } from 'src/api/generic/activity-status/activity-status.query';
import { ActivityStatusService } from 'src/api/generic/activity-status/activity-status.service';
import { IOrganizationUnitActivityRoles, IRelatedActivityResponse } from 'typings/api-activity';
import { DownloadProvider } from 'src/providers/download.provider';
import { PdfJsViewerComponent } from 'ng2-pdfjs-viewer';
import { ChoiceDialogComponent, IChoiceDialogData, IDialogActions, IChoiceDialogResult } from 'src/components/dialogs/choice-dialog/choice-dialog.component';
import { DoenkidsStaticValuesHelper } from 'src/components/shared/static-values/doenkids-static-values-helper';
import { JibbieActivityService } from 'src/api/customer/jibbie/activity/jibbie-activity.service';
import { JibbieProvider } from 'src/providers/jibbie.provider';
import {
  ReviewActivityDialogComponent,
  IReviewActivityResponse,
  IActivityOwner,
} from 'src/components/dialogs/review-activity-dialog/review-activity-dialog.component';
import { OrganizationUnitListQuery } from 'src/api/customer/organization-unit-list/organization-unit-list.query';
import { OrganizationUnitListService } from 'src/api/customer/organization-unit-list/organization-unit-list.service';
import { UserListService } from 'src/api/customer/users-list/user-list.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from 'src/app/utils/translate.service';
import { EActivityStatusType, ESectionType } from '../../shared/section/section.component';
import { EOrganizationUnitType } from '../../dialogs/add-organization-dialog/add-organization-dialog.component';
import { I18nToastProvider } from '../../../providers/i18n-toast.provider';
import { OrganizationUnitTypeNamePipe } from '../../../pipes/organization-unit-type-name';
import { I18nTitleStrategy } from '../../../app/utils/i18n-title-strategy';
import { OrganizationUnitService } from 'src/api/customer/organization-unit/organization-unit.service';
import { ActivitySelectionProvider } from 'src/providers/activity-selection.provider';

@Component({
  selector: 'app-activity-preview',
  templateUrl: './activity-preview.component.html',
  styleUrls: ['./activity-preview.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ActivityPreviewComponent implements OnInit, OnDestroy {
  @ViewChild('reviewWrapper') reviewWrapper: ElementRef<HTMLDivElement>;

  private stop$ = new Subject<void>();

  public activityId$: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  public forkedId: number;

  /**
   * The name of the user that originally created the activity
   *
   */
  public activityCreatedByUser = '';

  /**
   * The name of the organization unit that originally created the activity
   *
   */
  public activityCreatedByOU = '';

  public activityDetails$: Observable<IActivity>;

  public activityLoading$: Observable<boolean>;

  private afterFirstPdfRender = false;

  public activityStatus$: Observable<number>;

  // All the metadata
  public activityAttachments$: BehaviorSubject<IAttachmentMedia[]> = new BehaviorSubject<IAttachmentMedia[]>([]);

  public reviewsVisible$ = new BehaviorSubject(false);

  public sections$: Observable<ISection[]>;

  public mediaSections$: Observable<ISection[]>;

  public notMediaSections$: Observable<ISection[]>;

  public headingColor = '#000000';

  public subheadingColor = '#000000';

  public bodyTextColor = '#000000';

  public bodyBackgroundColor = '#FFFFFF';

  /** Subject to render PDF updates
   */
  public pdf$: BehaviorSubject<string | Blob> = new BehaviorSubject<string>('');

  public pdfLoading: boolean;

  /** The last time a PDF Render was requested */
  private lastPdfRender: number;

  @ViewChild('pdfViewer') pdfViewer: PdfJsViewerComponent;

  public isSmall$: Observable<boolean>;

  public isMedium$: Observable<boolean>;

  public isMobile$: Observable<boolean>;

  public isAdmin$: Observable<boolean>;

  public isReader$: Observable<boolean>;

  public currentOUDetails$: Observable<IOrganizationUnitOverview>;

  public isManagement$: Observable<boolean>;

  public isLinkedWihtJibbie$: Observable<boolean>;

  public isActivityLinkedWithJibbie$ = new BehaviorSubject(false);

  private activityOwnerRoles$: BehaviorSubject<IOrganizationUnitActivityRoles[]> = new BehaviorSubject<IOrganizationUnitActivityRoles[]>([]);

  public activityOwner$: Observable<IOrganizationUnitActivityRoles>;

  public activityStatuses$: Observable<IActivityStatus[]>;

  public isRevokedInNodePath$: Observable<boolean>;

  public isOwner$: Observable<boolean>;

  public isEditor$: Observable<boolean>;

  public hasActivityCopyPermission$: Observable<boolean>;

  public hasOUWritePermission$: Observable<boolean>;

  public isCurrentOrganizationUnitIsOfTypeLocation$: Observable<boolean>;

  public isCurrentOrganizationUnitIsOfTypeGroup$: Observable<boolean>;

  public relatedActivities$: BehaviorSubject<IRelatedActivityResponse> = new BehaviorSubject<IRelatedActivityResponse>(null);

  public activityCopyOuName: { [key: number]: string } = {};

  public activityCopies$ = this.relatedActivities$.pipe(
    takeUntil(this.stop$),
    filter((value) => !isNil(value)),
    map((value) => value.copies ?? []),
  );

  public activitySource$ = this.relatedActivities$.pipe(
    takeUntil(this.stop$),
    filter((value) => !isNil(value)),
    map((value) => value.source),
  );

  public reviewOverview$ = new BehaviorSubject(null);

  readonly EActivityStatusType = EActivityStatusType;

  readonly ESectionType = ESectionType;

  private hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$: Observable<boolean>;

  public isLocation$: Observable<boolean>;

  public publishedStatus = DoenkidsStaticValuesHelper.ACTIVITY_STATUS_PUBLISHED;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private sectionQuery: SectionQuery,
    private sectionService: SectionService,
    private $session: DoenkidsSessionProvider,
    private assetService: AssetService,
    private jibbieActivityService: JibbieActivityService,
    private publishActivityService: PublishActivityService,
    private $jibbieProvider: JibbieProvider,
    private $breakpoint: BreakpointsProvider,
    private $permission: PermissionProvider,
    private activityService: ActivityService,
    private activityQuery: ActivityQuery,
    private organizationUnitActivityService: OrganizationUnitActivityService,
    private organizationUnitListQuery: OrganizationUnitListQuery,
    private organizationUnitListService: OrganizationUnitListService,
    private activityStatusQuery: ActivityStatusQuery,
    private activityStatusService: ActivityStatusService,
    private downloadProvider: DownloadProvider,
    private $review: ActivityReviewService,
    private userListService: UserListService,
    private $translateService: TranslateService,
    private $i18nToastProvider: I18nToastProvider,
    private $organizationUnitTypeNamePipe: OrganizationUnitTypeNamePipe,
    private $i18nTitleStrategy: I18nTitleStrategy,
    private organizationUnitService: OrganizationUnitService,
    private activitySelectionProvider: ActivitySelectionProvider,
  ) {
    this.activityDetails$ = this.activityId$.pipe(
      takeUntil(this.stop$),
      filter((value) => !isNil(value)),
      mergeMap((activityId) =>
        this.activityQuery.selectEntity(activityId).pipe(
          takeUntil(this.stop$),
          filter((value) => !isNil(value)),
          debounceTime(300),
          map((activity) => activity.data),
        ),
      ),
    );

    this.activityStatus$ = this.activityDetails$.pipe(
      takeUntil(this.stop$),
      map((activity) => activity.status_id),
    );

    this.activityLoading$ = this.activityQuery.selectLoading().pipe(takeUntil(this.stop$));

    this.sections$ = this.sectionQuery.selectAll().pipe(
      takeUntil(this.stop$),
      map((sections) => sortBy(sections, 'order')),
      map((sections) =>
        sections.map((section) => {
          if (section.type_id === ESectionType.YOUTUBE) {
            // Map all video urls, change them to an accepted format.
            //
            const parsedVideos = get(section, 'data.video', []).map((url: string) => {
              // Regular expression that can take just about any youtube link and get the ID.
              //
              // From: https://stackoverflow.com/a/5831191
              //
              // eslint-disable-next-line max-len
              const re =
                /https?:\/\/(?:[0-9A-Z-]+\.)?(?:youtu\.be\/|youtube(?:-nocookie)?\.com\S*?[^\w\s-])([\w-]{11})(?=[^\w-]|$)(?![?=&+%\w.-]*(?:['"][^<>]*>|<\/a>))[?=&+%\w.-]*/gi;

              // Replace the youtube
              return url.replace(re, 'https://www.youtube-nocookie.com/embed/$1');
            });

            const data: IYoutube = {
              ...section.data,
              video: parsedVideos,
            } as IYoutube;

            return { ...section, data };
          }
          return section;
        }),
      ),
    );

    this.mediaSections$ = this.sections$.pipe(
      takeUntil(this.stop$),
      map((sections) => sections.filter((section) => section.type_id === ESectionType.YOUTUBE)),
    );

    this.notMediaSections$ = this.sections$.pipe(
      takeUntil(this.stop$),
      map((sections) => sections.filter((section) => section.type_id !== ESectionType.YOUTUBE)),
    );

    this.isSmall$ = this.$breakpoint.isSmall$.pipe(takeUntil(this.stop$));

    this.isMedium$ = this.$breakpoint.isMedium$.pipe(takeUntil(this.stop$));

    this.isMobile$ = this.$breakpoint.isMobile$.pipe(takeUntil(this.stop$));

    this.isAdmin$ = this.$session.isAdmin$.pipe(takeUntil(this.stop$));

    this.isReader$ = this.$session.isReader$.pipe(takeUntil(this.stop$));

    this.isLocation$ = this.$session.isCurrentOrganizationUnitIsOfTypeLocation$.pipe(takeUntil(this.stop$));

    this.hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$ = this.$permission.hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$.pipe(
      takeUntil(this.stop$),
    );

    this.currentOUDetails$ = this.$session.getOrganizationUnit$.pipe(takeUntil(this.stop$));

    this.isManagement$ = this.$permission.isDoenkidsManagement$.pipe(takeUntil(this.stop$));

    this.isLinkedWihtJibbie$ = this.$jibbieProvider.isLinkedWithJibbie$.pipe(takeUntil(this.stop$));

    this.activityOwner$ = this.activityOwnerRoles$.pipe(
      takeUntil(this.stop$),
      filter((value) => !isNil(value)),
      map((roles) => {
        const owners = roles.filter((activityRole) => activityRole.organization_unit_role_id === DoenkidsStaticValuesHelper.ACTIVITY_OWNER_ROLE);
        return owners[0];
      }),
    );

    this.activityStatuses$ = this.activityStatusQuery.selectAll().pipe(takeUntil(this.stop$));

    this.isRevokedInNodePath$ = combineLatest([this.currentOUDetails$, this.activityOwnerRoles$]).pipe(
      takeUntil(this.stop$),
      /**
       * Let's check if there in my node_path this activity is revoked
       */
      map(([organization, roles = []]) => {
        const { node_path: nodePath } = organization;
        // @ts-ignore:next-line
        const organizationIdsInMyPath = nodePath.map((id) => parseInt(id, 10));
        const organisationsWhoRevokedThisActivity = roles
          .filter((role) => role.organization_unit_role_name === 'REVOKED')
          .map((role) => role.organization_unit_id);
        const organizationIdsThatOverlap = intersection(organizationIdsInMyPath, organisationsWhoRevokedThisActivity);
        return size(organizationIdsThatOverlap) > 0;
      }),
    );

    this.isOwner$ = combineLatest([this.activityOwner$, this.currentOUDetails$]).pipe(
      takeUntil(this.stop$),
      map(([activityOwner, OUDetails]) => activityOwner?.organization_unit_id === OUDetails?.id),
    );

    this.isEditor$ = combineLatest([this.currentOUDetails$, this.activityOwner$]).pipe(
      takeUntil(this.stop$),
      filter(([OUDetails, activityOwner]) => !isNil(OUDetails) && !isNil(activityOwner)),
      map(([OUDetails, activityOwner]) => {
        // check if owner id is different from current ou id and see if the current ou has a child node with the activityOwners id.
        // if so it is the direct parent of the ou which made the activity
        //
        const isDirectParentOfActivityOwner =
          activityOwner?.organization_unit_id !== OUDetails?.id &&
          OUDetails.child_nodes?.map((childNode) => parseInt(childNode.toString(), 10)).includes(activityOwner?.organization_unit_id);

        return isDirectParentOfActivityOwner;
      }),
    );

    this.hasActivityCopyPermission$ = this.$permission.hasActivityCopyPermission$.asObservable().pipe(takeUntil(this.stop$));

    this.hasOUWritePermission$ = this.$permission.hasOUWritePermissions$.asObservable().pipe(takeUntil(this.stop$));

    this.isCurrentOrganizationUnitIsOfTypeLocation$ = this.$session.isCurrentOrganizationUnitIsOfTypeLocation$.pipe(takeUntil(this.stop$));

    this.isCurrentOrganizationUnitIsOfTypeGroup$ = this.$session.isCurrentOrganizationUnitIsOfTypeGroup$.pipe(takeUntil(this.stop$));
  }

  onPdfLoadedEvent() {
    this.pdfLoading = false;
    this.pdfViewer?.PDFViewerApplication?.preferences?.set('externalLinkTarget', 2);
  }

  async ngOnInit() {
    this.route.params.pipe(takeUntil(this.stop$)).subscribe((params) => {
      this.activityId$.next(+params.id);
    });

    this.route.queryParams.pipe(takeUntil(this.stop$)).subscribe((queryParams) => {
      this.forkedId = +queryParams.forkedId;
    });

    this.activityStatusService.fetchAll();

    // Fetch the activity creator details
    //
    this.activityDetails$
      .pipe(
        filter((value) => !isNil(value)),
        takeUntil(this.stop$),
      )
      .subscribe((activityDetails) => {
        this.$i18nTitleStrategy.updateTitleParameters({ activity: activityDetails.name || activityDetails.id });
        this.setActivityCreatedByValues(activityDetails);
      });

    // Render the current PDF.
    //
    this.pdf$
      .pipe(
        filter((value) => !isNil(value) && value !== ''),
        takeUntil(this.stop$),
      )
      .subscribe((pdf) => {
        if (this.pdfViewer) {
          if (typeof pdf === 'string') {
            this.pdfViewer.pdfSrc = encodeURIComponent(pdf);
          } else {
            this.pdfViewer.pdfSrc = pdf;
          }
          this.pdfViewer.refresh();
        }
      });

    this.activityId$
      .pipe(
        takeUntil(this.stop$),
        filter((activityId) => !isNil(activityId)),
      )
      .subscribe(async (newActivityId: number) => {
        this.getActivityRoles(newActivityId);
        this.fetchActivityDetails(newActivityId);
        this.sectionService.fetchAll(newActivityId, 100, 0);
        const response = await firstValueFrom(this.activityService.getCopyAndSource(newActivityId));
        this.relatedActivities$.next(response);
        this.prefetchCopyOUNames(response.copies ?? []);
        this.fetchReviewOverview();
      });

    this.$jibbieProvider.isLinkedWithJibbie$
      .pipe(
        filter((value) => isBoolean(value)),
        takeUntil(this.stop$),
      )
      .subscribe(() => {
        this.checkIfActivityIsLinkedWithJibbie();
      });

    combineLatest([this.currentOUDetails$, this.activityDetails$])
      .pipe(
        filter(() => this.afterFirstPdfRender),
        takeUntil(this.stop$),
        debounceTime(100),
        distinctUntilChanged(isEqual),
      )
      .subscribe(async () => {
        this.renderPdf();
      });
  }

  /** Destroy all subscriptions, leave no subscribers.
   */
  ngOnDestroy() {
    this.stop$.next();
  }

  /** Navigates to the editing page of the current activity.
   */
  edit() {
    // Navigate to the editing page.
    //
    this.router.navigate([`/activities/${this.activityId$.value}/edit`]);
  }

  // async displayPdf( pdf: Blob ) {
  // }

  /**
   * Downloads the pdf using the pushish API
   */

  async downloadPDF() {
    if (this.pdf$.value === '') {
      this.$i18nToastProvider.error(_('activity.preview.pdf.not_available'));
      return;
    }

    const activityDetails = await firstValueFrom(this.activityDetails$);
    this.downloadProvider.downloadActivityPdf(activityDetails);
  }

  /** Renders the pdf using the publish API.
   */
  async renderPdf() {
    this.pdfLoading = true;

    const thisRenderStartedAt = new Date().getTime();
    this.lastPdfRender = thisRenderStartedAt;

    if (this.pdfViewer) {
      this.pdfViewer.pdfSrc = null;
    }

    let activityDetails: IActivity;
    let OUDetails: IOrganizationUnitOverview;

    try {
      activityDetails = await firstValueFrom(this.activityDetails$);
      OUDetails = await firstValueFrom(this.currentOUDetails$);
    } catch (e) {
      // this happens when we already destroyed the component but are still trying to render.
      // if the component is already destroyed we don't wanna render the pdf anymore so we just return
      //
      return;
    }

    let pdf: string | Blob;
    try {
      const activityPdfRequest: IPublishActivityRequest = {
        activity_id: activityDetails.id,
        organization_unit_id: OUDetails.id,
        template_id: 1,
      };

      pdf = await this.publishActivityService.getActivityPdfUrl(activityPdfRequest, activityDetails.country_code);
    } catch (e) {
      if (thisRenderStartedAt === this.lastPdfRender) {
        this.$i18nToastProvider.error(_('activity.preview.pdf.download.failed'), { name: activityDetails.name });
        this.pdfLoading = false;
      }
    }

    // Only render the last time the PDF was requested to render to prevent 'flickering'
    //
    if (pdf && thisRenderStartedAt === this.lastPdfRender) {
      this.pdf$.next(pdf);
    }
  }

  /**
   * Check if activity is linked with Jibbie
   */
  async checkIfActivityIsLinkedWithJibbie() {
    // Check if this activity is linked to Jibbie
    //
    const OUDetails = await firstValueFrom(this.$session.getOrganizationUnit$);
    const activityId = await firstValueFrom(this.activityId$);
    const isSyncedWithJibbie = await this.$jibbieProvider.isActivitySyncedWithJibbie(activityId, OUDetails?.id);
    this.isActivityLinkedWithJibbie$.next(isSyncedWithJibbie);
  }

  /**
   * Link this activity with Jibbie
   */
  async linkWithJibbie() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      minWidth: '320px',
      data: {
        title: this.$translateService.instant(_('activity.preview.jibbie.link.title')),
        description: this.$translateService.instant(_('activity.preview.jibbie.link.description')),
      },
    });

    const result = await firstValueFrom(dialogRef.afterClosed());

    if (result === 'confirm') {
      const organization = await firstValueFrom(this.currentOUDetails$);
      const activityDetails = await firstValueFrom(this.activityDetails$);
      await this.jibbieActivityService.linkWithOrganization(activityDetails.id, organization.id);
      this.checkIfActivityIsLinkedWithJibbie();
    }
  }

  /**
   * Link this activity with Jibbie
   */
  async unlinkWithJibbie() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      minWidth: '320px',
      data: {
        title: this.$translateService.instant(_('activity.preview.jibbie.unlink.title')),
        description: this.$translateService.instant(_('activity.preview.jibbie.unlink.description')),
      },
    });

    const result = await firstValueFrom(dialogRef.afterClosed());

    if (result === 'confirm') {
      const organization = await firstValueFrom(this.currentOUDetails$);
      const activityDetails = await firstValueFrom(this.activityDetails$);
      await this.jibbieActivityService.unlinkWithOrganization(activityDetails.id, organization.id);
      this.checkIfActivityIsLinkedWithJibbie();
    }
  }

  async openAttachment(attachment: IAttachmentMedia) {
    // eslint-disable-next-line no-undef
    const a = document.createElement('a');

    const mediaAssetUrl = (await firstValueFrom(this.assetService.getUrl(attachment.uuid))) as IAssetUrl;

    a.href = mediaAssetUrl.url;
    a.download = attachment.filename;
    a.click();
  }

  async reviewByOrganisation() {
    const session = await firstValueFrom(this.$session.getSession$);
    const activityId = await firstValueFrom(this.activityId$);

    let parent: IActivityOwner;

    if (session.details.parent_organization_unit_id) {
      parent = {
        id: session.details.parent_organization_unit_id,
        name: session.details.parent_organization_unit_name,
        type: EOrganizationUnitType.ORGANIZATION,
      };
    } else {
      const dkDetails = await this.organizationUnitService.fetch(DoenkidsStaticValuesHelper.DOENKIDS_IDENTIFIER, true);
      parent = {
        id: dkDetails.id,
        name: dkDetails.name,
        type: EOrganizationUnitType.ORGANIZATION,
      };
    }

    const dialogRef = this.dialog.open(ReviewActivityDialogComponent, {
      width: '400px',
      minWidth: '320px',
      data: {
        parent,
        activityId,
      },
    });

    const result = (await firstValueFrom(dialogRef.afterClosed())) as IReviewActivityResponse;

    if (result?.status === 'confirm') {
      this.$i18nToastProvider.success(_('activity.preview.review.started'), {
        organization_unit_type: this.$translateService.instant(this.$organizationUnitTypeNamePipe.transform(result.owner.type)),
        name: result.owner.name,
      });
      await this.getActivityRoles(activityId);
    }
  }

  async fetchActivityDetails(activityId: number) {
    await this.activityService.fetch(activityId);
    await this.renderPdf();
    this.afterFirstPdfRender = true;
  }

  async forkActivity() {
    const ouId = (await firstValueFrom(this.currentOUDetails$)).id;
    const activityDetails = await firstValueFrom(this.activityDetails$);
    const newActivity = await this.activityService.copy(activityDetails.id, ouId);
    this.router.navigate([`/activities/${newActivity.id}/edit`], { replaceUrl: true });
  }

  async unforkActivity() {
    const ouId = (await firstValueFrom(this.currentOUDetails$)).id;
    const activityDetails = await firstValueFrom(this.activityDetails$);

    await firstValueFrom(this.organizationUnitActivityService.archive(ouId, activityDetails.id));
  }

  async excludeActivity() {
    const dialogText: string[] = [_('activity.exclude.confirm.description')];
    const dialogTextParams: any = {};
    const isAdmin = await firstValueFrom(this.isAdmin$);
    const hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree = await firstValueFrom(this.hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$);

    if (isAdmin || hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree) {
      const activityConnections = await firstValueFrom(this.activityService.listConnections(this.activityId$.value));
      const showableNameLength = 5;

      if (activityConnections.length > 0) {
        const templateCount = activityConnections.reduce((previous, current) => previous + (!isNil(current.template_id) ? 1 : 0), 0);
        if (templateCount > 0) {
          dialogTextParams.template_count = templateCount;
          const templateNames = uniqBy(
            activityConnections.map((activityConnection) => activityConnection.template_name).filter((name) => !isNil(name)),
            (name) => name.toLowerCase().trim(),
          );
          if (templateNames.length > showableNameLength) {
            dialogTextParams.template_names = [
              ...templateNames.splice(0, showableNameLength),
              this.$translateService.instant(_('activity.exclude.confirm.description.activity_connections.and_other_templates'), {
                template_count: templateCount - showableNameLength,
              }),
            ].join(', ');
          } else {
            dialogTextParams.template_names = templateNames.join(', ');
          }
        }

        const programCount = activityConnections.reduce((previous, current) => previous + (!isNil(current.program_id) ? 1 : 0), 0) - templateCount;

        if (programCount > 0) {
          dialogTextParams.program_count = programCount;
          const programNames = uniqBy(
            activityConnections.map((activityConnection) => activityConnection.program_name).filter((name) => !isNil(name)),
            (name) => name.toLowerCase().trim(),
          );
          if (programNames.length > showableNameLength) {
            dialogTextParams.program_names = [
              ...programNames.splice(0, showableNameLength),
              this.$translateService.instant(_('activity.exclude.confirm.description.activity_connections.and_other_programs'), {
                program_count: programCount - showableNameLength,
              }),
            ].join(', ');
          } else {
            dialogTextParams.program_names = programNames.join(', ');
          }
        }

        if (programCount > 0 && templateCount > 0) {
          dialogText.push(_('activity.exclude.confirm.description.activity_connections.templates_and_programs'));
        } else if (programCount === 0 && templateCount > 0) {
          dialogText.push(_('activity.exclude.confirm.description.activity_connections.templates'));
        } else if (programCount > 0 && templateCount === 0) {
          dialogText.push(_('activity.exclude.confirm.description.activity_connections.programs'));
        }
      }
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      minWidth: '320px',
      data: {
        title: this.$translateService.instant(_('activity.exclude.confirm.title')),
        description: this.$translateService.instant(dialogText, dialogTextParams),
      },
    });

    const result = await firstValueFrom(dialogRef.afterClosed());

    if (result === 'confirm') {
      const isOwner = await firstValueFrom(this.isOwner$);
      const activityId = this.activityId$.value;

      if (isOwner) {
        // if we are the owner of the activity and we want to exclude we set the status of the activity to unpublished
        //
        const activityStatuses = await firstValueFrom(this.activityStatuses$);
        const activityDetails = await firstValueFrom(this.activityDetails$);
        const unpublishedStatusId = activityStatuses.filter((status: IActivityStatus) => status.name === 'UNPUBLISHED')[0]?.id;

        if (unpublishedStatusId) {
          await this.activityService.update(activityId, { ...activityDetails, status_id: unpublishedStatusId });
        }
      } else {
        if (!this.activityOwnerRoles$.value) {
          await this.getActivityRoles(this.activityId$.value);
        }

        const currentOUDetails = await firstValueFrom(this.currentOUDetails$);
        const currentOURightsArray = this.activityOwnerRoles$.value.filter((activityRole) => activityRole.organization_unit_id === currentOUDetails.id);
        const currentOURights = currentOURightsArray[0]?.organization_unit_role_id ?? null;

        if (currentOURights && currentOURights === DoenkidsStaticValuesHelper.ACTIVITY_REVOKED_ROLE) {
          // if you already have a revoked right on this activity you shouldn't even see it
          // but we will let you know we already excluded it
          //
          this.$i18nToastProvider.error(_('activity.exclude.already_excluded'));
        } else {
          await firstValueFrom(this.activityService.unapprove(this.activityId$.value, currentOUDetails.id));
        }

        this.router.navigate(['/activities']);
      }
    }
  }

  async restoreActivity() {
    const currentOUDetails = await firstValueFrom(this.currentOUDetails$);
    const hasWritePermissionsOnCurrentOU = firstValueFrom(this.$permission.hasOUWritePermissions$);
    if (hasWritePermissionsOnCurrentOU) {
      await firstValueFrom(this.activityService.approve(this.activityId$.value, currentOUDetails.id));
    } else {
      this.$i18nToastProvider.error(_('activity.preview.restore.unauthorized'));
    }
    this.router.navigate(['/activities']);
  }

  async deleteActivity() {
    const dialogText: string[] = [_('activity.delete.confirm.description')];
    const dialogTextParams: any = {};
    const isAdmin = await firstValueFrom(this.isAdmin$);
    const hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree = await firstValueFrom(this.hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree$);

    if (isAdmin || hasWritePermissionOnAtLeastOneCustomerOUInCurrentNodeTree) {
      const activityConnections = await firstValueFrom(this.activityService.listConnections(this.activityId$.value));
      const showableNameLength = 5;

      if (activityConnections.length > 0) {
        const templateCount = activityConnections.reduce((previous, current) => previous + (!isNil(current.template_id) ? 1 : 0), 0);
        if (templateCount > 0) {
          dialogTextParams.template_count = templateCount;
          const templateNames = uniqBy(
            activityConnections.map((activityConnection) => activityConnection.template_name).filter((name) => !isNil(name)),
            (name) => name.toLowerCase().trim(),
          );
          if (templateNames.length > showableNameLength) {
            dialogTextParams.template_names = [
              ...templateNames.splice(0, showableNameLength),
              this.$translateService.instant(_('activity.delete.confirm.description.activity_connections.and_other_templates'), {
                template_count: templateCount - showableNameLength,
              }),
            ].join(', ');
          } else {
            dialogTextParams.template_names = templateNames.join(', ');
          }
        }

        const programCount = activityConnections.reduce((previous, current) => previous + (!isNil(current.program_id) ? 1 : 0), 0) - templateCount;

        if (programCount > 0) {
          dialogTextParams.program_count = programCount;
          const programNames = uniqBy(
            activityConnections.map((activityConnection) => activityConnection.program_name).filter((name) => !isNil(name)),
            (name) => name.toLowerCase().trim(),
          );
          if (programNames.length > showableNameLength) {
            dialogTextParams.program_names = [
              ...programNames.splice(0, showableNameLength),
              this.$translateService.instant(_('activity.delete.confirm.description.activity_connections.and_other_programs'), {
                program_count: programCount - showableNameLength,
              }),
            ].join(', ');
          } else {
            dialogTextParams.program_names = programNames.join(', ');
          }
        }

        if (programCount > 0 && templateCount > 0) {
          dialogText.push(_('activity.delete.confirm.description.activity_connections.templates_and_programs'));
        } else if (programCount === 0 && templateCount > 0) {
          dialogText.push(_('activity.delete.confirm.description.activity_connections.templates'));
        } else if (programCount > 0 && templateCount === 0) {
          dialogText.push(_('activity.delete.confirm.description.activity_connections.programs'));
        }
      }
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      minWidth: '320px',
      data: {
        title: this.$translateService.instant(_('activity.preview.delete.confirm.title')),
        description: this.$translateService.instant(dialogText, dialogTextParams),
      },
    });

    const result = await firstValueFrom(dialogRef.afterClosed());

    if (result === 'confirm') {
      await this.activityService.archive(this.activityId$.value);
      this.router.navigate(['/activities/'], { replaceUrl: true });
    }
  }

  async navigateToForkedActivity() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      minWidth: '320px',
      data: {
        title: this.$translateService.instant(_('generic.confirm')),
        description: this.$translateService.instant(_('activity.preview.navigate_to_forked.confirm.description')),
      },
    });

    const result = await firstValueFrom(dialogRef.afterClosed());

    if (result === 'confirm') {
      this.router.navigate([`/activities/preview/${this.forkedId}`]);
    }
  }

  async navigateToActivity(activityId: number) {
    if (activityId !== this.activityId$.value) {
      this.router.navigate([`/activities/preview/${activityId}`], { replaceUrl: true });
    }
  }

  async fetchActivityAttachments() {
    const activityAttachments = (await firstValueFrom(this.activityService.attachment(this.activityId$.value))) as IAttachmentListResponse;
    this.activityAttachments$.next(activityAttachments.items);
  }

  async backToDrawingBoard() {
    const currentActivityDetails = await firstValueFrom(this.activityDetails$);

    let currentStatus = '';

    switch (currentActivityDetails.status_id) {
      case EActivityStatusType.CONCEPT:
        currentStatus = this.$translateService.instant(_('activity.status.concept'));
        break;
      case EActivityStatusType.REVIEW:
        currentStatus = this.$translateService.instant(_('activity.status.review'));
        break;
      case EActivityStatusType.UNPUBLISHED:
        currentStatus = this.$translateService.instant(_('activity.status.unpublished'));
        break;
      default:
        currentStatus = this.$translateService.instant(_('activity.status.published'));
        break;
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      minWidth: '320px',
      data: {
        title: this.$translateService.instant(_('activity.preview.to_concept.confirm.title')),
        description: this.$translateService.instant(_('activity.preview.to_concept.confirm.description'), { currentStatus }),
      },
    });

    const result = await firstValueFrom(dialogRef.afterClosed());

    if (result && result === 'confirm') {
      // Change publication status
      //
      const activityDetails = await firstValueFrom(this.activityDetails$);
      activityDetails.status_id = 1;
      await this.activityService.update(activityDetails.id, activityDetails);

      // Go to edit screen
      //
      this.edit();
    }
  }

  public async approveActivity() {
    const dialogActions: IDialogActions = {
      primaryAction: {
        label: this.$translateService.instant(_('activity.preview.approve.for_location')),
        value: 'location',
      },
      secondaryAction: {
        label: this.$translateService.instant(_('activity.preview.approve.for_organization')),
        value: 'organization',
      },
    };

    const data: IChoiceDialogData = {
      title: this.$translateService.instant(_('activity.preview.approve.confirm.title')),
      description: this.$translateService.instant(_('activity.preview.approve.confirm.description')),
      actions: dialogActions,
    };

    const dialogRef = this.dialog.open(ChoiceDialogComponent, {
      width: '600px',
      minWidth: '320px',
      data,
    });

    const dialogResult: IChoiceDialogResult = await firstValueFrom(dialogRef.afterClosed());

    if (dialogResult) {
      const activityDetails = await firstValueFrom(this.activityDetails$);
      const activityId = activityDetails.id;
      const currentOwner = await firstValueFrom(this.activityOwner$);
      const currentOUDetails = await firstValueFrom(this.currentOUDetails$);

      if (dialogResult?.action === 'organization') {
        // it was chosen to publish for the organization so set owner to current ou and remove owner right for current owner
        //
        // set current owner link with activity to reader so it won't be excluded for the child
        //
        await firstValueFrom(this.organizationUnitActivityService.archive(currentOwner.organization_unit_id, activityId));
        await firstValueFrom(this.organizationUnitActivityService.update(currentOUDetails.id, activityId, DoenkidsStaticValuesHelper.ACTIVITY_READER_ROLE));

        // set the current ou as owner (3). This results in the approver becoming the owner so they can edit it and all ou's under it will see the activity
        // to be able to change the role we first need to archive before we can update
        //
        await firstValueFrom(this.organizationUnitActivityService.archive(currentOUDetails.id, activityId));
        await firstValueFrom(this.organizationUnitActivityService.update(currentOUDetails.id, activityId, DoenkidsStaticValuesHelper.ACTIVITY_OWNER_ROLE));

        // it was chosen to publish for the ou that created the activity so set published status (4) and revoke READ permission for current ou (editor)
        //
        const activityStatuses = await firstValueFrom(this.activityStatuses$);
        const publishedStatusId = activityStatuses.filter((status: IActivityStatus) => status.name === 'PUBLISHED')[0]?.id;

        const activity: IActivity = {
          ...activityDetails,
          status_id: publishedStatusId,
        };

        await this.activityService.update(this.activityId$.value, activity);

        this.getActivityRoles(activityDetails.id);
      }

      if (dialogResult?.action === 'location') {
        // it was chosen to publish for the ou that created the activity so set published status (4)
        //
        const activityStatuses = await firstValueFrom(this.activityStatuses$);
        const publishedStatusId = activityStatuses.filter((status: IActivityStatus) => status.name === 'PUBLISHED')[0]?.id;

        const activity: IActivity = {
          ...activityDetails,
          status_id: publishedStatusId,
        };

        await this.activityService.update(this.activityId$.value, activity);
      }
    }
  }

  private async getActivityRoles(activityId: number) {
    this.activityOwnerRoles$.next((await firstValueFrom(this.activityService.organizationUnits(activityId))) as IOrganizationUnitActivityRoles[]);
  }

  private async prefetchCopyOUNames(copies: ISearchActivity[]) {
    for (const copy of copies) {
      const ouId = (copy.owner_organization_unit_id ?? [])[0];
      if (ouId && !this.activityCopyOuName[ouId]) {
        let ouName;
        if (this.organizationUnitListQuery.hasEntity(ouId)) {
          const value = this.organizationUnitListQuery.getEntity(ouId);
          ouName = value.name;
        } else {
          try {
            // eslint-disable-next-line no-await-in-loop
            const organization = await this.organizationUnitListService.fetch(ouId);

            ouName = organization.name;
          } catch (errorResponse) {
            if (errorResponse.error.name === 'FORBIDDEN') {
              // we don't have rights to the ou of which has a copy so we don't show it
              // but also don't show the error
            } else {
              // TODO
              this.$i18nToastProvider.error(_(errorResponse.error.message));
            }
          }
        }

        if (ouName) {
          this.activityCopyOuName[ouId] = this.$translateService.instant(_('activity.preview.copy_at_organization'), { organization: ouName });
        }
      }
    }
  }

  async setActivityCreatedByValues(activity: IActivity) {
    if (activity.status_id === EActivityStatusType.REVIEW) {
      if (activity.created_by_organization_unit_id) {
        try {
          // eslint-disable-next-line no-await-in-loop
          const organization = await this.organizationUnitListService.fetch(activity.created_by_organization_unit_id);

          this.activityCreatedByOU = organization.name;
        } catch (errorResponse) {
          // There was an error fetching the ou but since its not critical to show this we supress the error and set the name to blank to be sure
          //
          this.activityCreatedByOU = '';
        }
      }

      if (activity.created_by_user_id) {
        // fetch user
        try {
          // eslint-disable-next-line no-await-in-loop
          const user = await this.userListService.fetch(activity.created_by_user_id);

          this.activityCreatedByUser = user.email;
        } catch (errorResponse) {
          // There was an error fetching the user but since its not critical to show this we supress the error and set the name to blank to be sure
          //
          this.activityCreatedByUser = '';
        }
      }
    } else {
      this.activityCreatedByUser = '';
      this.activityCreatedByOU = '';
    }
  }

  async fetchReviewOverview() {
    const overview = await this.$review.fetchOverview(this.activityId$.value);
    this.reviewOverview$.next(overview);
  }

  scrollToReviews() {
    this.reviewWrapper.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  async createProgram() {
    const activity = await firstValueFrom(this.activityDetails$);
    this.activitySelectionProvider.clearSelectedActivities();
    this.activitySelectionProvider.addActivity(activity);

    this.router.navigate(['/calendar']);
  }
}
