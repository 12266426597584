import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { ActivityReviewService } from 'src/api/activity/activity-review/activity-review.service';
import { MatDialog } from '@angular/material/dialog';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { AgeGroupQuery } from 'src/api/generic/age-group/age-group.query';
import { ActivityService } from 'src/api/activity/activity/activity.service';
import {
  Component, EventEmitter, Input, OnChanges, Output, SimpleChanges,
} from '@angular/core';
import { ActivityQuery } from 'src/api/activity/activity/activity.query';
import { map } from 'rxjs/operators';
import { ConfirmationDialogComponent } from 'src/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { IActivity, IAgeGroupDetails } from 'typings/doenkids/doenkids';
import { Observable, firstValueFrom } from 'rxjs';
import * as dayjs from 'dayjs';
import { I18nToastProvider } from 'src/providers/i18n-toast.provider';
import { TranslateService } from 'src/app/utils/translate.service';
import { EReviewStatus, IApprovalReview } from '../review-approval.component';

@Component({
  selector: 'app-review-preview',
  templateUrl: './review-preview.component.html',
  styleUrls: ['./review-preview.component.scss'],
})
export class ReviewPreviewComponent implements OnChanges {
  @Input() review: IApprovalReview;

  @Input() admin: boolean;

  @Input() selectedStatusOption: string;

  @Output() statusChange = new EventEmitter();

  public activity$: Observable<IActivity>;

  public reviewAgeGroup$: Observable<IAgeGroupDetails>;

  get reviewDate() {
    if (!this.review) {
      return null;
    }
    const createdAt = dayjs(this.review.created_at);
    const updatedAt = dayjs(this.review.updated_at);

    return updatedAt.isAfter(createdAt) ? updatedAt.toDate() : createdAt.toDate();
  }

  public trackById = (idx, val) => val.type_id;

  constructor(
    private $review: ActivityReviewService,
    private $activity: ActivityService,
    private session: DoenkidsSessionProvider,
    ageGroupQuery: AgeGroupQuery,
    public activityQuery: ActivityQuery,
    private dialog: MatDialog,
    private $translateService: TranslateService,
    private $i18nToastProvider: I18nToastProvider,
  ) {
    this.reviewAgeGroup$ = ageGroupQuery.firstNonEmpty().pipe(
      map((ageGroups) => {
        let ageGroup: IAgeGroupDetails;
        if (this.review && ageGroups) {
          ageGroup = ageGroups.find((group) => group.id === this.review.age_group_id);
        }
        return ageGroup;
      }),
    );
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.review) {
      const activityStream = this.activityQuery.selectEntity(this.review.activity_id);
      const activity = await firstValueFrom(activityStream);

      if (!activity) {
        this.$activity.fetch(this.review.activity_id);
      }

      this.activity$ = activityStream.pipe(map((storeActivity) => storeActivity?.data));
    }
  }

  approve() {
    this.update(EReviewStatus.APPROVED);
  }

  deny() {
    this.update(EReviewStatus.DENIED);
  }

  async update(statusId: EReviewStatus.APPROVED | EReviewStatus.DENIED) {
    const newStatus = statusId === EReviewStatus.APPROVED ? 'APPROVED' : 'DENIED';
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      minWidth: '320px',
      data: {
        title: statusId === EReviewStatus.APPROVED
          ? this.$translateService.instant(_('review.approve'))
          : this.$translateService.instant(_('review.deny')),
        description: this.$translateService.instant(_('review_preview.update.confirm.description'), { newStatus }),
      },
    });

    const result = await firstValueFrom(dialogRef.afterClosed());

    const organizationUnitId = await firstValueFrom(this.session.currentOuId$);

    if (organizationUnitId && result === 'confirm') {
      await this.$review.update(this.review.id, organizationUnitId, {
        activity_review_status_id: statusId,
      });

      const activity = await firstValueFrom(this.activity$);
      this.$i18nToastProvider.success(_('review_preview.update.success'), { newStatus, activity: activity.name });
      this.statusChange.emit();
    }
  }

  getStatusName(statusId: EReviewStatus): string {
    switch (statusId) {
      case EReviewStatus.NEW:
        return _('review.status.new');
      case EReviewStatus.DENIED:
        return _('review.status.denied');
      case EReviewStatus.UPDATED:
        return _('review.status.updated');
      case EReviewStatus.APPROVED:
        return _('review.status.approved');
      default:
        return _('review.status.unknown');
    }
  }
}
