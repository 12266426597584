/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ViewEncapsulation, Component, Input, OnInit, OnDestroy, SimpleChanges, OnChanges,
} from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TemplateService } from 'src/api/publish/template/template.service';
import { TemplateQuery } from 'src/api/publish/template/template.query';
import { filter } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ITemplateOptions } from 'typings/api-publish';
import { OrganizationUnitCustomerTemplateService } from 'src/api/customer/organization-unit-template/organization-unit-template.service';
import { isNil, isEmpty, omitBy } from 'lodash';
import { DoenkidsStaticValuesHelper } from 'src/components/shared/static-values/doenkids-static-values-helper';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

_('template_settings.poster_template.label');
_('template_settings.program_template.label');
_('template_settings.activity_template.label');

const DEFAULT_ACTIVITY_TEMPLATE_ID = 1;
const DEFAULT_PROGRAM_TEMPLATE_ID = 2;
const DEFAULT_POSTER_TEMPLATE_ID = 4;

@Component({
  selector: 'app-template-settings',
  templateUrl: './template-settings.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./template-settings.component.scss'],
})
export class TemplateSettingsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() organizationUnitId: number;

  form: UntypedFormGroup;

  protected editableTemplates: string[] = [];

  public customProgramTemplateId: number;

  public customActivityTemplateId: number;

  public customPosterTemplateId: number;

  private stop$ = new Subject();

  constructor(
    formBuilder: UntypedFormBuilder,
    private templateService: TemplateService,
    private templateQuery: TemplateQuery,
    private organizationUnitCustomerTemplateService: OrganizationUnitCustomerTemplateService,
  ) {
    this.form = formBuilder.group({
      program_template: [],
      activity_template: [],
      poster_template: [],
    });
  }

  ngOnInit(): void {
    this.templateQuery.selectAll().pipe(
      filter((value) => !isNil(value)),
    ).subscribe((templates) => {
      // If we're looking at DoenKids we also want to edit the settings
      //
      if (this.organizationUnitId === DoenkidsStaticValuesHelper.DOENKIDS_IDENTIFIER) {
        this.customActivityTemplateId = DEFAULT_ACTIVITY_TEMPLATE_ID;
        this.customProgramTemplateId = DEFAULT_PROGRAM_TEMPLATE_ID;
        this.customPosterTemplateId = DEFAULT_POSTER_TEMPLATE_ID;
      }

      // Only use the template settings that are applicable for the selected OU
      //
      const organizationTemplates = templates.filter((template) => template.organization_unit_id === this.organizationUnitId);

      // The logic is first check if the template (by id) is replaced, second if it is defined for your organization
      // otherwise you select the first template with that id
      //
      let activityTemplate = organizationTemplates.find(
        (template) => template.replace_template_id === DEFAULT_ACTIVITY_TEMPLATE_ID && template.organization_unit_id === this.organizationUnitId,
      );

      if (!activityTemplate) {
        activityTemplate = organizationTemplates.find(
          (template) => template.template_id === DEFAULT_ACTIVITY_TEMPLATE_ID && template.organization_unit_id === this.organizationUnitId,
        );
      }

      this.form.get('activity_template')?.setValue(activityTemplate?.template_settings);
      this.customActivityTemplateId = activityTemplate?.template_id;

      // Now do the same for program templates
      //
      let programTemplate = organizationTemplates.find(
        (template) => template.replace_template_id === DEFAULT_PROGRAM_TEMPLATE_ID && template.organization_unit_id === this.organizationUnitId,
      );

      if (!programTemplate) {
        programTemplate = organizationTemplates.find(
          (template) => template.template_id === DEFAULT_PROGRAM_TEMPLATE_ID && template.organization_unit_id === this.organizationUnitId,
        );
      }

      this.form.get('program_template')?.setValue(programTemplate?.template_settings);
      this.customProgramTemplateId = programTemplate?.template_id;

      // Now do the same for poster templates
      //
      let posterTemplate = organizationTemplates.find(
        (template) => template.replace_template_id === DEFAULT_POSTER_TEMPLATE_ID && template.organization_unit_id === this.organizationUnitId,
      );

      if (!posterTemplate) {
        posterTemplate = organizationTemplates.find(
          (template) => template.template_id === DEFAULT_POSTER_TEMPLATE_ID && template.organization_unit_id === this.organizationUnitId,
        );
      }

      this.form.get('poster_template')?.setValue(posterTemplate?.template_settings);
      this.customPosterTemplateId = posterTemplate?.template_id;

      this.editableTemplates = [];

      if (this.customActivityTemplateId) {
        this.editableTemplates.push('activity_template');
      }
      if (this.customProgramTemplateId) {
        this.editableTemplates.push('program_template');
      }
      if (this.customPosterTemplateId) {
        this.editableTemplates.push('poster_template');
      }
    });

    this.templateService.fetch(this.organizationUnitId);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.organizationUnitId && !changes.organizationUnitId?.firstChange) {
      this.templateService.fetch(this.organizationUnitId);
    }
  }

  ngOnDestroy(): void {
    this.stop$.next(undefined);
  }

  /**
   * Cleanup and concatenate the template before submitting
   * @param general The general template
   * @param kdv The kdv template
   * @param bso The bso template
   */
  cleanupSettings(settings: ITemplateOptions) {
    for (const settingsKey of Object.keys(settings)) {
      const property = settings[settingsKey];
      if (!isNil(property) && typeof property === 'object') {
        settings[settingsKey] = this.cleanupSettings(property);
      } else if (!isNil(property) && isEmpty(property)) {
        settings[settingsKey] = null;
      }
    }

    return omitBy(settings, isEmpty);
  }

  /**
   * Transform and save the changed to DoenKids
   * @param values form values
   */
  async saveSettings(values: {
    program_template: ITemplateOptions,
    activity_template: ITemplateOptions,
    poster_template: ITemplateOptions,
  }) {
    let somethingChanged = false;

    if (this.customProgramTemplateId && values.program_template) {
      await this.organizationUnitCustomerTemplateService.update(
        this.organizationUnitId,
        this.customProgramTemplateId,
        this.cleanupSettings(values.program_template),
      );
      somethingChanged = true;
    }

    if (this.customActivityTemplateId && values.activity_template) {
      await this.organizationUnitCustomerTemplateService.update(
        this.organizationUnitId,
        this.customActivityTemplateId,
        this.cleanupSettings(values.activity_template),
      );
      somethingChanged = true;
    }

    if (this.customPosterTemplateId && values.poster_template) {
      await this.organizationUnitCustomerTemplateService.update(
        this.organizationUnitId,
        this.customPosterTemplateId,
        this.cleanupSettings(values.poster_template),
      );
      somethingChanged = true;
    }

    if (somethingChanged) {
      this.templateService.fetch(this.organizationUnitId);
    }
  }

  getGroupControls() {
    return this.form.controls as { [key: string]: FormControl<ITemplateOptions> };
  }
}
