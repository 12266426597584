import {
  Component, OnInit, Inject, OnDestroy, ViewEncapsulation,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { filter } from 'rxjs/operators';
import { Subject, BehaviorSubject, firstValueFrom } from 'rxjs';
import { isNil } from 'lodash';
import { DoenkidsSessionProvider } from 'src/providers/session.provider';
import { PublishActivityService } from 'src/api/publish/activity/activity.service';
import { PublishProgramService } from 'src/api/publish/program/program.service';
import { IActivity } from 'typings/doenkids/doenkids';


@Component({
  selector: 'app-template-html-dialog',
  templateUrl: './template-html-dialog.component.html',
  styleUrls: ['./template-html-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TemplateHtmlDialogComponent implements OnInit, OnDestroy {
  public loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  programId?: number;

  templateId: number;

  activity?: IActivity;

  htmlUpdate$ = new Subject();

  stop$ = new Subject();

  constructor(
    private $session: DoenkidsSessionProvider,
    private publishActivityService: PublishActivityService,
    private publishProgramService: PublishProgramService,
    @Inject(MAT_DIALOG_DATA) public dialogData: { programId?: number, templateId: number, activity?: IActivity },
    public dialogRef: MatDialogRef<TemplateHtmlDialogComponent>,
  ) {
    this.programId = this.dialogData.programId;
    this.templateId = this.dialogData.templateId;
    this.activity = this.dialogData.activity;

    // Render the current HTML.
    //
    this.htmlUpdate$.pipe(
      filter((value) => !isNil(value)),
    ).subscribe((response: any) => {
      const iframe = document.getElementById('rendered-html') as HTMLFrameElement;
      if (iframe) {
        const blob = new Blob([response.content], { type: 'text/html' });
        iframe.src = window.URL.createObjectURL(blob);
      }
      this.loading$.next(false);
    });
  }

  ngOnDestroy(): void {
    this.stop$.next(undefined);
  }

  async ngOnInit() {
    let htmlDocument;

    // Either fetch the activity, or the program HTML data
    //
    this.loading$.next(true);

    const organizationUnitId = await firstValueFrom(this.$session.currentOuId$);

    try {
      if (this.programId) {
        htmlDocument = await this.publishProgramService.fetchProgramHtml({
          program_id: this.programId,
          organization_unit_id: organizationUnitId,
          template_id: this.templateId,
        });
      } else if (this.activity) {
        htmlDocument = await this.publishActivityService.fetchActivityHtml({
          activity_id: this.activity.id,
          organization_unit_id: organizationUnitId,
          template_id: this.templateId,
        }, this.activity.country_code);
      }
    } catch (e) {
      // fetchProgramHtml and fetchActivityHtml will already show a toast so we don't have to that here
      // this is just to catch the error so it doesn't bubble up
    }


    // this will set loading back to false
    //
    this.htmlUpdate$.next(htmlDocument);
  }
}
