<mat-expansion-panel *ngFor="let form of forms; first as isInnerFirst" [expanded]="isFirst && isInnerFirst">
  <mat-expansion-panel-header>
    <mat-panel-title>{{ 'template_settings.' + template + '.label' | translate }}</mat-panel-title>
    <mat-panel-description>{{ getPanelDescription(form) }}</mat-panel-description>
  </mat-expansion-panel-header>
  <ng-container [ngTemplateOutlet]="templateSettings" [ngTemplateOutletContext]="{ form: form.control }"></ng-container>
</mat-expansion-panel>

<ng-template #templateSettings let-form="form">
  <form [formGroup]="form">
    <section>
      <h3>{{ 'template_setting_options.header.heading' | translate }}</h3>
      <app-asset-upload
        identifier="headerImage"
        purpose="header"
        (uploaded)="selectImage($event, form.get('headerImageUuid'))"
        >
      <ng-template let-uploadData="uploadData">
        <div fxLayout="row" fxLayoutAlign="center center" >
          <app-doenkids-image
            [maxWidth]="800"
            [maxHeight]="150"
            class="banner-image"
            [mediaItem]="form.get( 'headerImageUuid' ).value">
            <app-select-media
              class="banner-image"
              [uploadData]="uploadData"
              identifier="headerImage"
              [removable]="form.get('headerImageUuid').value"
              (selected)="selectImage($event, form.get('headerImageUuid'))"
              purpose="header">
            </app-select-media>
          </app-doenkids-image>
        </div>
      </ng-template>
      </app-asset-upload>
    </section>
    <section>
      <h3>{{ 'template_setting_options.footer.heading' | translate }}</h3>
      <app-asset-upload
        identifier="footerImage"
        purpose="footer"
        (uploaded)="selectImage($event, form.get('footerImageUuid'))"
        >
      <ng-template let-uploadData="uploadData">
        <div fxLayout="row" fxLayoutAlign="center center" >
          <app-doenkids-image
            [maxWidth]="800"
            [maxHeight]="150"
            class="banner-image"
            [mediaItem]="form.get( 'footerImageUuid' ).value">
            <app-select-media
              class="banner-image"
              identifier="footerImage"
              [uploadData]="uploadData"
              [removable]="form.get('footerImageUuid').value"
              (selected)="selectImage($event, form.get('footerImageUuid'))"
              purpose="footer">
            </app-select-media>
          </app-doenkids-image>
        </div>
      </ng-template>
      </app-asset-upload>
    </section>
    <section>
      <h3>{{ 'template_setting_options.colors.heading' | translate }}</h3>
      <div fxLayout="row" fxLayoutAlign="space-around center">
        <mat-card appearance="outlined">
          <mat-card-header>
            <mat-card-title>{{ 'template_setting_options.body_text_color.label' | translate }}</mat-card-title>
          </mat-card-header>
          <mat-card-content fxLayout="row" fxLayoutAlign="space-around center">
            <span
              class="color-setting"
              [style.background]="form.get('bodyTextColor').value"
              cpPosition="top"
              [cpCancelButton]="true"
              (colorPickerChange)="form.get('bodyTextColor').setValue($event)"
              [colorPicker]="form.get('bodyTextColor').value"
            ></span>
            <span>{{form.get('bodyTextColor').value}}</span>
            <button *ngIf="form.get('bodyTextColor').value" mat-icon-button (click)="form.get('bodyTextColor').setValue(undefined)">
              <mat-icon>close</mat-icon>
            </button>
          </mat-card-content>
        </mat-card>

        <mat-card appearance="outlined">
          <mat-card-header>
            <mat-card-title>{{ 'template_setting_options.heading_text_color.label' | translate }}</mat-card-title>
          </mat-card-header>
          <mat-card-content fxLayout="row" fxLayoutAlign="space-around center">
            <span
              class="color-setting"
              [style.background]="form.get('headingTextColor').value"
              cpPosition="top"
              [cpCancelButton]="true"
              (colorPickerChange)="form.get('headingTextColor').setValue($event)"
              [colorPicker]="form.get('headingTextColor').value"
            ></span>
            <span>{{form.get('headingTextColor').value}}</span>
            <button *ngIf="form.get('bodyBackgroundColor').value" mat-icon-button (click)="form.get('bodyBackgroundColor').setValue(undefined)">
              <mat-icon>close</mat-icon>
            </button>
          </mat-card-content>
        </mat-card>

        <mat-card appearance="outlined">
          <mat-card-header>
            <mat-card-title>{{ 'template_setting_options.sub_heading_text_color.label' | translate }}</mat-card-title>
          </mat-card-header>
          <mat-card-content fxLayout="row" fxLayoutAlign="space-between center">
            <span
              class="color-setting"
              [style.background]="form.get('subHeadingTextColor').value"
              cpPosition="top"
              [cpCancelButton]="true"
              (colorPickerChange)="form.get('subHeadingTextColor').setValue($event)"
              [colorPicker]="form.get('subHeadingTextColor').value"
            ></span>
            <span>{{form.get('subHeadingTextColor').value}}</span>
            <button *ngIf="form.get('subHeadingTextColor').value" mat-icon-button (click)="form.get('subHeadingTextColor').setValue(undefined)">
              <mat-icon>close</mat-icon>
            </button>
          </mat-card-content>
        </mat-card>

        <mat-card appearance="outlined">
          <mat-card-header>
            <mat-card-title>{{ 'template_setting_options.body_background_color.label' | translate }}</mat-card-title>
          </mat-card-header>
          <mat-card-content fxLayout="row" fxLayoutAlign="space-around center">
            <span
              class="color-setting"
              [style.background]="form.get('bodyBackgroundColor').value"
              cpPosition="top"
              [cpCancelButton]="true"
              (colorPickerChange)="form.get('bodyBackgroundColor').setValue($event)"
              [colorPicker]="form.get('bodyBackgroundColor').value"
            ></span>
            <span>{{form.get('bodyBackgroundColor').value}}</span>
            <button *ngIf="form.get('bodyBackgroundColor').value" mat-icon-button (click)="form.get('bodyBackgroundColor').setValue(undefined)">
              <mat-icon>close</mat-icon>
            </button>
          </mat-card-content>
        </mat-card>
      </div>
    </section>
    <section>
      <h3>{{ 'template_setting_options.fonts.heading' | translate }}</h3>
      <div fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="20px">
        <app-asset-upload
          fxFlex="50%"
          identifier="headingFont"
          purpose="font"
          (uploaded)="selectFont(form, $event, 'headingFontUuid')"
          >
          <ng-template let-uploadData="uploadData">
            <mat-card appearance="outlined">
              <mat-card-header>
                <mat-card-title>{{ 'template_setting_options.heading_font.label' | translate }}</mat-card-title>
              </mat-card-header>
              <mat-card-content fxLayout="row" fxLayoutAlign="space-between center" >
                <span>{{ form.get('headingFontFileName').value }}</span>
                  <app-select-media
                    identifier="headingFont"
                    [uploadData]="uploadData"
                    [removable]="form.get('headingFontUuid').value"
                    purpose="font">
                  </app-select-media>
                </mat-card-content>
              </mat-card>
          </ng-template>
        </app-asset-upload>


        <app-asset-upload
          fxFlex="50%"
          identifier="bodyFont"
          purpose="font"
          (uploaded)="selectFont(form, $event, 'bodyFontUuid')"
          >
          <ng-template let-uploadData="uploadData">
            <mat-card appearance="outlined">
              <mat-card-header>
                <mat-card-title>{{ 'template_setting_options.body_font.label' | translate }}</mat-card-title>
              </mat-card-header>
              <mat-card-content fxLayout="row" fxLayoutAlign="space-between center" >
                <span>{{ form.get('bodyFontFileName').value }}</span>
                  <app-select-media
                    identifier="bodyFont"
                    [uploadData]="uploadData"
                    [removable]="form.get('bodyFontUuid').value"
                    (selected)="selectFont(form, $event, 'bodyFontUuid')"
                    purpose="font">
                  </app-select-media>
                </mat-card-content>
              </mat-card>
          </ng-template>
        </app-asset-upload>
      </div>
    </section>
  </form>
</ng-template>
